import {
  collection,
  deleteDoc,
  doc,
  DocumentData,
  FieldValue,
  Firestore,
  getDoc,
  onSnapshot,
  query,
  QueryConstraint,
  QuerySnapshot,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { BuyerAccount, UserObject } from "../../types";
import addErrorNotif from "../../utils/notifications/addErrorNotif";

const UserService = (db: Firestore) => ({
  getUser: async (uid: string): Promise<UserObject> => {
    try {
      const userResponse: DocumentData = await getDoc(doc(db, "users", uid));
      return userResponse.data();
    } catch (e) {
      addErrorNotif(e);
      return Promise.reject();
    }
  },
  setNewUser: async (userObj: UserObject): Promise<void> => {
    try {
      await setDoc(doc(db, "users", userObj.uid), userObj);
      return Promise.resolve();
    } catch (e) {
      addErrorNotif(e);
      return Promise.reject();
    }
  },
  updateUser: async (
    uid: string,
    updatedValues: Record<string, FieldValue | string | object>
  ) => {
    try {
      await updateDoc(doc(db, "users", uid), { ...updatedValues });
      return Promise.resolve();
    } catch (e) {
      addErrorNotif(e);
      return Promise.reject();
    }
  },
  addBuyerAccount: async (uid: string, buyerAccount: BuyerAccount) => {
    try {
      await setDoc(
        doc(db, "users", `${uid}`, "buyerAccounts", buyerAccount?.email),
        {
          ...buyerAccount,
          authAppKey: buyerAccount.authAppKey.replace(/\s/g, ""),
        }
      );
    } catch (e: any) {
      addErrorNotif(e);
      throw new Error(e);
    }
  },
  deleteBuyerAccount: async (uid: string, buyerAccountEmail: string) => {
    try {
      await deleteDoc(
        doc(db, "users", `${uid}`, "buyerAccounts", buyerAccountEmail)
      );
    } catch (e: any) {
      addErrorNotif(e);
      throw new Error(e);
    }
  },
  getBuyerAccountsSnapshot: (
    uid: string,
    queryOptions: QueryConstraint[],
    onNext: (snapshot: QuerySnapshot<DocumentData>) => void,
    onError: (e: any) => void = (e) => {
      addErrorNotif(e);
      throw new Error(e);
    }
  ) =>
    onSnapshot(
      query(
        collection(db, "users", `${uid}`, "buyerAccounts"),
        ...queryOptions
      ),
      onNext,
      onError
    ),
  getUserSnapshot: (
    uid: string,
    onNext: (doc: DocumentData) => void,
    onError: (e: any) => void = (e) => {
      addErrorNotif(e);
      throw new Error(e);
    }
  ) => onSnapshot(doc(db, "users", `${uid}`), onNext, onError),
});

export default UserService;
