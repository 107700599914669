import DataUsageIcon from "@mui/icons-material/DataUsage";
import InsightsIcon from "@mui/icons-material/Insights";
import { CircularProgress, Tab, Tabs } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import DateRangePickers from "../../components/DateRangePickers";
import TabsPageLayout from "../../components/TabsPageLayout";
import { SMALL_SCREEN_WIDTH } from "../../constants";
import WindowContext from "../../context/WindowContext";
import { isSaleValid } from "../../utils";
import Analytics from "./Tabs/Analytics";
import Overview from "./Tabs/Overview/index";
import { DashboardProps } from "./types";

const DashboardContent = (props: DashboardProps) => {
  const theme = useTheme();
  const { width: screenWidth } = useContext(WindowContext);

  const {
    lastThirtyDaysOrders = [],
    filteredOrders,
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    ordersDataStartDate,
    selectedTab,
    setSelectedTab,
    isDataLoading = false,
  } = props;

  const [numberOfDaysInDateRange, setNumberOfDaysInDateRange] =
    useState<number>(0);
  const [analytics, setAnalytics] = useState<object>({
    averageDailyProfit: 0,
    averageDailyOrders: 0,
    totalItemsCost: 0,
    totalBuyPrice: 0,
    totalBuyTax: 0,
    totalAutoDsWalletFees: 0,
    totalAutoDsCreditFees: 0,
    totalFinalValueFees: 0,
    totalAdFees: 0,
    totalSales: 0,
    totalProfit: 0,
    totalProfitPerOrder: 0,
    totalOrderCount: 0,
    successfulOrderCount: 0,
    dailyProfit: 0,
    weeklyProfit: 0,
    monthlyProfit: 0,
    yearlyProfit: 0,
  });

  useEffect(() => {
    if (startDate && endDate)
      setNumberOfDaysInDateRange(
        moment(endDate)
          .startOf("day")
          .diff(moment(startDate).startOf("day"), "days") + 1
      );
  }, [startDate, endDate]);

  useEffect(() => {
    const newAnalytics = {
      averageDailyProfit: 0,
      averageDailyOrders: 0,
      totalItemsCost: 0,
      totalBuyPrice: 0,
      totalBuyTax: 0,
      totalAutoDsWalletFees: 0,
      totalAutoDsCreditFees: 0,
      totalFinalValueFees: 0,
      totalAdFees: 0,
      totalSales: 0,
      totalProfit: 0,
      profitPerOrder: 0,
      totalOrderCount: 0,
      successfulOrderCount: 0,
      dailyProfit: 0,
      weeklyProfit: 0,
      monthlyProfit: 0,
      yearlyProfit: 0,
    };

    filteredOrders?.forEach((order) => {
      if (isSaleValid(order?.status)) {
        newAnalytics.totalItemsCost += order.itemCost;
        newAnalytics.totalBuyPrice += order.buyPrice;
        newAnalytics.totalBuyTax += order.buyTax;
        newAnalytics.totalAutoDsCreditFees += order.autodsCreditFee;
        newAnalytics.totalAutoDsWalletFees += order.autodsWalletFee;
        newAnalytics.totalFinalValueFees += order.finalValueFee;
        newAnalytics.totalAdFees += order.ebayAdFee;
        newAnalytics.totalSales += order.sellPrice;
        newAnalytics.totalProfit += order.profit;
        newAnalytics.successfulOrderCount += 1;
      }

      newAnalytics.totalOrderCount += 1;
    });

    newAnalytics.averageDailyProfit =
      newAnalytics.totalProfit / numberOfDaysInDateRange || 0;
    newAnalytics.averageDailyOrders =
      newAnalytics.totalOrderCount / numberOfDaysInDateRange || 0;
    newAnalytics.profitPerOrder =
      newAnalytics.totalProfit / newAnalytics.totalOrderCount || 0;

    setAnalytics(newAnalytics);
  }, [filteredOrders, numberOfDaysInDateRange]);

  return (
    <TabsPageLayout>
      <>
        <Tabs
          value={selectedTab}
          onChange={(e, index) => setSelectedTab(index)}
          style={{
            marginBottom: "25px",
            width: "100%",
            borderBottom: `1px solid ${theme.palette.divider}`,
          }}
        >
          <Tab
            label={
              <div style={{ display: "flex", alignItems: "center" }}>
                <DataUsageIcon style={{ marginRight: "10px" }} />{" "}
                <span>Overview</span>
              </div>
            }
          />
          <Tab
            label={
              <div style={{ display: "flex", alignItems: "center" }}>
                <InsightsIcon style={{ marginRight: "10px" }} />{" "}
                <span>Analytics</span>
              </div>
            }
          />
        </Tabs>
        <>
          <div
            style={{
              width: "100%",
              maxWidth: "1600px",
              display: "flex",
              rowGap: "15px",
              flexDirection: "column",
              alignItems: "center",
              ...(screenWidth > SMALL_SCREEN_WIDTH
                ? { paddingLeft: "15px", paddingRight: "15px" }
                : {}),
            }}
          >
            <DateRangePickers
              {...{
                isDataLoading,
                startDate,
                setStartDate,
                endDate,
                setEndDate,
                startDateLimit: ordersDataStartDate,
                endDateLimit: moment().endOf("day"),
              }}
            />
            {isDataLoading ? (
              <div style={{ position: "fixed", top: "50%", left: "50%" }}>
                <CircularProgress />
              </div>
            ) : (
              <>
                {selectedTab === 0 && (
                  <Overview
                    {...{
                      analytics,
                      isDataLoading,
                    }}
                  />
                )}
                {selectedTab === 1 && (
                  <Analytics
                    {...{
                      analytics,
                      numberOfOrders: filteredOrders?.length ?? 0,
                      startDate,
                      endDate,
                      isDataLoading,
                      lastThirtyDaysOrders,
                      filteredOrders,
                    }}
                  />
                )}
              </>
            )}
          </div>
        </>
      </>
    </TabsPageLayout>
  );
};

export default DashboardContent;
