import AmazonService from "./AmazonService";
import EbayService from "./EbayService";
import EasySyncService from './EasySyncService'

const services = {
  EbayService: () => EbayService(),
  AmazonService: () => AmazonService(),
  EasySyncService: () => EasySyncService()
};

export default services;
