/* eslint-disable react/no-unescaped-entities */
import {
  CircularProgress,
  Divider,
  InputAdornment,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useContext, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import {
  EBAY_OAUTH_URL,
  SECONDARY_TEXT_COLOR,
  SMALL_SCREEN_WIDTH,
} from "../../constants";
import UserContext from "../../context/UserContext";
import CloudFunctionService from "../../services/CloudFunctions";
import FirestoreService from "../../services/Firestore";
import { UserObject } from "../../types";
import { InfoOutlined } from "@mui/icons-material";
import WindowContext from "../../context/WindowContext";
import StoreBlock from "../../components/StoreBlock";

const Section = ({ children }: { children: React.ReactNode }) => (
  <div style={{ margin: "0px 0px 30px 0px" }}>{children}</div>
);

type SettingsContentProps = {
  feeConfigs: {
    walletLoadingPercentage: number;
    orderCreditPrice: number;
  };
  setWalletLoadingPercentage: (walletLoadingPercentage: number) => void;
  setOrderCreditPrice: (orderCreditPrice: number) => void;
  setFeeConfigs: () => void;
};

const SettingsContent = ({
  feeConfigs: { walletLoadingPercentage, orderCreditPrice },
  setWalletLoadingPercentage,
  setOrderCreditPrice,
  setFeeConfigs,
}: SettingsContentProps) => {
  const [searchParams] = useSearchParams();
  const [ebayStoreUsername, setEbayStoreUsername] = React.useState();
  const userObject: UserObject = useContext(UserContext);
  const theme = useTheme();
  const { width: screenWidth } = useContext(WindowContext);
  useEffect(() => {
    if (searchParams.get("code")) {
      const postEbayAccessToken = async () => {
        await CloudFunctionService.EbayService().loadUserEbayRefreshToken(
          userObject.uid,
          searchParams.get("code") ?? ""
        );

        window.location.href = "/settings";
      };

      postEbayAccessToken();
    }
  }, [searchParams]);

  useEffect(() => {
    return FirestoreService.UserService().getUserSnapshot(
      userObject.uid,
      (doc) => {
        if (doc.data()) {
          const userObject = doc.data();
          if (userObject.ebayToken?.username) {
            setEbayStoreUsername(userObject.ebayToken?.username);
          }
        }
      }
    );
  }, []);

  return (
    <div style={{ width: "100%" }}>
      <Section>
        <div
          style={{
            alignItems: "center",
            margin: "0px 0px 10px 0px",
          }}
        >
          <Typography variant="h6">eBay Store</Typography>
          {!userObject?.ebayToken && (
            <div
              style={{
                backgroundColor: theme.palette.primary.main,
                width: "fit-content",
                minWidth: screenWidth < SMALL_SCREEN_WIDTH ? "0px" : "500px",
                padding: "15px 20px",
                borderRadius: "15px",
                display: "flex",
                flexDirection: "column",
                margin: "20px 0px",
              }}
            >
              <span
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "10px",
                  fontWeight: 600,
                }}
              >
                <InfoOutlined style={{ marginRight: "5px" }} />
                {userObject?.displayName}, let's add an eBay Store!
              </span>
              <span>{"You're almost ready to start using DropStats."}</span>
              <span>{"Add an eBay store to get started."}</span>
            </div>
          )}
        </div>
        <div style={{ display: "flex", columnGap: "15px" }}>
          {searchParams.get("code") && (
            <StoreBlock>
              <CircularProgress />
            </StoreBlock>
          )}
          {userObject?.ebayToken && (
            <StoreBlock>
              <img src="/ebayLogo.png" style={{ height: "30px" }} />
              <span>{ebayStoreUsername}</span>
            </StoreBlock>
          )}
          <div
            style={{
              height: "200px",
              width: "200px",
              border: `3px dashed ${SECONDARY_TEXT_COLOR}`,
              borderRadius: "10px",
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
              color: SECONDARY_TEXT_COLOR,
              cursor: "pointer",
            }}
            onClick={() => (window.location.href = EBAY_OAUTH_URL)}
          >
            + Add Store
          </div>
        </div>
      </Section>
      <Divider style={{ margin: "15px 0px" }} />
      {userObject?.ebayToken && (
        <Section>
          <Typography variant="h6" style={{ margin: "0px 0px 10px 0px" }}>
            Fee Configurations
          </Typography>
          <Typography style={{ margin: "0px 0px 20px 0px" }}>
            AutoDS Fees:
          </Typography>
          <div style={{ display: "flex", gap: "10px" }}>
            <TextField
              id="orderCreditPrice"
              label="Credit Price"
              value={orderCreditPrice}
              onChange={(e) => setOrderCreditPrice(Number(e.target.value))}
              onBlur={setFeeConfigs}
              type="number"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
              style={{ margin: "0px 5px", width: "170px" }}
              size="small"
            />
            <TextField
              id="walletLoadingPercentage"
              label="Wallet Loading Fee"
              value={walletLoadingPercentage}
              onChange={(e) =>
                setWalletLoadingPercentage(Number(e.target.value))
              }
              onBlur={setFeeConfigs}
              type="number"
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
              style={{ margin: "0px 5px", width: "170px" }}
              size="small"
            />
          </div>
        </Section>
      )}
    </div>
  );
};

export default SettingsContent;
