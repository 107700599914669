import {
  collection,
  doc,
  Firestore,
  getDoc,
  getDocs,
  setDoc,
} from "firebase/firestore";
import { AutodsOrder } from "../../types";
import addErrorNotif from "../../utils/notifications/addErrorNotif";

const AutodsService = (db: Firestore) => ({
  getAutodsOrders: async (uid: string) => {
    try {
      const autodsOrdersSnapshot = await getDocs(
        collection(db, "users", uid, "autodsOrders")
      );
      const autodsOrderMap: Record<string, AutodsOrder> = {};
      for (const order of autodsOrdersSnapshot.docs) {
        autodsOrderMap[order.id] = order.data() as AutodsOrder;
      }
      return autodsOrderMap;
    } catch (e: any) {
      addErrorNotif(e);
      throw new Error(e);
    }
  },
  getAutodsOrder: async (uid: string, orderId: string) => {
    try {
      const autodsOrder = await getDoc(
        doc(db, `users/${uid}/autodsOrders/${orderId}`)
      );
      return autodsOrder.data();
    } catch (e: any) {
      addErrorNotif(e);
      throw new Error(e);
    }
  },
  setAutodsOrders: async (uid: string, orders: AutodsOrder[]) => {
    try {
      const autodsOrdersSnapshot = await getDocs(
        collection(db, "users", uid, "autodsOrders")
      );
      const autodsOrderMap: Record<string, AutodsOrder> = {};
      for (const order of autodsOrdersSnapshot.docs) {
        autodsOrderMap[order.id] = order.data() as AutodsOrder;
      }

      await Promise.all(
        orders.map(async (order) => {
          if (
            autodsOrderMap[order?.sell_site_order_id]?.status !== order?.status
          ) {
            await setDoc(
              doc(db, "users", uid, "autodsOrders", order?.sell_site_order_id),
              order
            );
          }
        })
      );
    } catch (e: any) {
      addErrorNotif(e);
      throw new Error(e);
    }
  },
});

export default AutodsService;
