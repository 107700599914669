import React from "react";

const StoreBlock = ({ children }: { children: React.ReactNode }) => (
  <div
    style={{
      height: "200px",
      width: "200px",
      border: `3px solid white`,
      borderRadius: "10px",
      alignItems: "center",
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      overflow: "hidden",
    }}
  >
    {children}
  </div>
);

export default StoreBlock;
