import { getFirestore } from "firebase/firestore";
import app from "../firebase";
import SourceDataService from "./SourceDataService";
import AutodsService from "./AutodsService";
import FeeConfigService from "./FeeConfigService";
import UserService from "./UserService";
import EbayService from "./EbayService";

const db = getFirestore(app);
// connectFirestoreEmulator(db, "localhost", 8080);

const services = {
  UserService: () => UserService(db),
  FeeConfigService: () => FeeConfigService(db),
  AutodsService: () => AutodsService(db),
  SourceDataService: () => SourceDataService(db),
  EbayService: () => EbayService(db),
};

export default services;
