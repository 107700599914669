import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { Chip, IconButton, Skeleton, TextField } from "@mui/material";
import { Stack } from "@mui/system";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { CACHED_DASHBOARD_DATE_FILTER, SMALL_SCREEN_WIDTH } from "../constants";
import WindowContext from "../context/WindowContext";
import { ValueOf } from "../types";

type DateRangePickersProps = {
  isDataLoading?: boolean;
  startDate: moment.Moment;
  setStartDate: (date: moment.Moment) => void;
  endDate: moment.Moment;
  setEndDate: (date: moment.Moment) => void;
  startDateLimit: moment.Moment;
  endDateLimit: moment.Moment;
};

const DateRangePickers = ({
  isDataLoading = false,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  startDateLimit,
  endDateLimit,
}: DateRangePickersProps) => {
  const { width: screenWidth } = useContext(WindowContext);

  const dates = useMemo(
    () => ({
      startOfToday: moment().startOf("day"),
      endOfToday: moment().endOf("day"),
      startOfWeek: moment().startOf("week"),
      startOfMonth: moment().startOf("month"),
    }),
    []
  );

  const FilterValues = Object.freeze({
    TODAY: "Today",
    THIS_WEEK: "This Week",
    THIS_MONTH: "This Month",
    RESET: "Reset",
  });

  const FilterValueDateRanges = Object.freeze({
    [FilterValues.TODAY]: {
      startDate: dates.startOfToday,
      endDate: dates.endOfToday,
    },
    [FilterValues.THIS_WEEK]: {
      startDate: dates.startOfWeek,
      endDate: dates.endOfToday,
    },
    [FilterValues.THIS_MONTH]: {
      startDate: dates.startOfMonth,
      endDate: dates.endOfToday,
    },
    [FilterValues.RESET]: {
      startDate: startDateLimit,
      endDate: endDateLimit,
    },
  });

  const [filter, setFilter] = useState<ValueOf<typeof FilterValues> | null>();
  const StartDatePicker = () => (
    <DatePicker
      label="Start Date"
      value={startDate}
      onChange={(newStartDate) => {
        if (newStartDate?.isValid()) {
          localStorage.removeItem(CACHED_DASHBOARD_DATE_FILTER);
          setStartDate(newStartDate.startOf("day"));
        }
      }}
      renderInput={(params) => {
        return <TextField {...params} />;
      }}
      shouldDisableDate={(date) => {
        if (
          date.isBefore(startDateLimit, "day") ||
          date.isAfter(endDateLimit, "day")
        )
          return true;
        else return false;
      }}
      disabled={!startDateLimit && !startDate}
    />
  );

  const EndDatePicker = () => (
    <DatePicker
      label="End Date"
      value={endDate}
      onChange={(newEndDate) => {
        if (newEndDate?.isValid()) {
          localStorage.removeItem(CACHED_DASHBOARD_DATE_FILTER);
          setEndDate(newEndDate.endOf("day"));
        }
      }}
      renderInput={(params) => <TextField {...params} />}
      shouldDisableDate={(date) => {
        if (
          date.isBefore(startDateLimit, "day") ||
          date.isAfter(endDateLimit, "day")
        )
          return true;
        else return false;
      }}
      disabled={!startDateLimit && !startDate}
    />
  );

  const FiltersButtonGroup = () => (
    <div style={{ overflow: "auto", maxWidth: "100%" }}>
      <Stack direction="row" spacing={1}>
        <Chip
          color="primary"
          label={FilterValues.TODAY}
          variant={filter === FilterValues.TODAY ? "filled" : "outlined"}
          onClick={() => {
            setFilter(FilterValues.TODAY);
          }}
        />
        <Chip
          color="primary"
          label={FilterValues.THIS_WEEK}
          variant={filter === FilterValues.THIS_WEEK ? "filled" : "outlined"}
          onClick={() => {
            setFilter(FilterValues.THIS_WEEK);
          }}
        />
        <Chip
          color="primary"
          label={FilterValues.THIS_MONTH}
          variant={filter === FilterValues.THIS_MONTH ? "filled" : "outlined"}
          onClick={() => {
            setFilter(FilterValues.THIS_MONTH);
          }}
        />
      </Stack>
    </div>
  );

  useEffect(() => {
    if (filter) {
      setStartDate(FilterValueDateRanges[filter].startDate);
      setEndDate(FilterValueDateRanges[filter].endDate);

      if (window.location.href.includes("dashboard")) {
        localStorage.setItem(CACHED_DASHBOARD_DATE_FILTER, filter);
      }
    }
  }, [filter]);

  // unset quick filter when user changes the dates manually
  useEffect(() => {
    if (filter) {
      if (
        !FilterValueDateRanges[filter].startDate.isSame(startDate, "day") ||
        !FilterValueDateRanges[filter].endDate.isSame(endDate, "day")
      ) {
        setFilter(null);
      }
    }
  }, [startDate, endDate]);

  // get users cached quick filter selection when viewing dashboard
  useEffect(() => {
    if (window.location.href.includes("dashboard")) {
      const cachedQuickFilter: ValueOf<typeof FilterValues> | null =
        localStorage.getItem(CACHED_DASHBOARD_DATE_FILTER) as ValueOf<
          typeof FilterValues
        > | null;

      if (cachedQuickFilter) {
        setFilter(cachedQuickFilter);
      } else {
        setFilter(FilterValues.THIS_MONTH);
      }
    }
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: screenWidth < SMALL_SCREEN_WIDTH ? "column" : "row",
        gap: "15px",
        alignItems: "center",
        maxWidth: "100%",
      }}
    >
      <div style={{ display: "flex" }}>
        <div style={{ display: "flex", columnGap: "15px" }}>
          {isDataLoading ? (
            <>
              <Skeleton>
                <StartDatePicker />
              </Skeleton>
              <Skeleton>
                <EndDatePicker />
              </Skeleton>
            </>
          ) : (
            <>
              <StartDatePicker />
              <EndDatePicker />
            </>
          )}
        </div>
        <IconButton
          onClick={() => {
            setFilter(null);
            setStartDate(startDateLimit);
            setEndDate(endDateLimit);
            localStorage.removeItem(CACHED_DASHBOARD_DATE_FILTER);
          }}
          disabled={!startDateLimit || !endDateLimit}
        >
          <RestartAltIcon />
        </IconButton>
      </div>
      <FiltersButtonGroup />
    </div>
  );
};

export default DateRangePickers;
