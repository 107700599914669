import React from "react";
import { IconButton } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { NotificationVariants } from "../../constants";
import { OpenInNew } from "@mui/icons-material";

const addInfoNotif = (message: string, link?: string, timeout?: number) => {
  enqueueSnackbar(message, {
    variant: NotificationVariants.INFO,
    ...(link
      ? {
        action: () => (
          <IconButton size="small" style={{ padding: "0px" }}>
            <a href={link} target="_blank" rel="noopener noreferrer">
              <OpenInNew fontSize="small" />
            </a>
          </IconButton>
        ),
      }
      : {}),
    ...(timeout ? {
      autoHideDuration: timeout
    } : {})
  });
};

export default addInfoNotif;
