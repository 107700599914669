import React from "react";
import LoginContent from "./Content";

type LoginProps = {
  isSigningIn: boolean;
};

const Login = ({ isSigningIn }: LoginProps) => {
  return <LoginContent {...{ isSigningIn }} />;
};

export default Login;
