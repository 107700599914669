import axios from "axios";
import addErrorNotif from "../../utils/notifications/addErrorNotif";

const EasySyncService = () => ({
  createAquilineTrackingNumber: async ({
    uid,
    shippingAddress,
    amazonOrderId,
    buyerAccountEmail,
    itemLocation,
    ebayLegacyOrderId,
  }: {
    uid: string;
    shippingAddress: {
      addressLine1: string;
      addressLine2: string;
      city: string;
      countryCode: string;
      fullName: string;
      phoneNumber: string;
      stateOrProvince: string;
      postalCode: string;
    };
    amazonOrderId: string;
    buyerAccountEmail: string;
    itemLocation: {
      city: string;
      postalCode: string;
      state: string;
      countryCode: string;
    };
    ebayLegacyOrderId: string;
  }) => {
    try {
      await axios.post(
        "https://us-central1-ds-metrics-364506.cloudfunctions.net/createAquilineTrackingNumber",
        {
          uid,
          shippingAddress,
          amazonOrderId,
          buyerAccountEmail,
          itemLocation,
          ebayLegacyOrderId,
        }
      );
    } catch (e) {
      addErrorNotif(e);
    }
  },
});

export default EasySyncService;
