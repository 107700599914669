/*global chrome*/
import { CssBaseline, GlobalStyles, ThemeProvider } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import {
  getAuth,
  GoogleAuthProvider,
  signInWithCredential,
} from "firebase/auth";
import { limit, orderBy } from "firebase/firestore";
import moment from "moment-timezone";
import { enqueueSnackbar, SnackbarProvider } from "notistack";
import React, { useCallback, useEffect, useState } from "react";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { useInterval } from "usehooks-ts";
import MiniNavDrawer from "./components/MiniNavDrawer/index";
import {
  ChromeExtensionResponseStatuses,
  EXTENSION_ID,
  NotificationVariants,
} from "./constants";
import UserContext from "./context/UserContext";
import WindowContext from "./context/WindowContext";
import FirestoreService from "./services/Firestore/index";
import themes from "./themes";
import formatError from "./utils/formatError";
import addErrorNotif from "./utils/notifications/addErrorNotif";
import addInfoNotif from "./utils/notifications/addInfoNotif";
import addSuccessNotif from "./utils/notifications/addSuccessNotif";
import Dashboard from "./views/Dashboard/index";
import Listings from "./views/Listings";
import Orders from "./views/Orders/index";
import Products from "./views/Products";
import Settings from "./views/Settings";
import Setup from "./views/Setup";
import Login from "./views/Login";
const App = () => {
  const auth = getAuth();
  const navigate = useNavigate();

  const [google, setGoogle] = useState();
  const [isGoogleLoading, setIsGoogleLoading] = useState(true);
  const [userObject, setUserObject] = useState();
  const [isAutodsDataSyncing, setIsAutodsDataSyncing] = useState(false);
  const [ordersDataStartDate, setOrdersDataStartDate] = useState();
  const [windowSize, setWindowSize] = useState({
    width: window.screen.width,
    height: window.screen.height,
  });
  const [isSigningIn, setIsSigningIn] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.screen.width,
        height: window.screen.height,
      });
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  });

  useEffect(() => {
    if (userObject?.ebayToken) {
      const loadOrdersDateRange = async () => {
        const firstEbayOrderArr =
          await FirestoreService.EbayService().getEbayOrders(userObject.uid, [
            orderBy("creationDate"),
            limit(1),
          ]);

        const firstEbayOrder = firstEbayOrderArr[0].data();

        setOrdersDataStartDate(moment(firstEbayOrder.creationDate));
      };

      loadOrdersDateRange();
    }
  }, [userObject]);

  //wait until windw.google loads to then call initalize and renderButton
  //https://www.dolthub.com/blog/2022-05-04-google-signin-migration/#3-useeffect-doesnt-listen-to-when-the-window-object-changes
  useInterval(
    () => {
      if (typeof window !== "undefined" && window.google) {
        setGoogle(window.google);
        setIsGoogleLoading(false);
      }
    },
    isGoogleLoading ? 100 : null
  );

  useEffect(() => {
    if (userObject && !userObject?.ebayToken) {
      navigate("/setup");
    }
  }, [userObject]);

  useEffect(() => {
    const handleCallbackResponse = (response) => {
      setIsSigningIn(true);
      //signing in with "Sign in With Google" integrated with firebase
      //Documentation: https://firebase.google.com/docs/auth/web/google-signin#advanced:-handle-the-sign-in-flow-manually
      const credential = GoogleAuthProvider.credential(response.credential);

      signInWithCredential(auth, credential).then(async (result) => {
        const user = await FirestoreService.UserService().getUser(
          result.user.uid
        );

        if (user) {
          setUserObject(user);
        } else {
          const userObj = {
            displayName: result.user?.displayName,
            email: result.user?.email,
            emailVerified: result.user?.emailVerified,
            phoneNumber: result.user?.phoneNumber,
            photoURL: result.user?.photoURL,
            providerId: result.user?.providerId,
            uid: result.user?.uid,
          };
          setUserObject(userObj);
          await FirestoreService.UserService().setNewUser(userObj);
        }

        setIsSigningIn(false);

        localStorage.setItem("dropstatsUserId", result.user.uid);
      });
    };

    if (google && !isGoogleLoading) {
      google.accounts.id.initialize({
        client_id:
          "411209736988-cjumgbtvcu4he9qre39tuqva24sqbupm.apps.googleusercontent.com",
        callback: handleCallbackResponse,
        prompt_parent_id: "accountDropdown",
        cancel_on_tap_outside: true,
      });
      google.accounts.id.renderButton(
        document.getElementById("signInToolbar"),
        {
          theme: "filled_black",
          size: "large",
          type: "icon",
          text: "signin",
          shape: "circle",
        }
      );
      google.accounts.id.renderButton(document.getElementById("loginButton"), {
        shape: "circle",
        size: "large",
      });

      const setCachedUser = async ({ userId }) => {
        const user = await FirestoreService.UserService().getUser(userId);
        if (user) {
          setIsSigningIn(false);
          setUserObject(user);
        }
      };

      const cachedUserId = localStorage.getItem("dropstatsUserId");
      if (cachedUserId && !userObject) {
        setCachedUser({ userId: cachedUserId });
      } else {
        setIsSigningIn(false);
      }
    }
  }, [auth, enqueueSnackbar, google, isGoogleLoading, navigate, userObject]);

  const handleSyncClick = useCallback(async () => {
    setIsAutodsDataSyncing(true);
    if (chrome?.runtime)
      chrome.runtime.sendMessage(
        EXTENSION_ID,
        "getIdToken",
        {},
        async function (response) {
          try {
            const { status, cookie } = response || {};

            if (status !== ChromeExtensionResponseStatuses.SUCCESS) {
              addInfoNotif(
                "Please install the Dropstats Chrome Extension to Sync your AutoDS data",
                `https://chrome.google.com/webstore/detail/dropstats/${EXTENSION_ID}`
              );

              setIsAutodsDataSyncing(false);
              return;
            }
            if (!cookie) {
              addInfoNotif(
                "Please login to AutoDS in a different tab",
                "https://platform.autods.com/"
              );

              setIsAutodsDataSyncing(false);
              return;
            }

            const tokenExpirationTime = moment
              .unix(cookie.expirationDate)
              .subtract(30, "days")
              .add(1, "hour");

            //check if it has been an hour since the AutoDS token was created
            //if it has, it's expired so don't bother issuing a request that will fail
            if (moment(tokenExpirationTime).isBefore(moment())) {
              addInfoNotif(
                "Please login to AutoDS in a different tab",
                "https://platform.autods.com/"
              );
              setIsAutodsDataSyncing(false);
            } else {
              const storesResponse = await fetch(
                "https://v2-api.autods.com/store/list",
                {
                  method: "GET",
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${cookie.value}`,
                  },
                }
              );

              if (storesResponse.status !== 200) {
                if (storesResponse.status === 401) {
                  addInfoNotif(
                    "Please login to AutoDS in a different tab",
                    "https://platform.autods.com/"
                  );
                  setIsAutodsDataSyncing(false);
                  return;
                } else throw new Error(`AutoDS: ${storesResponse.statusText}`);
              }
              const stores = await storesResponse.json();
              let orders = [];
              const ebayStores = stores.filter(
                (store) => store.store?.ebay_eias
              );
              if (!ebayStores.length) {
                enqueueSnackbar(`AutoDS: No eBay stores to sync`, {
                  variant: NotificationVariants.WARNING,
                });
                setIsAutodsDataSyncing(false);

                return;
              }

              for (const store of ebayStores) {
                addInfoNotif("Syncing AutoDS data, please wait...");
                const getOrders = async ({ offset, storeId }) => {
                  const ordersResponse = await fetch(
                    `https://v2-api.autods.com/orders/${storeId}/list/`,
                    {
                      method: "POST",
                      headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${cookie.value}`,
                      },
                      body: JSON.stringify({
                        limit: 1000,
                        offset,
                        condition: "and",
                        filters: [],
                        order_by: { name: "sold_date", direction: "desc" },
                      }),
                    }
                  );
                  if (ordersResponse.status !== 200) {
                    throw new Error(`AutoDS: ${response.statusText}`);
                  }
                  const ordersData = await ordersResponse.json();
                  orders = orders.concat(ordersData?.results);
                  if (ordersData?.results.length === 1000)
                    await getOrders({ offset: offset + 1000, storeId });
                };

                await getOrders({ offset: 0, storeId: store?.store?.id });

                // update autods order data inside ebayOrders collection data
                await Promise.all(
                  orders.map((order) =>
                    FirestoreService.EbayService().syncAutodsOrderData(
                      userObject.uid,
                      order
                    )
                  )
                );

                addSuccessNotif("AutoDS Data synced successfully!");
                const now = moment().format();
                await FirestoreService.UserService().updateUser(
                  userObject.uid,
                  {
                    lastAutodsSync: now,
                  }
                );
                setUserObject({
                  ...userObject,
                  lastAutodsSync: now,
                });
                setIsAutodsDataSyncing(false);
              }
            }
          } catch (e) {
            setIsAutodsDataSyncing(false);
            addErrorNotif(formatError(e));
            throw new Error(e);
          }
        }
      );
    else {
      addInfoNotif(
        "Please install the Dropstats Chrome Extension to Sync your AutoDS data",
        `https://chrome.google.com/webstore/detail/dropstats/${EXTENSION_ID}`
      );
      setIsAutodsDataSyncing(false);
    }
  }, [enqueueSnackbar, userObject]);

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <ThemeProvider theme={themes.dark4}>
        <SnackbarProvider maxSnack={2} />
        <UserContext.Provider value={userObject}>
          <WindowContext.Provider value={windowSize}>
            <GlobalStyles
              styles={{ ul: { margin: 0, padding: 0, listStyle: "none" } }}
            />
            <CssBaseline />
            {!userObject?.uid?.length && <Login {...{ isSigningIn }} />}
            {userObject?.uid?.length && (
              <MiniNavDrawer
                {...{
                  handleSyncClick,
                  setUserObject,
                }}
              >
                <Routes>
                  <>
                    <Route
                      path="/settings"
                      element={<Settings {...{ userObject, setUserObject }} />}
                    />
                    <Route
                      path="/setup"
                      element={<Setup {...{ handleSyncClick }} />}
                    />
                  </>
                  {!userObject?.ebayToken && (
                    <Route exact path="/" element={<Navigate to="/setup" />} />
                  )}
                  {userObject?.ebayToken && (
                    <>
                      <Route
                        path="/orders"
                        element={
                          <Orders
                            {...{
                              ordersDataStartDate,
                              isAutodsDataSyncing,
                            }}
                          />
                        }
                      />
                      <Route
                        path="/dashboard"
                        element={
                          <Dashboard
                            {...{
                              ordersDataStartDate,
                            }}
                          />
                        }
                      />
                      <Route
                        exact
                        path="/"
                        element={<Navigate to="/dashboard" />}
                      />
                      {/* <Route
                      path="/products"
                      element={<Products {...{ orders: [] }} />}
                    /> */}
                      {/* <Route path="/listings" element={<Listings />} /> */}
                    </>
                  )}
                </Routes>
              </MiniNavDrawer>
            )}
          </WindowContext.Provider>
        </UserContext.Provider>
      </ThemeProvider>
    </LocalizationProvider>
  );
};

export default App;
