import { Typography, useTheme } from "@mui/material";
import React from "react";
import DashboardCardSmall from "../../../../components/DashboardCardSmall";
import { SECONDARY_TEXT_COLOR } from "../../../../constants";
import DashboardCardExpandableContent from "./DashboardCardExpandableContent";

const OrdersCard = ({ isLoading, totalOrderCount, successfulOrderCount }) => {
  const theme = useTheme();

  return (
    <DashboardCardSmall
      title="Orders"
      isLoading={isLoading}
      isExpandable={true}
      expandableContent={
        <DashboardCardExpandableContent
          {...{
            title: "Monthly Orders",
            yAxisNames: ["Orders"],
            yAxisKeys: ["orderCount"],
            headerIcon: "",
          }}
        />
      }
    >
      <Typography variant="h4" style={{ marginBottom: "20px" }}>
        {totalOrderCount}
      </Typography>
      <Typography>
        {successfulOrderCount}
        <span style={{ color: theme.palette.text.secondary }}>
          {" "}
          Successful Orders
        </span>
      </Typography>
      <Typography>
        {totalOrderCount - successfulOrderCount}
        <span style={{ color: theme.palette.text.secondary }}>
          {" "}
          Cancelled/Returned Orders
        </span>
      </Typography>
    </DashboardCardSmall>
  );
};

export default OrdersCard;
