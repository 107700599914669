import React from "react";
import moment from "moment";
import { IconButton } from "@mui/material";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";

const MonthNavigator = ({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  latestDate,
  earliestDate,
}) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        columnGap: "5px",
        marginLeft: "auto",
        alignItems: "center",
      }}
    >
      <IconButton
        onClick={() => {
          setStartDate(moment(startDate).subtract(1, "month").startOf("month"));
          setEndDate(moment(endDate).subtract(1, "month").endOf("month"));
        }}
        disabled={moment(startDate)
          .subtract(1, "month")
          .isBefore(earliestDate, "month")}
      >
        <ArrowLeftIcon />
      </IconButton>
      <CalendarTodayIcon />
      <span>{moment(startDate).format("MMM YYYY")}</span>
      <IconButton
        onClick={() => {
          setStartDate(moment(startDate).add(1, "month").startOf("month"));
          setEndDate(moment(endDate).add(1, "month").endOf("month"));
        }}
        disabled={moment(endDate).add(1, "month").isAfter(latestDate, "month")}
      >
        <ArrowRightIcon />
      </IconButton>
    </div>
  );
};

export default MonthNavigator;
