import { useTheme } from "@emotion/react";
import { Typography } from "@mui/material";
import React from "react";
import DashboardCardSmall from "../../../../components/DashboardCardSmall";
import { getFormattedDecimal } from "../../../../utils";
import DashboardCardExpandableContent from "./DashboardCardExpandableContent";

const AutodsFeesCard = ({
  isLoading,
  totalAutoDsCreditFees,
  totalAutoDsWalletFees,
}) => {
  const theme = useTheme();

  return (
    <DashboardCardSmall
      title="AutoDS Fees"
      isLoading={isLoading}
      isExpandable={true}
      expandableContent={
        <DashboardCardExpandableContent
          {...{
            title: "Monthly AutoDS Fees",
            yAxisNames: ["AutodDS Wallet Fee", "AutoDS Credit Fee"],
            yAxisKeys: ["autodsWalletFee", "autodsCreditFee"],
          }}
        />
      }
    >
      <Typography variant="h4" style={{ marginBottom: "20px" }}>
        $
        {getFormattedDecimal(
          totalAutoDsCreditFees + totalAutoDsWalletFees
        ).toLocaleString()}
      </Typography>
      <Typography>
        ${getFormattedDecimal(totalAutoDsCreditFees).toLocaleString()}
        <span style={{ color: theme.palette.text.secondary }}>
          {" "}
          AutoDS Order Credit Fees
        </span>
      </Typography>
      <Typography>
        ${getFormattedDecimal(totalAutoDsWalletFees).toLocaleString()}
        <span style={{ color: theme.palette.text.secondary }}>
          {" "}
          AutoDS Wallet Loading Fees
        </span>
      </Typography>
    </DashboardCardSmall>
  );
};

export default AutodsFeesCard;
