import { enqueueSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { NotificationVariants } from "../../constants";
import FirestoreService from "../../services/Firestore/index";
import { UserObject } from "../../types";
import formatError from "../../utils/formatError";
import SettingsContent from "./Content";

export type SettingsProps = {
  userObject: UserObject;
  setUserObject: (userObject: UserObject) => void;
};

const Settings = ({ userObject, setUserObject }: SettingsProps) => {
  const [walletLoadingPercentage, setWalletLoadingPercentage] = useState(5);
  const [orderCreditPrice, setOrderCreditPrice] = useState(0.32);

  useEffect(() => {
    if (userObject) {
      //loads users fee configs
      const loadFeeConfigs = async () => {
        const feeConfigs =
          await FirestoreService.FeeConfigService().getFeeConfigs(
            userObject.uid
          );
        if (feeConfigs) {
          setOrderCreditPrice(feeConfigs?.orderCreditPrice ?? 0.32);
          setWalletLoadingPercentage(
            feeConfigs?.walletLoadingPercentage ?? 0.05
          );
        }
      };

      loadFeeConfigs();
    }
  }, [userObject]);

  const setFeeConfigs = async () => {
    try {
      setUserObject({
        ...userObject,
        feeConfigs: {
          orderCreditPrice: orderCreditPrice,
          walletLoadingPercentage: walletLoadingPercentage,
        },
      });
      await FirestoreService.FeeConfigService().setFeeConfigs(userObject.uid, {
        orderCreditPrice: orderCreditPrice,
        walletLoadingPercentage: walletLoadingPercentage,
      });
    } catch (e) {
      enqueueSnackbar(formatError(e), { variant: NotificationVariants.ERROR });
    }
  };

  return (
    <SettingsContent
      {...{
        feeConfigs: { walletLoadingPercentage, orderCreditPrice },
        setWalletLoadingPercentage,
        setOrderCreditPrice,
        setFeeConfigs,
      }}
    />
  );
};

export default Settings;
