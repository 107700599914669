import React from "react";

type TabsPageLayoutProps = {
  children: JSX.Element | string;
};

const TabsPageLayout = ({ children }: TabsPageLayoutProps) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        width: "100%",
        marginTop: "-15px",
      }}
    >
      {children}
    </div>
  );
};

export default TabsPageLayout;
