import { initializeApp } from "firebase/app";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDZbLvW_EYJhPyP0KUPCYoQxwbG_8t2K-Y",
  authDomain: "ds-metrics-364506.firebaseapp.com",
  databaseURL: "https://ds-metrics-364506-default-rtdb.firebaseio.com",
  projectId: "ds-metrics-364506",
  storageBucket: "ds-metrics-364506.appspot.com",
  messagingSenderId: "411209736988",
  appId: "1:411209736988:web:a1984c77a5e8de9961d0d9",
  measurementId: "G-LN7HH5VC2K",
};

const app = initializeApp(firebaseConfig);
export default app;
