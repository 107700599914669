import axios from "axios";
import formatError from "../../utils/formatError";
import addErrorNotif from "../../utils/notifications/addErrorNotif";
import addSuccessNotif from "../../utils/notifications/addSuccessNotif";

const EbayService = () => ({
  updateEbayOrders: async (uid: string) => {
    try {
      await axios.post(
        "https://us-central1-ds-metrics-364506.cloudfunctions.net/updateEbayOrders",
        { uid }
      );
    } catch (e) {
      addErrorNotif(e);
    }
  },
  updateActiveEbayListings: async (uid: string) => {
    try {
      await axios.post(
        "https://us-central1-ds-metrics-364506.cloudfunctions.net/updateActiveEbayListings",
        { uid }
      );
    } catch (e) {
      addErrorNotif(`Error updating active eBay listings: ${formatError(e)}`);
    }
  },
  postEbayListing: async (
    uid: string,
    listing: {
      newSellPrice: number;
      itemId: string;
      isOutOfStock: boolean;
      defaultQuantity: number;
      listingUrl?: string;
    }
  ) => {
    try {
      await axios.post(
        "https://us-central1-ds-metrics-364506.cloudfunctions.net/postEbayListingAPI",
        {
          uid,
          listing,
        }
      );
      addSuccessNotif("Listing saved!", listing?.listingUrl);
    } catch (e) {
      addErrorNotif(e);
    }
  },
  loadUserEbayRefreshToken: async (uid: string, authorizationCode: string) => {
    try {
      await axios.post(
        "https://us-central1-ds-metrics-364506.cloudfunctions.net/loadUserEbayRefreshToken",
        { authorizationCode, uid }
      );
    } catch (e: any) {
      addErrorNotif(e);
      throw new Error(e);
    }
  },
});

export default EbayService;
