export const EXTENSION_ID = "ophdgnkkehbojcocmgodoileapldbjae";

export const SECONDARY_TEXT_COLOR = "rgb(255, 255, 255, 0.5)";

export const ChromeExtensionResponseStatuses = Object.freeze({
  SUCCESS: "success",
});

export const NotificationVariants = Object.freeze({
  SUCCESS: "success",
  ERROR: "error",
  WARNING: "warning",
  INFO: "info",
});

export const DRAWER_WIDTH = 250;

export const DASHBOARD_GAP = 25;

export const MEDIUM_SCREEN_WIDTH = 1366;

export const SMALL_SCREEN_WIDTH = 1024;

export const AVERAGE_SALES_TAX_RATE = 0.0625;

export const DEFAULT_PRICING_CONFIG = Object.freeze({
  PROFIT_MARGIN: 0.18,
  ADDITIONAL_PROFIT: 0.5,
  FEES: 0.129,
  ADDITIONAL_FEES: 0.3,
  SALES_TAX_RATE: AVERAGE_SALES_TAX_RATE,
});

export const CACHED_DASHBOARD_DATE_FILTER = "cachedDashboardDateFilter";

export const EBAY_OAUTH_URL =
  "https://auth.ebay.com/oauth2/authorize?client_id=GrantSte-AutoDrop-PRD-7a28fb95c-c15429d7&response_type=code&redirect_uri=Grant_Steffen-GrantSte-AutoDr-ffcjwbop&scope=https://api.ebay.com/oauth/api_scope https://api.ebay.com/oauth/api_scope/sell.marketing.readonly https://api.ebay.com/oauth/api_scope/sell.marketing https://api.ebay.com/oauth/api_scope/sell.inventory.readonly https://api.ebay.com/oauth/api_scope/sell.inventory https://api.ebay.com/oauth/api_scope/sell.account.readonly https://api.ebay.com/oauth/api_scope/sell.account https://api.ebay.com/oauth/api_scope/sell.fulfillment.readonly https://api.ebay.com/oauth/api_scope/sell.fulfillment https://api.ebay.com/oauth/api_scope/sell.analytics.readonly https://api.ebay.com/oauth/api_scope/sell.finances https://api.ebay.com/oauth/api_scope/sell.payment.dispute https://api.ebay.com/oauth/api_scope/commerce.identity.readonly https://api.ebay.com/oauth/api_scope/commerce.notification.subscription https://api.ebay.com/oauth/api_scope/commerce.notification.subscription.readonly";
