import * as React from "react";
import { useTheme } from "@mui/material/styles";
import {
  BarChart,
  XAxis,
  ResponsiveContainer,
  Tooltip,
  Bar,
  YAxis,
  LabelList,
} from "recharts";

export default function Chart({
  chartData = [],
  xAxisKeys = [],
  yAxisKeys = [],
  yAxisNames = [],
  showTooltip = true,
  showLabels = false,
  showXAxis = false,
  margin = {
    top: 16,
    right: -25,
    bottom: 0,
  },
  renderTooltip = null,
  labelFontSize = 24,
  labelFontWeight = 600,
}) {
  const theme = useTheme();

  const BarColors = [theme.palette.primary.main, theme.palette.secondary.main];

  return (
    <ResponsiveContainer>
      <BarChart
        data={chartData}
        margin={margin}
        key={Math.random()} //gets the chart to re-animate when data changes
      >
        {xAxisKeys.map((key) => (
          <XAxis
            dataKey={key}
            stroke={theme.palette.text.secondary}
            style={theme.typography.body2}
            hide={!showXAxis}
          />
        ))}

        {showTooltip && (
          <Tooltip
            contentStyle={{
              backgroundColor:
                theme.palette.mode === "dark"
                  ? "rgb(0, 0, 0, 0.9)"
                  : "rgb(250, 250, 250, 0.9)",
              border: "0px",
              borderRadius: "10px",
            }}
            {...(renderTooltip
              ? { content: (data) => renderTooltip(data) }
              : {})}
          />
        )}

        {yAxisKeys.map((key, index) => (
          <Bar
            isAnimationActive={true}
            dataKey={key}
            name={yAxisNames[index]}
            fill={BarColors[index]}
          >
            {showLabels && (
              <LabelList
                dataKey={key}
                position="top"
                offset={15}
                formatter={(value) => (value === 0 ? "" : `$${value}`)}
                fontSize={labelFontSize}
                fontWeight={labelFontWeight}
                fill="white"
              />
            )}
          </Bar>
        ))}
        <YAxis orientation="right" />
      </BarChart>
    </ResponsiveContainer>
  );
}
