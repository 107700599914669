import React from "react";
import moment from "moment";
import { merge } from "lodash";
import AllInclusiveIcon from "@mui/icons-material/AllInclusive";

export const getFormattedDecimal = (number, decimalPoints = 2) => {
  return (
    Math.round(number * Math.pow(10, decimalPoints)) /
    Math.pow(10, decimalPoints)
  );
};

export const getFormattedPercentage = (number, decimalPoints = 0) => {
  const decimal = getFormattedDecimal(number * 100, decimalPoints);
  if (decimal === Infinity) return <AllInclusiveIcon />;
  return getFormattedDecimal(number * 100, decimalPoints);
};

export const formatChartData = (chartData) => {
  let newChartData = [];
  chartData.forEach((point) => {
    let formattedPoint = {};
    Object.keys(point).forEach((key) => {
      if (Number.isFinite(point[key]))
        formattedPoint[key] = getFormattedDecimal(point[key]);
      else if (key === "date")
        formattedPoint[key] = point[key].format("MMM Do");
      else formattedPoint[key] = point[key];
    });
    newChartData.push(formattedPoint);
  });

  return newChartData;
};

export const isSaleValid = (status) =>
  status !== "CANCEL" &&
  status !== "CANCELED" &&
  status !== "RETURN_COMPLETED" &&
  status !== "RETURN_PENDING" &&
  status !== "RETURN_INPROGRESS" &&
  status !== "FAILED" &&
  status !== "AWAITING_PAYMENT" &&
  status !== "REFUNDED";

export const isSaleCancelled = (status) => status === "CANCEL";

export const isSaleReturned = (status) => status.includes("RETURN");

export const buildDailyChartData = (orders = [], fields = {}) => {
  const chartData = [merge({ date: moment(orders[0]?.creationDate) }, fields)];

  let i = 0;
  while (orders[i]) {
    const { creationDate = null } = orders[i];

    if (chartData[chartData.length - 1].date.isSame(creationDate, "day")) {
      Object.keys(fields).forEach((key) => {
        if (key === "orderCount") chartData[chartData.length - 1][key] += 1;
        else if (isSaleValid(orders[i].status))
          chartData[chartData.length - 1][key] += orders[i][key] || 0;
      });
      i++;
    } else {
      chartData.push(
        merge(
          { date: moment(chartData[chartData.length - 1].date).add(1, "day") },
          fields
        )
      );
    }
  }

  return chartData;
};

export const filterChartDataByDateRange = ({
  chartData,
  startDate,
  endDate,
}) => {
  return chartData.filter((point) => {
    if (
      point.date.isSameOrBefore(endDate, "day") &&
      point.date.isSameOrAfter(startDate, "day")
    )
      return true;

    return false;
  });
};

export const getEstimatedProfit = ({
  feesPercentage,
  additionalFees,
  salesTaxRate,
  buyPrice,
  sellPrice,
}) => {
  const finalValueFee =
    (sellPrice + sellPrice * salesTaxRate) * feesPercentage + additionalFees;
  const totalBuyPrice = buyPrice + buyPrice * salesTaxRate;
  const profit = sellPrice - totalBuyPrice - finalValueFee;
  return profit;
};

export const getNewSellPrice = ({
  profitMargin,
  additionalProfit,
  buyPrice,
  feesPercentage,
  additionalFees,
  salesTaxRate,
  roundCentsTo = 0.99,
  roundCents = false,
  sellPrice,
}) => {
  let newSellPrice = sellPrice;
  if (buyPrice !== null) {
    const totalBuyPrice = buyPrice + buyPrice * salesTaxRate;

    newSellPrice =
      (totalBuyPrice +
        additionalProfit +
        totalBuyPrice * profitMargin +
        additionalFees) /
      (1 - feesPercentage - salesTaxRate * feesPercentage);
  }
  const sellPriceBeforeRounding = newSellPrice;

  if (roundCents) {
    if (
      parseFloat((newSellPrice - Math.floor(newSellPrice)).toFixed(2)) >
      roundCentsTo
    ) {
      newSellPrice += 1;
    }
    newSellPrice = Math.floor(newSellPrice) + roundCentsTo;
  }

  return { newSellPrice, sellPriceBeforeRounding };
};
