declare module "@mui/material/styles" {
  interface Components {
    [key: string]: any;
  }
}
import { createTheme } from "@mui/material/styles";

const light = createTheme({
  transitions: { duration: { leavingScreen: 200, enteringScreen: 200 } },
  components: {
    MuiList: {
      styleOverrides: {
        root: {
          backgroundColor: "#ffffff",
        },
      },
    },
  },
  typography: {
    fontFamily: ["Inter", "sans-serif"].join(","),
  },
  palette: {
    mode: "light",
    text: {
      primary: "#0b3041",
    },
    background: {
      default: "#f8f9fd",
      paper: "#ffffff",
    },
    primary: {
      main: "#008dfe",
    },
    secondary: {
      main: "rgb(240, 240, 240)",
    },
  },
});

const dark = createTheme({
  transitions: { duration: { leavingScreen: 200, enteringScreen: 200 } },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          background: "#1e1e1e",
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            backgroundColor: "rgba(255, 255, 255, 0.1)",
          },
        },
      },
    },
    MUIDataTable: {
      styleOverrides: {
        root: {
          borderRadius: "15px",
        },
        responsiveBase: {
          padding: "0px 45px 0px 45px",
          backgroundColor: "#1e1e1e",
        },
      },
    },
    MUIDataTableFooter: {
      styleOverrides: {
        root: {
          backgroundColor: "#1e1e1e",
          border: "none",
          borderRadius: "0px 0px 15px 15px",
        },
      },
    },
    MUIDataTableToolbar: {
      styleOverrides: {
        root: {
          borderRadius: "15px 15px 0px 0px !important",
          backgroundColor: "#1e1e1e",
          padding: "25px 40px 25px 40px",
        },
      },
    },
  },
  typography: {
    fontFamily: ["Inter", "sans-serif"].join(","),
  },
  palette: {
    mode: "dark",
    primary: {
      main: "#3399FF",
    },
    secondary: {
      main: "rgb(26 26 26)",
    },
    background: {
      paper: "#1e1e1e",
      default: "#121212",
    },
  },
});

const dark2 = createTheme({
  transitions: { duration: { leavingScreen: 200, enteringScreen: 200 } },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          background: "#2a2d3e",
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            backgroundColor: "rgba(255, 255, 255, 0.1)",
          },
        },
      },
    },
    MUIDataTable: {
      styleOverrides: {
        root: {
          borderRadius: "15px",
          boxShadow: "6px 6px 10px rgba(0, 0, 0, 0.06)",
        },
        responsiveBase: {
          padding: "0px 45px 0px 45px",
          backgroundColor: "#2a2d3e",
        },
      },
    },
    MUIDataTableFooter: {
      styleOverrides: {
        root: {
          backgroundColor: "#2a2d3e",
          border: "none",
          borderRadius: "0px 0px 15px 15px",
        },
      },
    },
    MUIDataTableToolbar: {
      styleOverrides: {
        root: {
          borderRadius: "15px 15px 0px 0px !important",
          backgroundColor: "#2a2d3e",
          padding: "25px 40px 25px 40px",
        },
      },
    },
  },
  typography: {
    fontFamily: ["Inter", "sans-serif"].join(","),
  },
  palette: {
    mode: "dark",
    primary: {
      main: "#44a5ff",
    },
    secondary: {
      main: "rgb(26 26 26)",
    },
    background: {
      paper: "#2a2d3e",
      default: "#212332",
    },
  },
});

const dark3 = createTheme({
  transitions: { duration: { leavingScreen: 200, enteringScreen: 200 } },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          background: "#212b37",
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            backgroundColor: "rgba(255, 255, 255, 0.1)",
          },
        },
      },
    },
    MUIDataTable: {
      styleOverrides: {
        root: {
          borderRadius: "15px",
          boxShadow: "6px 6px 10px rgba(0, 0, 0, 0.06)",
        },
        responsiveBase: {
          padding: "0px 45px 0px 45px",
          backgroundColor: "#212b37",
        },
      },
    },
    MUIDataTableFooter: {
      styleOverrides: {
        root: {
          backgroundColor: "#212b37",
          border: "none",
          borderRadius: "0px 0px 15px 15px",
        },
      },
    },
    MUIDataTableToolbar: {
      styleOverrides: {
        root: {
          borderRadius: "15px 15px 0px 0px !important",
          backgroundColor: "#212b37",
          padding: "25px 40px 25px 40px",
        },
      },
    },
  },
  typography: {
    fontFamily: ["Inter", "sans-serif"].join(","),
  },
  palette: {
    mode: "dark",
    primary: {
      main: "#078dee",
    },
    secondary: {
      main: "rgb(26 26 26)",
    },
    background: {
      paper: "#212b37",
      default: "#181c25",
    },
  },
});

const dark4 = createTheme({
  transitions: { duration: { leavingScreen: 200, enteringScreen: 200 } },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          background: "#1e1f25",
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            backgroundColor: "rgba(255, 255, 255, 0.1)",
          },
        },
      },
    },
    MUIDataTable: {
      styleOverrides: {
        root: {
          borderRadius: "15px",
          boxShadow: "6px 6px 10px rgba(0, 0, 0, 0.06)",
        },
        responsiveBase: {
          padding: "0px 45px 0px 45px",
          backgroundColor: "#1e1f25",
        },
      },
    },
    MUIDataTableFooter: {
      styleOverrides: {
        root: {
          backgroundColor: "#1e1f25",
          border: "none",
          borderRadius: "0px 0px 15px 15px",
        },
      },
    },
    MUIDataTableToolbar: {
      styleOverrides: {
        root: {
          borderRadius: "15px 15px 0px 0px !important",
          backgroundColor: "#1e1f25",
          padding: "25px 40px 25px 40px",
        },
      },
    },
  },
  typography: {
    fontFamily: ["Inter", "sans-serif"].join(","),
  },
  palette: {
    mode: "dark",
    primary: {
      main: "#26a5ec",
    },
    secondary: {
      main: "#febb35",
    },
    background: {
      paper: "#1e1f25",
      default: "#131517",
    },
    success: {
      main: "#00d68f",
    },
    error: {
      main: "#ff3d71",
    },
  },
});

const dark5 = createTheme({
  transitions: { duration: { leavingScreen: 200, enteringScreen: 200 } },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          background: "#1e2742",
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            backgroundColor: "rgba(255, 255, 255, 0.1)",
          },
        },
      },
    },
    MUIDataTable: {
      styleOverrides: {
        root: {
          borderRadius: "15px",
          boxShadow: "6px 6px 10px rgba(0, 0, 0, 0.06)",
        },
        responsiveBase: {
          padding: "0px 45px 0px 45px",
          backgroundColor: "#1e2742",
        },
      },
    },
    MUIDataTableFooter: {
      styleOverrides: {
        root: {
          backgroundColor: "#1e2742",
          border: "none",
          borderRadius: "0px 0px 15px 15px",
        },
      },
    },
    MUIDataTableToolbar: {
      styleOverrides: {
        root: {
          borderRadius: "15px 15px 0px 0px !important",
          backgroundColor: "#1e2742",
          padding: "25px 40px 25px 40px",
        },
      },
    },
  },
  typography: {
    fontFamily: ["Inter", "sans-serif"].join(","),
  },
  palette: {
    mode: "dark",
    primary: {
      main: "#7259ff",
    },
    secondary: {
      main: "rgb(26 26 26)",
    },
    background: {
      paper: "#1e2742",
      default: "#13192f",
    },
  },
});

const dark6 = createTheme({
  transitions: { duration: { leavingScreen: 200, enteringScreen: 200 } },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          background: "#28273f",
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            backgroundColor: "rgba(255, 255, 255, 0.1)",
          },
        },
      },
    },
    MUIDataTable: {
      styleOverrides: {
        root: {
          borderRadius: "15px",
          boxShadow: "6px 6px 10px rgba(0, 0, 0, 0.06)",
        },
        responsiveBase: {
          padding: "0px 45px 0px 45px",
          backgroundColor: "#28273f",
        },
      },
    },
    MUIDataTableFooter: {
      styleOverrides: {
        root: {
          backgroundColor: "#28273f",
          border: "none",
          borderRadius: "0px 0px 15px 15px",
        },
      },
    },
    MUIDataTableToolbar: {
      styleOverrides: {
        root: {
          borderRadius: "15px 15px 0px 0px !important",
          backgroundColor: "#28273f",
          padding: "25px 40px 25px 40px",
        },
      },
    },
  },
  typography: {
    fontFamily: ["Inter", "sans-serif"].join(","),
  },
  palette: {
    mode: "dark",
    primary: {
      main: "#7f5cff",
    },
    secondary: {
      main: "rgb(26 26 26)",
    },
    background: {
      paper: "#28273f",
      default: "#1f1c30",
    },
  },
});

const dark7 = createTheme({
  transitions: { duration: { leavingScreen: 200, enteringScreen: 200 } },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          background: "#3d3e49",
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            backgroundColor: "rgba(255, 255, 255, 0.1)",
          },
        },
      },
    },
    MUIDataTable: {
      styleOverrides: {
        root: {
          borderRadius: "15px",
          boxShadow: "6px 6px 10px rgba(0, 0, 0, 0.06)",
        },
        responsiveBase: {
          padding: "0px 45px 0px 45px",
          backgroundColor: "#3d3e49",
        },
      },
    },
    MUIDataTableFooter: {
      styleOverrides: {
        root: {
          backgroundColor: "#3d3e49",
          border: "none",
          borderRadius: "0px 0px 15px 15px",
        },
      },
    },
    MUIDataTableToolbar: {
      styleOverrides: {
        root: {
          borderRadius: "15px 15px 0px 0px !important",
          backgroundColor: "#3d3e49",
          padding: "25px 40px 25px 40px",
        },
      },
    },
  },
  typography: {
    fontFamily: ["Inter", "sans-serif"].join(","),
  },
  palette: {
    mode: "dark",
    primary: {
      main: "#ffa800",
    },
    secondary: {
      main: "rgb(26 26 26)",
    },
    background: {
      paper: "#3d3e49",
      default: "#33353f",
    },
  },
});

const ocean = createTheme({
  transitions: { duration: { leavingScreen: 200, enteringScreen: 200 } },
  components: {
    MuiList: {
      styleOverrides: {
        root: {
          backgroundColor: "#0d1d2f",
        },
      },
    },
  },
  typography: {
    fontFamily: ["Inter", "sans-serif"].join(","),
  },
  palette: {
    mode: "dark",
    background: {
      default: "#071325",
      paper: "#0d1d2f",
    },
    primary: {
      main: "#2c7ae4",
    },
    secondary: {
      main: "#122337",
    },
  },
});

const nebula = createTheme({
  transitions: { duration: { leavingScreen: 200, enteringScreen: 200 } },
  components: {
    MuiList: {
      styleOverrides: {
        root: {
          backgroundColor: "#0c1427",
        },
      },
    },
  },
  typography: {
    fontFamily: ["Inter", "sans-serif"].join(","),
  },
  palette: {
    mode: "dark",
    background: {
      default: "#070d19",
      paper: "#0c1427",
    },
    primary: {
      main: "#737cf4",
    },
    secondary: {
      main: "#0c1427",
    },
  },
});

export default {
  light,
  dark,
  dark2,
  dark3,
  dark4,
  dark5,
  dark6,
  dark7,
  ocean,
  nebula,
};
