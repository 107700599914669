import { doc, Firestore, getDoc, updateDoc } from "firebase/firestore";

const FeeConfigService = (db: Firestore) => ({
  getFeeConfigs: async (uid: string) => {
    const userObjectSnapshot = await getDoc(doc(db, "users", uid));
    const userObject = userObjectSnapshot.data();
    if (userObject?.feeConfigs) {
      return userObject.feeConfigs;
    }
  },
  setFeeConfigs: (
    uid: string,
    feeConfigs: {
      orderCreditPrice: number;
      walletLoadingPercentage: number;
    }
  ) => {
    return updateDoc(doc(db, "users", uid), { feeConfigs });
  },
});

export default FeeConfigService;
