import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import MovingIcon from "@mui/icons-material/Moving";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Tooltip,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/system";
import moment from "moment";
import React, { useContext, useEffect, useMemo, useState } from "react";
import DashboardCardChartHybridLarge from "../../../components/DashboardCardChartHybridLarge";
import DashboardCardChartHybridSmall, {
  ChartTypes,
} from "../../../components/DashboardCardChartHybridSmall";
import DashboardCardExtraSmall from "../../../components/DashboardCardExtraSmall";
import {
  DASHBOARD_GAP,
  MEDIUM_SCREEN_WIDTH,
  SECONDARY_TEXT_COLOR,
  SMALL_SCREEN_WIDTH,
} from "../../../constants";
import WindowContext from "../../../context/WindowContext";
import {
  formatChartData,
  getFormattedDecimal,
  getFormattedPercentage,
} from "../../../utils";

const NeutralIcon = () => {
  return <HorizontalRuleIcon style={{ marginRight: "10px" }} />;
};

const StatisticsTextBlock = ({ children }) => (
  <Typography style={{ display: "flex", alignItems: "center" }}>
    {children}
  </Typography>
);

const DashboardCardSecondaryText = ({ children }) => (
  <span style={{ color: SECONDARY_TEXT_COLOR, marginLeft: "5px" }}>
    {children}
  </span>
);

const dataKeyToLabelMap = {
  profit: "Profit",
  buyPrice: "Total Buy Price",
  buyTax: "Total Buy Tax",
  revenue: "Total Sales",
  finalValueFees: "Ebay Final Value Fees",
  autodsFees: "AutoDS Fee's",
  orderCount: "Order Count",
  ebayAdFees: "Ebay Ad Fees",
};

const renderTooltip = (data, theme) => {
  if (data.active) {
    return (
      <div
        style={{
          backgroundColor:
            theme.palette.mode === "dark"
              ? "rgb(0, 0, 0, 0.9)"
              : "rgb(250, 250, 250, 0.9)",
          border: "0px",
          borderRadius: "10px",
          padding: "15px",
        }}
      >
        <div style={{ marginBottom: "10px" }}>
          {data.payload?.[0]?.payload?.date}
        </div>
        {Object.entries(data.payload?.[0]?.payload ?? {}).map(
          ([key, value]) => (
            <>
              {key !== "date" && (
                <div>
                  {dataKeyToLabelMap[key]}: {value}
                </div>
              )}
            </>
          )
        )}
      </div>
    );
  }
};

const Analytics = ({
  analytics,
  numberOfOrders,
  startDate,
  endDate,
  isDataLoading,
  lastThirtyDaysOrders,
  filteredOrders,
}) => {
  const theme = useTheme();
  const [sevenDayOrderCount, setSevenDayOrderCount] = useState(null);
  const [thirtyDayOrderCount, setThirtyDayOrderCount] = useState(null);
  const [sevenDayProfit, setSevenDayProfit] = useState(0);
  const [thirtyDayProfit, setThirtyDayProfit] = useState(0);
  const [sevenDayOrderCountAverage, setSevenDayOrderCountAverage] =
    useState(null);
  const [thirtyDayOrderCountAverage, setThirtyDayOrderCountAverage] =
    useState(null);
  const [totalProfitChartData, setTotalProfitChartData] = useState([]);
  const [totalOrdersChartData, setTotalOrdersChartData] = useState([]);
  const [dailyProfitChartData, setDailyProfitChartData] = useState([]);
  const [dailyOrdersChartData, setDailyOrdersChartData] = useState([]);
  const [salesOverviewChartData, setSalesOverviewChartData] = useState([]);
  const [dailyProfitPerOrderChartData, setDailyProfitPerOrderChartData] =
    useState([]);
  const [bestDay, setBestDay] = useState({});
  const { width: screenWidth } = useContext(WindowContext);

  const {
    totalProfit,
    profitPerOrder,
    totalSales,
    totalBuyPrice,
    totalBuyTax,
    totalAutoDsCreditFees,
    totalAutoDsWalletFees,
    totalFinalValueFees,
    totalAdFees,
    averageDailyProfit,
    averageDailyOrders,
  } = analytics;

  const IncreaseIcon = () => {
    return (
      <MovingIcon
        style={{ color: theme.palette.success.main, marginRight: "10px" }}
      />
    );
  };

  const DecreaseIcon = () => {
    return (
      <MovingIcon
        style={{
          color: theme.palette.error.main,
          marginRight: "10px",
          transform: "scaleY(-1)",
        }}
      />
    );
  };

  const dates = useMemo(
    () => ({
      sixDaysAgo: moment().subtract(6, "days"),
      twentyNineDaysAgo: moment().subtract(29, "days"),
    }),
    []
  );

  useEffect(() => {
    let newSevenDayProfit = 0;
    let newThirtyDayProfit = 0;

    setSevenDayOrderCount(
      lastThirtyDaysOrders.filter((order) => {
        if (order.creationDate.isSameOrAfter(dates.sixDaysAgo, "day")) {
          newSevenDayProfit += order.profit;
          return true;
        } else return false;
      }).length
    );
    setSevenDayProfit(getFormattedDecimal(newSevenDayProfit));

    setThirtyDayOrderCount(
      lastThirtyDaysOrders.filter((order) => {
        if (order.creationDate.isSameOrAfter(dates.twentyNineDaysAgo, "day")) {
          newThirtyDayProfit += order.profit;
          return true;
        } else return false;
      }).length
    );
    setThirtyDayProfit(getFormattedDecimal(newThirtyDayProfit));
  }, [
    dates.sixDaysAgo,
    dates.twentyNineDaysAgo,
    lastThirtyDaysOrders,
    sevenDayOrderCountAverage,
    thirtyDayOrderCount,
  ]);

  useEffect(() => {
    setSevenDayOrderCountAverage(getFormattedDecimal(sevenDayOrderCount / 7));
    setThirtyDayOrderCountAverage(
      getFormattedDecimal(thirtyDayOrderCount / 30)
    );
  }, [sevenDayOrderCount, thirtyDayOrderCount]);

  //build chart data
  useEffect(() => {
    //Do I only push valid sales (not cancelled, etc.) to the order count so average profit per order does not get messed up? right now im not

    let newBestDay = { profit: 0, orderCount: 0 };

    const newTotalProfitChartData = [
      {
        date: startDate,
        totalProfit: 0,
      },
    ];
    const newTotalOrdersChartData = [
      {
        date: startDate,
        totalOrders: 0,
      },
    ];
    const newDailyProfitChartData = [
      {
        date: startDate,
        profit: 0,
      },
    ];
    const newDailyProfitPerOrderChartData = [
      {
        date: startDate,
        profitPerOrder: 0,
      },
    ];
    const newDailyOrdersChartData = [
      {
        date: startDate,
        orderCount: 0,
      },
    ];
    const newSalesOverviewChartData = [
      {
        date: startDate,
        orderCount: 0,
        profit: 0,
        revenue: 0,
        buyPrice: 0,
        buyTax: 0,
        finalValueFees: 0,
        ebayAdFees: 0,
        autodsFees: 0,
      },
    ];
    if (filteredOrders.length) {
      let collectOrdersDate = startDate;

      filteredOrders.forEach((order, index) => {
        const {
          creationDate,
          profit,
          buyPrice,
          buyTax,
          sellPrice,
          finalValueFee,
          ebayAdFee,
          autodsCreditFee,
          autodsWalletFee,
        } = order;

        if (collectOrdersDate.isBefore(creationDate, "day")) {
          if (
            newDailyProfitChartData[newDailyProfitChartData.length - 1].profit >
            (newBestDay?.profit ?? 0)
          ) {
            newBestDay = {
              profit:
                newDailyProfitChartData[newDailyProfitChartData.length - 1]
                  .profit,
              date: newDailyProfitChartData[newDailyProfitChartData.length - 1]
                .date,
              orderCount:
                newDailyOrdersChartData[newDailyOrdersChartData.length - 1]
                  .orderCount,
            };
          }
          newDailyProfitPerOrderChartData[
            newDailyProfitPerOrderChartData.length - 1
          ].profitPerOrder =
            newDailyProfitChartData[newDailyProfitChartData.length - 1].profit /
            newDailyOrdersChartData[newDailyOrdersChartData.length - 1]
              .orderCount;
        }

        while (collectOrdersDate.isBefore(creationDate, "day")) {
          collectOrdersDate = moment(collectOrdersDate).add(1, "day");

          newTotalProfitChartData.push({
            date: collectOrdersDate,
            totalProfit:
              newTotalProfitChartData[newTotalProfitChartData.length - 1]
                .totalProfit,
          });
          newTotalOrdersChartData.push({
            date: collectOrdersDate,
            totalOrders:
              newTotalOrdersChartData[newTotalOrdersChartData.length - 1]
                .totalOrders,
          });
          newDailyProfitChartData.push({
            date: collectOrdersDate,
            profit: 0,
          });
          newDailyProfitPerOrderChartData.push({
            date: collectOrdersDate,
            profitPerOrder: 0,
          });
          newDailyOrdersChartData.push({
            date: collectOrdersDate,
            orderCount: 0,
          });
          newSalesOverviewChartData.push({
            date: collectOrdersDate,
            orderCount: 0,
            profit: 0,
            revenue: 0,
            buyPrice: 0,
            buyTax: 0,
            finalValueFees: 0,
            ebayAdFees: 0,
            autodsFees: 0,
          });
        }
        if (collectOrdersDate.isSame(creationDate, "day")) {
          newTotalProfitChartData[
            newTotalProfitChartData.length - 1
          ].totalProfit += profit || 0;
          newTotalOrdersChartData[
            newTotalOrdersChartData.length - 1
          ].totalOrders += 1;
          newDailyProfitChartData[newDailyProfitChartData.length - 1].profit +=
            profit || 0;
          newDailyOrdersChartData[
            newDailyOrdersChartData.length - 1
          ].orderCount += 1;
          newSalesOverviewChartData[
            newSalesOverviewChartData.length - 1
          ].profit += profit || 0;
          newSalesOverviewChartData[
            newSalesOverviewChartData.length - 1
          ].buyPrice += buyPrice || 0;
          newSalesOverviewChartData[
            newSalesOverviewChartData.length - 1
          ].buyTax += buyTax || 0;
          newSalesOverviewChartData[
            newSalesOverviewChartData.length - 1
          ].revenue += sellPrice || 0;
          newSalesOverviewChartData[
            newSalesOverviewChartData.length - 1
          ].finalValueFees += finalValueFee || 0;
          newSalesOverviewChartData[
            newSalesOverviewChartData.length - 1
          ].autodsFees += autodsCreditFee + autodsWalletFee || 0;
          newSalesOverviewChartData[
            newSalesOverviewChartData.length - 1
          ].ebayAdFees += ebayAdFee || 0;
          newSalesOverviewChartData[
            newSalesOverviewChartData.length - 1
          ].orderCount += 1;
        }

        if (index === filteredOrders.length - 1) {
          newDailyProfitPerOrderChartData[
            newDailyProfitPerOrderChartData.length - 1
          ].profitPerOrder =
            newDailyProfitChartData[newDailyProfitChartData.length - 1].profit /
            newDailyOrdersChartData[newDailyOrdersChartData.length - 1]
              .orderCount;

          if (
            newDailyProfitChartData[newDailyProfitChartData.length - 1].profit >
            (newBestDay?.profit ?? 0)
          ) {
            newBestDay = {
              profit:
                newDailyProfitChartData[newDailyProfitChartData.length - 1]
                  .profit,
              date: newDailyProfitChartData[newDailyProfitChartData.length - 1]
                .date,
              orderCount:
                newDailyOrdersChartData[newDailyOrdersChartData.length - 1]
                  .orderCount,
            };
          }

          while (collectOrdersDate.isBefore(endDate, "day")) {
            collectOrdersDate = moment(collectOrdersDate).add(1, "day");

            newTotalProfitChartData.push({
              date: collectOrdersDate,
              totalProfit:
                newTotalProfitChartData[newTotalProfitChartData.length - 1]
                  .totalProfit,
            });
            newTotalOrdersChartData.push({
              date: collectOrdersDate,
              totalOrders:
                newTotalOrdersChartData[newTotalOrdersChartData.length - 1]
                  .totalOrders,
            });
            newDailyProfitChartData.push({
              date: collectOrdersDate,
              profit: 0,
            });
            newDailyProfitPerOrderChartData.push({
              date: collectOrdersDate,
              profitPerOrder: 0,
            });
            newDailyOrdersChartData.push({
              date: collectOrdersDate,
              orderCount: 0,
            });
            newSalesOverviewChartData.push({
              date: collectOrdersDate,
              orderCount: 0,
              profit: 0,
              revenue: 0,
              buyPrice: 0,
              buyTax: 0,
              finalValueFees: 0,
              autodsFees: 0,
              ebayAdFees: 0,
            });
          }
        }
      });
      setTotalProfitChartData(newTotalProfitChartData);
      setTotalOrdersChartData(newTotalOrdersChartData);
      setDailyProfitChartData(newDailyProfitChartData);
      setDailyProfitPerOrderChartData(newDailyProfitPerOrderChartData);
      setDailyOrdersChartData(newDailyOrdersChartData);
      setSalesOverviewChartData(newSalesOverviewChartData);
    }

    setBestDay(newBestDay);
  }, [startDate, endDate, filteredOrders, lastThirtyDaysOrders.length]);

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        gap: `${DASHBOARD_GAP}px`,
        flexWrap: "wrap",
        justifyContent: "center",
      }}
    >
      <DashboardCardChartHybridSmall
        title="Net Profit"
        chartProps={{
          chartData: formatChartData(totalProfitChartData).slice(-50),
          xAxisKeys: ["date"],
          yAxisKeys: ["totalProfit"],
          yAxisNames: ["Total Profit"],
        }}
        chartType={ChartTypes.LINE}
        isLoading={isDataLoading}
      >
        <Typography variant="h4" style={{ marginBottom: "20px" }}>
          ${getFormattedDecimal(totalProfit).toLocaleString()}
        </Typography>
        <StatisticsTextBlock>
          {sevenDayProfit === 0 && <NeutralIcon />}
          {sevenDayProfit > 0 && <IncreaseIcon />}
          {sevenDayProfit < 0 && <DecreaseIcon />}${Math.abs(sevenDayProfit)}{" "}
          <DashboardCardSecondaryText>
            in last 7 days
          </DashboardCardSecondaryText>
        </StatisticsTextBlock>
        <StatisticsTextBlock>
          {thirtyDayProfit === 0 && <NeutralIcon />}
          {thirtyDayProfit > 0 && <IncreaseIcon />}
          {thirtyDayProfit < 0 && <DecreaseIcon />}${Math.abs(thirtyDayProfit)}{" "}
          <DashboardCardSecondaryText>
            in last 30 days
          </DashboardCardSecondaryText>
        </StatisticsTextBlock>
      </DashboardCardChartHybridSmall>

      <DashboardCardChartHybridSmall
        title="Average Daily Profit"
        chartProps={{
          chartData: formatChartData(dailyProfitChartData).slice(-50),
          xAxisKeys: ["date"],
          yAxisKeys: ["profit"],
          yAxisNames: ["Profit $"],
        }}
        chartType={ChartTypes.BAR}
        isLoading={isDataLoading}
      >
        <Typography variant="h4" style={{ marginBottom: "20px" }}>
          ${getFormattedDecimal(averageDailyProfit).toLocaleString()}
        </Typography>
        <StatisticsTextBlock>
          {getFormattedDecimal(sevenDayProfit / 7) >
            getFormattedDecimal(averageDailyProfit) && <DecreaseIcon />}
          {getFormattedDecimal(sevenDayProfit / 7) <
            getFormattedDecimal(averageDailyProfit) && <IncreaseIcon />}
          {getFormattedDecimal(sevenDayProfit / 7) ===
            getFormattedDecimal(averageDailyProfit) && <NeutralIcon />}
          {getFormattedPercentage(
            (averageDailyProfit - sevenDayProfit / 7) / (sevenDayProfit / 7) ||
              0
          )}
          %
          <DashboardCardSecondaryText>
            vs last 7 days (${getFormattedDecimal(sevenDayProfit / 7)})
          </DashboardCardSecondaryText>
        </StatisticsTextBlock>
        <StatisticsTextBlock>
          {getFormattedDecimal(thirtyDayProfit / 30) >
            getFormattedDecimal(averageDailyProfit) && <DecreaseIcon />}
          {getFormattedDecimal(thirtyDayProfit / 30) <
            getFormattedDecimal(averageDailyProfit) && <IncreaseIcon />}
          {getFormattedDecimal(thirtyDayProfit / 30) ===
            getFormattedDecimal(averageDailyProfit) && <NeutralIcon />}
          {getFormattedPercentage(
            (averageDailyProfit - thirtyDayProfit / 30) /
              (thirtyDayProfit / 30) || 0
          )}
          %
          <DashboardCardSecondaryText>
            vs last 30 days (${getFormattedDecimal(thirtyDayProfit / 30)})
          </DashboardCardSecondaryText>
        </StatisticsTextBlock>
      </DashboardCardChartHybridSmall>

      <DashboardCardChartHybridSmall
        title="Orders"
        chartProps={{
          chartData: formatChartData(totalOrdersChartData).slice(-50),
          xAxisKeys: ["date"],
          yAxisKeys: ["totalOrders"],
          yAxisNames: ["Total Orders"],
        }}
        isLoading={isDataLoading}
        chartType={ChartTypes.LINE}
      >
        <Typography variant="h4" style={{ marginBottom: "20px" }}>
          {numberOfOrders}{" "}
          <span
            style={{
              color: SECONDARY_TEXT_COLOR,
              fontSize: "24px",
              fontWeight: "600",
            }}
          >
            Orders
          </span>
        </Typography>
        <StatisticsTextBlock>
          {sevenDayOrderCount === 0 && <NeutralIcon />}
          {sevenDayOrderCount > 0 && <IncreaseIcon />}
          {sevenDayOrderCount}
          <DashboardCardSecondaryText>
            in last 7 days
          </DashboardCardSecondaryText>
        </StatisticsTextBlock>
        <StatisticsTextBlock>
          {thirtyDayOrderCount === 0 && <NeutralIcon />}
          {thirtyDayOrderCount > 0 && <IncreaseIcon />}
          {thirtyDayOrderCount}
          <DashboardCardSecondaryText>
            in last 30 days
          </DashboardCardSecondaryText>
        </StatisticsTextBlock>
      </DashboardCardChartHybridSmall>

      <DashboardCardChartHybridSmall
        title="Average Daily Orders"
        chartProps={{
          chartData: formatChartData(dailyOrdersChartData).slice(-50),
          xAxisKeys: ["date"],
          yAxisKeys: ["orderCount"],
          yAxisNames: ["Orders"],
        }}
        isLoading={isDataLoading}
        chartType={ChartTypes.BAR}
      >
        <Typography variant="h4" style={{ marginBottom: "20px" }}>
          {getFormattedDecimal(averageDailyOrders).toLocaleString()}{" "}
          <span
            style={{
              color: SECONDARY_TEXT_COLOR,
              fontSize: "24px",
              fontWeight: "600",
            }}
          >
            Orders
          </span>
        </Typography>
        <StatisticsTextBlock>
          {sevenDayOrderCountAverage >
            getFormattedDecimal(averageDailyOrders) && <DecreaseIcon />}
          {sevenDayOrderCountAverage <
            getFormattedDecimal(averageDailyOrders) && <IncreaseIcon />}
          {sevenDayOrderCountAverage ===
            getFormattedDecimal(averageDailyOrders) && <NeutralIcon />}
          {getFormattedPercentage(
            (averageDailyOrders - sevenDayOrderCountAverage) /
              sevenDayOrderCountAverage || 0
          )}
          %
          <DashboardCardSecondaryText>
            vs last 7 days (
            {getFormattedDecimal(sevenDayOrderCountAverage).toLocaleString()})
          </DashboardCardSecondaryText>
        </StatisticsTextBlock>
        <StatisticsTextBlock>
          {thirtyDayOrderCountAverage >
            getFormattedDecimal(averageDailyOrders) && <DecreaseIcon />}
          {thirtyDayOrderCountAverage <
            getFormattedDecimal(averageDailyOrders) && <IncreaseIcon />}
          {thirtyDayOrderCountAverage ===
            getFormattedDecimal(averageDailyOrders) && <NeutralIcon />}
          {getFormattedPercentage(
            (averageDailyOrders - thirtyDayOrderCountAverage) /
              thirtyDayOrderCountAverage || 0
          )}
          %
          <DashboardCardSecondaryText>
            vs last 30 days (
            {getFormattedDecimal(thirtyDayOrderCountAverage).toLocaleString()})
          </DashboardCardSecondaryText>
        </StatisticsTextBlock>
      </DashboardCardChartHybridSmall>

      <div
        style={{
          display: "flex",
          gap: "inherit",
          justifyContent: "center",
          ...(screenWidth < MEDIUM_SCREEN_WIDTH
            ? { width: "100%" }
            : { width: `calc(50% - ${DASHBOARD_GAP / 2}px)` }),
          ...(screenWidth > SMALL_SCREEN_WIDTH
            ? {}
            : { flexDirection: "column" }),
        }}
      >
        <DashboardCardExtraSmall
          title={
            <div
              style={{
                alignItems: "center",
                display: "flex",
                columnGap: "10px",
              }}
            >
              Future Profit Estimation
              <Tooltip
                arrow
                title="This is an estimate of future profits based off the averages from the specified date range."
                placement="top"
              >
                <InfoOutlinedIcon />
              </Tooltip>
            </div>
          }
        >
          <Typography variant="h6">
            Weekly Profit: $
            {getFormattedDecimal(averageDailyProfit * 7).toLocaleString()}
          </Typography>
          <Typography variant="h6">
            Monthly Profit: $
            {getFormattedDecimal(averageDailyProfit * 31).toLocaleString()}
          </Typography>
          <Typography variant="h6">
            Yearly Profit: $
            {getFormattedDecimal(averageDailyProfit * 365).toLocaleString()}
          </Typography>
        </DashboardCardExtraSmall>

        <DashboardCardExtraSmall title={"Best Day"}>
          <Typography variant="h6">
            Profit: ${getFormattedDecimal(bestDay.profit).toLocaleString()}
          </Typography>
          <Typography variant="h6">
            Orders: {getFormattedDecimal(bestDay.orderCount)}
          </Typography>
          <Typography variant="h6">
            Date: {moment(bestDay.date).format("MMM DD YYYY")}
          </Typography>
        </DashboardCardExtraSmall>
      </div>

      <DashboardCardChartHybridSmall
        title="Average Profit Per Order"
        chartProps={{
          chartData: formatChartData(dailyProfitPerOrderChartData).slice(-50),
          xAxisKeys: ["date"],
          yAxisKeys: ["profitPerOrder"],
          yAxisNames: ["Profit Per Order $"],
        }}
        isLoading={isDataLoading}
        chartType={ChartTypes.BAR}
      >
        <Typography variant="h4" style={{ marginBottom: "20px" }}>
          ${getFormattedDecimal(profitPerOrder).toLocaleString()}{" "}
        </Typography>
        <StatisticsTextBlock>
          {getFormattedDecimal(sevenDayProfit / sevenDayOrderCount) >
            getFormattedDecimal(profitPerOrder) && <DecreaseIcon />}
          {getFormattedDecimal(sevenDayProfit / sevenDayOrderCount) <
            getFormattedDecimal(profitPerOrder) && <IncreaseIcon />}
          {getFormattedDecimal(sevenDayProfit / sevenDayOrderCount) ===
            getFormattedDecimal(profitPerOrder) && <NeutralIcon />}
          {getFormattedPercentage(
            (profitPerOrder - sevenDayProfit / sevenDayOrderCount) /
              (sevenDayProfit / sevenDayOrderCount) || 0
          )}
          %
          <DashboardCardSecondaryText>
            vs last 7 days ($
            {getFormattedDecimal(
              sevenDayProfit / sevenDayOrderCount
            ).toLocaleString()}
            )
          </DashboardCardSecondaryText>
        </StatisticsTextBlock>
        <StatisticsTextBlock>
          {getFormattedDecimal(thirtyDayProfit / thirtyDayOrderCount) >
            getFormattedDecimal(profitPerOrder) && <DecreaseIcon />}
          {getFormattedDecimal(thirtyDayProfit / thirtyDayOrderCount) <
            getFormattedDecimal(profitPerOrder) && <IncreaseIcon />}
          {getFormattedDecimal(thirtyDayProfit / thirtyDayOrderCount) ===
            getFormattedDecimal(profitPerOrder) && <NeutralIcon />}
          {getFormattedPercentage(
            (profitPerOrder - thirtyDayProfit / thirtyDayOrderCount) /
              (thirtyDayProfit / thirtyDayOrderCount) || 0
          )}
          %
          <DashboardCardSecondaryText>
            vs last 30 days ($
            {getFormattedDecimal(
              thirtyDayProfit / thirtyDayOrderCount
            ).toLocaleString()}
            )
          </DashboardCardSecondaryText>
        </StatisticsTextBlock>
      </DashboardCardChartHybridSmall>

      {screenWidth > SMALL_SCREEN_WIDTH && (
        <DashboardCardChartHybridLarge
          title="Sales Overview"
          chartProps={{
            chartData: formatChartData(salesOverviewChartData).slice(-200),
            xAxisKeys: ["date"],
            yAxisKeys: ["profit", "orderCount"],
            yAxisNames: ["Profit", "Order Count"],
            showXAxis: true,
          }}
          isLoading={isDataLoading}
          chartType={ChartTypes.BAR}
          renderTooltip={(data) => renderTooltip(data, theme)}
          showLegend={true}
        >
          <div>
            <Typography style={{ display: "flex", alignItems: "center" }}>
              <KeyboardDoubleArrowUpIcon
                style={{
                  color: "green",
                  marginRight: "5px",
                }}
              />
              Total Sales: ${getFormattedDecimal(totalSales).toLocaleString()}
            </Typography>
            <Accordion
              style={{
                width: "100%",
                boxShadow: "none",
                borderBottom: `${theme.palette.divider} 1px solid`,
                borderRadius: "0px",
                paddingBottom: "10px",
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                style={{ padding: "0" }}
              >
                <Typography style={{ display: "flex", alignItems: "center" }}>
                  <KeyboardDoubleArrowDownIcon
                    style={{
                      color: "red",
                      marginRight: "5px",
                    }}
                  />{" "}
                  Total Cost/Fees: $
                  {getFormattedDecimal(
                    totalBuyPrice +
                      totalBuyTax +
                      totalFinalValueFees +
                      totalAdFees +
                      totalAutoDsWalletFees +
                      totalAutoDsCreditFees
                  ).toLocaleString()}
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                style={{
                  padding: "0",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Typography>
                  Total Buy Price: $
                  {getFormattedDecimal(totalBuyPrice).toLocaleString()}
                </Typography>
                <Typography>
                  Total Buy Tax: $
                  {getFormattedDecimal(totalBuyTax).toLocaleString()}
                </Typography>
                <Typography>
                  Ebay Final Value Fees: $
                  {getFormattedDecimal(totalFinalValueFees).toLocaleString()}
                </Typography>
                <Typography>
                  Ebay Ad Fees: $
                  {getFormattedDecimal(totalAdFees).toLocaleString()}
                </Typography>
                <Typography>
                  AutoDS Fees: $
                  {getFormattedDecimal(
                    totalAutoDsCreditFees + totalAutoDsWalletFees
                  ).toLocaleString()}
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Typography
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "20px",
              }}
            >
              Total Profit: ${getFormattedDecimal(totalProfit).toLocaleString()}
            </Typography>
            <Typography
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "10px",
              }}
            >
              Total Order Count: {numberOfOrders.toLocaleString()}
            </Typography>
          </div>
        </DashboardCardChartHybridLarge>
      )}
    </div>
  );
};

export default Analytics;
