import { Typography, useTheme } from "@mui/material";
import React from "react";
import DashboardCardSmall from "../../../../components/DashboardCardSmall";
import { getFormattedDecimal } from "../../../../utils";
import DashboardCardExpandableContent from "./DashboardCardExpandableContent";

const ProductCostsCard = ({
  isLoading,
  totalItemsCost,
  totalBuyPrice,
  totalBuyTax,
}) => {
  const theme = useTheme();

  return (
    <DashboardCardSmall
      title="Product Costs"
      isLoading={isLoading}
      isExpandable={true}
      expandableContent={
        <DashboardCardExpandableContent
          {...{
            title: "Monthly Product Costs",
            yAxisNames: ["Product Cost"],
            yAxisKeys: ["itemCost"],
          }}
        />
      }
    >
      <Typography variant="h4" style={{ marginBottom: "20px" }}>
        ${getFormattedDecimal(totalItemsCost).toLocaleString()}
      </Typography>
      <Typography>
        ${getFormattedDecimal(totalBuyPrice).toLocaleString()}
        <span style={{ color: theme.palette.text.secondary }}>
          {" "}
          Total Buy Price
        </span>
      </Typography>
      <Typography>
        ${getFormattedDecimal(totalBuyTax).toLocaleString()}
        <span style={{ color: theme.palette.text.secondary }}>
          {" "}
          Total Buy Tax
        </span>
      </Typography>
    </DashboardCardSmall>
  );
};

export default ProductCostsCard;
