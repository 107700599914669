import { CircularProgress, Typography } from "@mui/material";
import { limit, orderBy, where } from "firebase/firestore";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import BarChart from "../../../../components/BarChart";
import MonthNavigator from "../../../../components/MonthNavigator";
import UserContext from "../../../../context/UserContext";
import FirestoreService from "../../../../services/Firestore/index";
import { EbayOrder } from "../../../../types";
import {
  buildDailyChartData,
  filterChartDataByDateRange,
  formatChartData,
  getFormattedDecimal,
} from "../../../../utils";
import buildOrdersArr from "../../../../utils/buildOrdersArr";

const DashboardCardExpandableContent = ({
  yAxisKeys,
  title = "",
  yAxisNames = [""],
  headerIcon = "$",
}: {
  yAxisKeys: string[];
  title?: string;
  yAxisNames?: string[];
  headerIcon?: string;
}) => {
  const [isDataLoading, setIsDataLoading] = useState<boolean>(true);
  const [startDate, setStartDate] = useState<moment.Moment>(
    moment().startOf("month")
  );
  const [endDate, setEndDate] = useState<moment.Moment>(moment());
  const [earliestDate, setEarliestDate] = useState<moment.Moment>(moment());
  const [chartData, setChartData] = useState<Record<string, number>[]>([]);
  const [headerTotal, setHeaderTotal] = useState(0);

  const userObject = useContext(UserContext);

  useEffect(() => {
    if (userObject) {
      const loadEbayOrders = async () => {
        setIsDataLoading(true);

        const dateRangeQueryOptions = [
          where("creationDate", "<=", moment(endDate).tz("Etc/GMT").format()),
          where("creationDate", ">=", moment(startDate).tz("Etc/GMT").format()),
        ];

        const ebayOrdersDocs =
          await FirestoreService.EbayService().getEbayOrders(
            userObject.uid,
            dateRangeQueryOptions
          );
        const newEbayOrders: Record<string, EbayOrder> = {};

        for (const ebayOrder of ebayOrdersDocs) {
          newEbayOrders[ebayOrder.id] = ebayOrder.data() as EbayOrder;
        }

        const sortedOrders = buildOrdersArr(newEbayOrders, userObject);
        const chartDataInitialValues: Record<string, number> = {};
        yAxisKeys.forEach((field) => (chartDataInitialValues[field] = 0));
        setChartData(
          formatChartData(
            filterChartDataByDateRange({
              chartData: buildDailyChartData(
                sortedOrders,
                chartDataInitialValues
              ),
              startDate,
              endDate,
            })
          )
        );
        setIsDataLoading(false);
      };

      loadEbayOrders();
    }
  }, [startDate, endDate, userObject]);

  useEffect(() => {
    if (chartData) {
      let headerTotal = 0;
      chartData?.forEach((day) => {
        yAxisKeys.forEach((field) => {
          headerTotal += day?.[field];
        });
      });
      setHeaderTotal(headerTotal);
    }
  }, [chartData]);

  useEffect(() => {
    if (userObject) {
      const loadOrdersDateRange = async () => {
        const firstEbayOrderArr =
          await FirestoreService.EbayService().getEbayOrders(userObject.uid, [
            orderBy("creationDate"),
            limit(1),
          ]);

        const firstEbayOrder = firstEbayOrderArr[0].data();

        setEarliestDate(moment(firstEbayOrder.creationDate));
      };

      loadOrdersDateRange();
    }
  }, []);

  return (
    <div style={{ width: "50vw", height: "40vh" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "start",
        }}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Typography variant="h6" style={{ fontWeight: 600 }}>
            {title}
          </Typography>
          <Typography variant="h4" style={{}}>
            {headerIcon}
            {getFormattedDecimal(headerTotal).toLocaleString()}
          </Typography>
        </div>
        <MonthNavigator
          {...{
            startDate,
            setStartDate,
            endDate,
            setEndDate,
            earliestDate,
            latestDate: moment(),
          }}
        />
      </div>
      {isDataLoading ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "calc(100% - 74px)",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <BarChart
          chartData={chartData}
          yAxisKeys={[...yAxisKeys]}
          yAxisNames={[...yAxisNames]}
          xAxisKeys={["date"]}
          showXAxis={true}
          margin={{
            top: 16,
            right: -25,
            bottom: 70,
          }}
        />
      )}
    </div>
  );
};

export default DashboardCardExpandableContent;
