import * as React from "react";
import { useTheme } from "@mui/material/styles";
import {
  LineChart,
  Line,
  XAxis,
  ResponsiveContainer,
  Tooltip,
  Legend,
} from "recharts";

export default function Chart({
  chartData,
  xAxisKeys = [],
  yAxisKeys = [],
  yAxisNames = [],
  margin = {
    top: 16,
    right: 16,
    bottom: 10,
  },
  hideXAxis = true,
  renderTooltip,
  showLegend = false,
  showTooltip = true,
}) {
  const theme = useTheme();

  const LineColors = [theme.palette.primary.main, theme.palette.secondary.main];

  return (
    <ResponsiveContainer>
      <LineChart
        data={chartData}
        margin={margin}
        key={Math.random()} //gets the chart to re-animate when data changes
      >
        {showLegend && <Legend />}
        {xAxisKeys.map((key) => {
          return (
            <XAxis
              dataKey={key}
              stroke={theme.palette.text.secondary}
              style={theme.typography.body2}
              hide={hideXAxis}
              rotate={0}
            />
          );
        })}

        {showTooltip && (
          <Tooltip
            contentStyle={{
              backgroundColor:
                theme.palette.mode === "dark"
                  ? "rgb(0, 0, 0, 0.9)"
                  : "rgb(250, 250, 250, 0.9)",
              border: "0px",
              borderRadius: "10px",
            }}
            {...(renderTooltip
              ? { content: (data) => renderTooltip(data) }
              : {})}
          />
        )}

        {yAxisKeys.map((key, index) => (
          <>
            <Line
              yAxisId={key}
              isAnimationActive={true}
              type="monotone"
              dataKey={key}
              name={yAxisNames[index]}
              stroke={LineColors[index]}
              dot={false}
              activeDot={true}
              strokeWidth={3}
            />
          </>
        ))}
      </LineChart>
    </ResponsiveContainer>
  );
}
