import React, { useContext } from "react";
import { Modal, Fade, Box, Typography, useTheme } from "@mui/material";
import WindowContext from "../context/WindowContext";
import { SMALL_SCREEN_WIDTH } from "../constants";

type CustomModalProps = {
  isOpen: boolean;
  onClose: () => void;
  children: JSX.Element | string;
  maxWidth?: string;
  title?: JSX.Element | string;
  style?: React.CSSProperties;
};

const CustomModal: React.FC<CustomModalProps> = ({
  isOpen,
  onClose,
  children,
  maxWidth,
  title,
  style = {},
}) => {
  const { width: screenWidth } = useContext(WindowContext);
  const theme = useTheme();

  return (
    <Modal open={isOpen} onClose={onClose} disableAutoFocus={true}>
      <Fade in={isOpen}>
        <Box
          sx={{
            width: screenWidth < SMALL_SCREEN_WIDTH ? "95%" : "auto",
            height: screenWidth < SMALL_SCREEN_WIDTH ? "95%" : "auto",
            overflow: "overlay",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: theme.palette.background.paper,
            borderRadius: "20px",
            boxShadow: 24,
            p: 4,
            ...(maxWidth ? { maxWidth } : {}),
            maxHeight: "100%",
            ...style,
          }}
        >
          {title && (
            <Typography variant="h5" fontWeight={600} marginBottom="20px">
              {title}
            </Typography>
          )}
          {children}
        </Box>
      </Fade>
    </Modal>
  );
};

export default CustomModal;
