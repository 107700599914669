import { LinearProgress } from "@mui/material";
import MUIDataTable, {
  MUIDataTableBody,
  MUIDataTableColumn,
  MUIDataTableHead,
  MUIDataTableOptions,
  TableHead,
} from "mui-datatables";
import React from "react";
import LoadingTableBody from "./LoadingTableBody";

export type TableProps = {
  rows: Array<object | number[] | string[]>;
  rowCount?: number;
  columns: MUIDataTableColumn[];
  title?: string | React.ReactNode;
  options?: MUIDataTableOptions;
  isLoading?: boolean;
  isLinearProgressActive?: boolean;
  style?: React.CSSProperties;
  components?: any;
};

const DataTable = ({
  rows,
  columns,
  isLoading = false,
  isLinearProgressActive = false,
  title,
  options,
  style,
  components = {},
}: TableProps) => {
  return (
    <div style={{ margin: "auto", width: "100%", ...style }}>
      <MUIDataTable
        title={title}
        data={rows}
        columns={columns}
        options={{
          setTableProps: () => ({
            size: "medium",
          }),
          elevation: 1,
          draggableColumns: { enabled: true },
          filterType: "textField",
          selectableRowsHideCheckboxes: true,
          rowsPerPage: 15,
          rowsPerPageOptions: [15],
          // fixedHeader: true,
          pagination: true,
          responsive: "vertical",
          tableBodyHeight: "100%",
          storageKey: `dropstats-${title}-table`,
          customToolbar: () => {
            return (
              isLinearProgressActive && (
                <div
                  style={{
                    width: "100%",
                    position: "absolute",
                    bottom: "0",
                    left: "0",
                  }}
                >
                  <LinearProgress />
                </div>
              )
            );
          },
          ...options,
        }}
        components={{
          TableBody: (props: MUIDataTableBody) => (
            <LoadingTableBody {...{ isLoading, ...props }} />
          ),
          TableHead: (props: MUIDataTableHead) =>
            isLoading ? <div /> : <TableHead {...{ ...props }} />,
          ...components,
        }}
      />
    </div>
  );
};

export default DataTable;
