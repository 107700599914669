import { Button, MenuItem, TextField } from "@mui/material";
import React, { useState, useEffect, useContext, FormEvent } from "react";
import CustomModal from "../../components/Modal";
import { OrdersTableRow, OrderStatuses, ValueOf } from "../../types";
import FirestoreService from "../../services/Firestore/index";
import UserContext from "../../context/UserContext";
import addSuccessNotif from "../../utils/notifications/addSuccessNotif";

type StatusModalProps = {
  isOpen: boolean;
  onClose: () => void;
  defaultModalState: OrdersTableRow;
  setIsOrderModalOpen: (isOpen: boolean) => void;
};

const selectOrderStatuses = [
  OrderStatuses.READY,
  OrderStatuses.ORDERED,
  OrderStatuses.SHIPPED,
  OrderStatuses.DELIVERED,
  OrderStatuses.REFUNDED,
  OrderStatuses.CANCELED,
];

const StatusModal = ({
  isOpen,
  onClose,
  defaultModalState,
  setIsOrderModalOpen,
}: StatusModalProps) => {
  const [formState, setFormState] = useState<OrdersTableRow>(defaultModalState);
  const userObject = useContext(UserContext);

  useEffect(() => {
    setFormState(defaultModalState);
  }, [defaultModalState]);

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    await FirestoreService.EbayService().updateEbayOrder(
      userObject.uid,
      formState.legacySellOrderId,
      {
        ["dropstatsInfo.status"]: formState.status,
      }
    );

    addSuccessNotif("Order Status Updated");
    onClose();

    if (formState.status === OrderStatuses.ORDERED) {
      setIsOrderModalOpen(true);
    }
  };

  return (
    <CustomModal {...{ isOpen, onClose, title: "Update Order Status" }}>
      <form style={{ height: "100%" }} onSubmit={onSubmit}>
        <div style={{ width: "300px" }}>
          <TextField
            label="Order Status"
            value={formState.status}
            style={{ width: "100%" }}
            select
            onChange={(e) =>
              setFormState((prevState) => ({
                ...prevState,
                status: e.target.value as ValueOf<typeof OrderStatuses>,
              }))
            }
          >
            {selectOrderStatuses.map((status) => (
              <MenuItem value={status}>{status}</MenuItem>
            ))}
          </TextField>
        </div>
        <div
          style={{
            display: "flex",
            columnGap: "10px",
            marginTop: "20px",
            justifyContent: "end",
          }}
        >
          <Button
            onClick={() => {
              onClose();
            }}
          >
            Cancel
          </Button>
          <Button variant={"contained"} type="submit">
            Save
          </Button>
        </div>
      </form>
    </CustomModal>
  );
};

export default StatusModal;
