import AssignmentReturnIcon from "@mui/icons-material/AssignmentReturn";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import PaidIcon from "@mui/icons-material/Paid";
import PendingIcon from "@mui/icons-material/Pending";
import ReceiptIcon from "@mui/icons-material/Receipt";
import ReplayIcon from "@mui/icons-material/Replay";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import WarningIcon from "@mui/icons-material/Warning";
import { Button, Tooltip, useTheme } from "@mui/material";
import moment from "moment";
import React, { useContext, useState } from "react";
import DataTable from "../../components/DataTable";
import DateRangePickers from "../../components/DateRangePickers";
import MainPageLayout from "../../components/MainPageLayout";
import TextBlock from "../../components/TextBlock";
import { SECONDARY_TEXT_COLOR, SMALL_SCREEN_WIDTH } from "../../constants";
import WindowContext from "../../context/WindowContext";
import { OrdersTableRow, OrderStatuses, ValueOf } from "../../types";
import { getFormattedDecimal } from "../../utils";
import OrderModal from "./OrderModal";
import StatusModal from "./StatusModal";
import { Edit } from "@mui/icons-material";

export type OrdersContentProps = {
  areRowsLoading: boolean;
  rowCount: number;
  tableRows: OrdersTableRow[];
  currentPage: number;
  startDate: moment.Moment;
  endDate: moment.Moment;
  setStartDate: (date: moment.Moment) => void;
  setEndDate: (date: moment.Moment) => void;
  ordersDataStartDate: moment.Moment;
  isAutodsDataSyncing: boolean;
  isDataLoading: boolean;
  onChangePage: (newPage: number) => void;
  onColumnSortChange: (
    changedColumn: string,
    direction: "desc" | "asc"
  ) => void;
  sortBy: string;
  sortDirection: "desc" | "asc";
  setSearchString: (searchString: string | null) => void;
};

const statusIconMap = {
  [OrderStatuses.CANCEL]: (
    <CancelRoundedIcon fontSize="small" style={{ color: "red" }} />
  ),
  [OrderStatuses.CANCELED]: (
    <CancelRoundedIcon fontSize="small" style={{ color: "red" }} />
  ),
  [OrderStatuses.SHIPPED]: (
    <LocalShippingIcon fontSize="small" style={{ color: "#3399FF" }} />
  ),
  [OrderStatuses.AWAITING_PAYMENT]: (
    <PaidIcon fontSize="small" style={{ color: "gold" }} />
  ),
  [OrderStatuses.RETURN_COMPLETED]: (
    <AssignmentReturnIcon fontSize="small" style={{ color: "lightgreen" }} />
  ),
  [OrderStatuses.RETURN_INPROGRESS]: (
    <AssignmentReturnIcon fontSize="small" style={{ color: "gold" }} />
  ),
  [OrderStatuses.RETURN_PENDING]: (
    <AssignmentReturnIcon fontSize="small" style={{ color: "gold" }} />
  ),
  [OrderStatuses.FAILED]: (
    <WarningIcon fontSize="small" style={{ color: "red" }} />
  ),
  [OrderStatuses.DELIVERED]: (
    <CheckCircleRoundedIcon fontSize="small" style={{ color: "lightgreen" }} />
  ),
  [OrderStatuses.ORDERED]: (
    <ReceiptIcon fontSize="small" style={{ color: "lightblue" }} />
  ),
  [OrderStatuses.STAND_BY]: (
    <PendingIcon fontSize="small" style={{ color: "white" }} />
  ),
  [OrderStatuses.REFUNDED]: (
    <ReplayIcon fontSize="small" style={{ color: "lightgreen" }} />
  ),
  [OrderStatuses.READY]: (
    <TaskAltIcon fontSize="small" style={{ color: "black" }} />
  ),
};

export const defaultModalStateObj = {
  id: 1,
  fullName: "",
  countryCode: "",
  buyerUsername: "",
  addressLine1: "",
  addressLine2: "",
  stateOrProvince: "",
  city: "",
  postalCode: "",
  phoneNumber: "",
  fullfillmentStatus: "",
  profit: 0,
  creationDate: moment(),
  sellItemId: "",
  buySiteOrderId: "",
  buyerAccount: "",
  buyItemId: "",
  buyPrice: 0,
  sellPrice: 0,
  shippingPrice: 0,
  profitMargin: 0,
  status: "PENDING" as ValueOf<typeof OrderStatuses>,
  trackingNumber: "",
  easySyncOrderId: "",
  buyItemUrl: "",
  productPictureUrl: "",
  productTitle: "",
  totalItemCost: 0,
  itemLocation: { city: "", state: "", postalCode: "", countryCode: "" },
  buyTax: 0,
  autodsCreditFee: 0,
  autodsWalletFee: 0,
  finalValueFee: 0,
  totalFees: 0,
  isAutoDSAutoOrder: false,
  sellOrderId: "",
  legacySellOrderId: "",
  miscFees: 0,
  notes: "",
  ebayAdFee: 0,
};

const OrdersContent = ({
  areRowsLoading,
  rowCount,
  tableRows,
  currentPage,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  ordersDataStartDate,
  isAutodsDataSyncing,
  isDataLoading,
  onChangePage,
  onColumnSortChange,
  sortBy,
  sortDirection,
  setSearchString,
}: OrdersContentProps) => {
  const theme = useTheme();
  const { width: screenWidth } = useContext(WindowContext);
  const [isOrderModalOpen, setIsOrderModalOpen] = useState<boolean>(false);
  const [isStatusModalOpen, setIsStatusModalOpen] = useState<boolean>(false);
  const [defaultModalState, setDefaultModalState] =
    useState<OrdersTableRow>(defaultModalStateObj);

  return (
    <MainPageLayout {...{ title: `Orders (${rowCount})` }}>
      <>
        <DataTable
          {...{
            title: (
              <DateRangePickers
                {...{
                  isDataLoading,
                  startDate,
                  endDate,
                  setStartDate,
                  setEndDate,
                  startDateLimit: ordersDataStartDate,
                  endDateLimit: moment().endOf("day"),
                }}
              />
            ),
            options: {
              setTableProps: () => ({ size: "small" }),
              sortOrder: { name: sortBy, direction: sortDirection },
              onChangePage,
              count: rowCount,
              serverSide: true,
              page: currentPage,
              onColumnSortChange,
              onSearchChange: (searchText) => {
                setSearchString(searchText);
              },
              searchPlaceholder: "Ebay Order ID",
              filter: false,
            },
            columns: [
              {
                name: "productTitle",
                label: "Item",
                options: {
                  setCellHeaderProps: () => ({ style: { width: "100px" } }),
                  customBodyRender: (value, tableMeta) => {
                    return (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          columnGap: "10px",
                          minWidth:
                            screenWidth > SMALL_SCREEN_WIDTH
                              ? "300px"
                              : "150px",
                        }}
                      >
                        <Tooltip title="Edit" placement="top" arrow={true}>
                          <div
                            style={{ padding: "0px", position: "relative" }}
                            onClick={() => {
                              setDefaultModalState(
                                tableRows[tableMeta.rowIndex]
                              );
                              setIsOrderModalOpen(true);
                            }}
                          >
                            <Edit
                              className="editIconImage"
                              style={{
                                minWidth: "50px",
                                minHeight: "50px",
                                borderRadius: "25px",
                                objectFit: "cover",
                                padding: '10px'
                              }} />
                          </div>
                        </Tooltip>
                        <Tooltip
                          title={`https://www.ebay.com/sh/ord/details?orderid=${tableMeta.rowData[19]}`}
                          placement="top"
                          style={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          <a
                            style={{
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              height: "100%",
                              display: "flex",
                              alignItems: "center",
                              columnGap: "10px",
                              fontWeight: 600,
                              maxWidth: "250px",
                            }}
                            href={`https://www.ebay.com/sh/ord/details?orderid=${tableMeta.rowData[19]}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <span>{value}</span>
                          </a>
                        </Tooltip>
                      </div>
                    );
                  },
                  sort: false,
                },
              },
              {
                name: "creationDate",
                label: "Date",
                options: {
                  customBodyRender: (value) => {
                    if (value == null) {
                      return "";
                    }

                    return value.format("MM/DD/YY");
                  },
                },
              },
              {
                name: "status",
                label: "Status",
                options: {
                  customBodyRender: (
                    value: ValueOf<typeof OrderStatuses>,
                    tableMeta
                  ) => {
                    return (
                      <Button
                        variant={
                          value === OrderStatuses.READY
                            ? "contained"
                            : "outlined"
                        }
                        style={{ minWidth: "130px", justifyContent: "start" }}
                        onClick={() => {
                          setDefaultModalState(tableRows[tableMeta.rowIndex]);
                          setIsStatusModalOpen(true);
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            columnGap: "10px",
                          }}
                        >
                          {statusIconMap[OrderStatuses[value]]}
                          <span style={{ fontSize: "12px", fontWeight: 600 }}>
                            {OrderStatuses[value] ?? value}
                          </span>
                        </div>
                      </Button>
                    );
                  },
                  sort: false,
                },
              },
              {
                name: "profit",
                label: "Profit",
                options: {
                  customBodyRender: (value) => {
                    if (value == null) {
                      return "N/A";
                    }

                    return (
                      <span
                        style={{
                          color:
                            value > 0
                              ? theme.palette.success.main
                              : theme.palette.error.main,
                        }}
                      >
                        ${value}
                      </span>
                    );
                  },
                  sort: false,
                },
              },
              {
                name: "buyPrice",
                label: "Buy Price",
                options: {
                  customBodyRender: (value, tableMeta) => {
                    if (value == null) {
                      return "";
                    }

                    return (
                      <div style={{ border: `1px ${SECONDARY_TEXT_COLOR} solid`, borderRadius: '5px', padding: '5px 10px', cursor: 'pointer' }}
                        onClick={() => {
                          setDefaultModalState(
                            tableRows[tableMeta.rowIndex]
                          );
                          setIsOrderModalOpen(true);
                        }}>
                        ${value}
                      </div>
                    )
                  },
                  sort: false,
                },
              },
              {
                name: "buyTax",
                label: "Buy Tax",
                options: {
                  customBodyRender: (value) => {
                    if (value == null) {
                      return "";
                    }

                    return `$${value}`;
                  },
                  sort: false,
                },
              },
              {
                name: "shippingPrice",
                label: "Shipping Cost",
                options: {
                  customBodyRender: (value) => {
                    if (value == null) {
                      return "";
                    }

                    return `$${value}`;
                  },
                  sort: false,
                },
              },
              {
                name: "totalItemCost",
                label: "Total Item Cost",
                options: {
                  display: false,
                  customBodyRender: (value) => {
                    if (value == null) {
                      return "";
                    }

                    return `$${value}`;
                  },
                  sort: false,
                },
              },
              {
                name: "sellPrice",
                label: "Sell Price",
                options: {
                  customBodyRender: (value) => {
                    if (value == null) {
                      return "";
                    }

                    return `$${value}`;
                  },
                  sort: false,
                },
              },
              {
                name: "ebayAdFee",
                label: "eBay Ad Fee",
                options: {
                  display: true,
                  customBodyRender: (value) => {
                    if (value == null) {
                      return "";
                    }

                    return `$${getFormattedDecimal(value)}`;
                  },
                  sort: false,
                },
              },
              {
                name: "finalValueFee",
                label: "eBay Final Value",
                options: {
                  display: true,
                  customBodyRender: (value) => {
                    if (value == null) {
                      return "";
                    }

                    return `$${value}`;
                  },
                  sort: false,
                },
              },
              {
                name: "miscFees",
                label: "Misc Fees",
                options: {
                  display: false,
                  customBodyRender: (value) => {
                    if (value == null) {
                      return "";
                    }

                    return `$${value}`;
                  },
                  sort: false,
                },
              },
              {
                name: "totalFees",
                label: "Total Fees",
                options: {
                  customBodyRender: (value) => {
                    if (value == null) {
                      return "";
                    }

                    return `$${value}`;
                  },
                  sort: false,
                },
              },
              { name: "fullName", label: "Name", options: { sort: false } },
              {
                name: "sellItemId",
                label: "Item ID",
                options: {
                  customBodyRender: (value, tableMeta) => {
                    return (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "start",
                        }}
                      >
                        {/* <div
                          style={{
                            display: "flex",
                            columnGap: "5px",
                            alignItems: "center",
                            textOverflow: "ellipsis",
                            maxWidth: "250px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                          }}
                        >
                          <TextBlock label={"BUY"} />
                          <a
                            href={tableMeta.rowData[20]}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-transition"
                            style={{
                              display: "flex",
                              columnGap: "5px",
                              alignItems: "center",
                            }}
                          >
                            {tableMeta.rowData[21] ? (
                              <>
                                <img
                                  src="amazon.svg"
                                  style={{ width: "25px" }}
                                  draggable={false}
                                />
                                <span>{tableMeta.rowData[21]}</span>
                              </>
                            ) : (
                              <>
                                <InsertLinkIcon />
                                {tableMeta.rowData[20]}
                              </>
                            )}
                          </a>
                        </div> */}
                        <div
                          style={{
                            display: "flex",
                            columnGap: "5px",
                            alignItems: "center",
                          }}
                        >
                          {/* <TextBlock label={"SELL"} /> */}
                          <a
                            href={`https://ebay.com/itm/${tableMeta.rowData[5]}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-transition"
                            style={{
                              display: "flex",
                              columnGap: "5px",
                              alignItems: "center",
                            }}
                          >
                            <img
                              src="ebay.svg"
                              style={{ width: "25px" }}
                              draggable={false}
                            />
                            <span>{getFormattedDecimal(value)}</span>
                          </a>
                        </div>
                      </div>
                    );
                  },
                  sort: false,
                },
              },
              {
                name: "autodsCreditFee",
                label: "AutoDS Credit",
                options: {
                  display: false,
                  customBodyRender: (value) => {
                    if (value == null) {
                      return "";
                    }

                    return `$${getFormattedDecimal(value)}`;
                  },
                  sort: false,
                },
              },
              {
                name: "autodsWalletFee",
                label: "AutoDS Wallet Fee",
                options: {
                  display: false,
                  customBodyRender: (value) => {
                    if (value == null) {
                      return "";
                    }

                    return `$${value}`;
                  },
                  sort: false,
                },
              },
              {
                name: "profitMargin",
                label: "Profit Margin",
                options: {
                  display: false,
                  customBodyRender: (value) => {
                    if (value == null) {
                      return "";
                    }

                    return `${value}%`;
                  },
                  sort: false,
                },
              },
              {
                name: "isAutoDSAutoOrder",
                label: "AutoDS Auto Order",
                options: {
                  display: false,
                  customBodyRender: (value) => {
                    if (value == null) {
                      return "";
                    }

                    return value ? "TRUE" : "FALSE";
                  },
                  sort: false,
                },
              },
              {
                name: "productPictureUrl",
                options: { display: "excluded", filter: false },
              },
              {
                name: "sellOrderId",
                label: "Ebay Order ID",
                options: { display: false, filter: false },
              },
              {
                name: "buyItemUrl",
                label: "Buy Item URL",
                options: { display: "excluded", filter: false },
              },
              {
                name: "buyItemId",
                label: "Buy Item ID",
                options: { display: "excluded", filter: false },
              },
            ],
            rows: tableRows,
            isLoading: areRowsLoading,
            isLinearProgressActive: isAutodsDataSyncing,
          }}
        />
        <OrderModal
          {...{
            isOpen: isOrderModalOpen,
            onClose: () => setIsOrderModalOpen(false),
            defaultModalState,
          }}
        />
        <StatusModal
          {...{
            isOpen: isStatusModalOpen,
            onClose: () => setIsStatusModalOpen(false),
            defaultModalState,
            setIsOrderModalOpen,
          }}
        />
      </>
    </MainPageLayout>
  );
};

export default OrdersContent;
