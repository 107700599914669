import React from "react";
import { DASHBOARD_GAP } from "../../../../constants";
import AutodsFeesCard from "./AutodsFeesCard";
import EbayFeesCard from "./EbayFeesCard";
import NetProfitCard from "./NetProfitCard";
import OrdersCard from "./OrdersCard";
import ProductCostsCard from "./ProductCostsCard";
import TotalSalesCard from "./TotalSalesCard";

const Overview = ({ analytics, isDataLoading }) => {
  const {
    totalProfit,
    totalSales,
    totalItemsCost,
    totalBuyPrice,
    totalBuyTax,
    totalAutoDsCreditFees,
    totalAutoDsWalletFees,
    totalFinalValueFees,
    totalAdFees,
    totalOrderCount,
    successfulOrderCount,
  } = analytics;

  return (
    <div
      style={{
        display: "flex",
        gap: `${DASHBOARD_GAP}px`,
        flexWrap: "wrap",
        justifyContent: "center",
      }}
    >
      <NetProfitCard
        {...{
          isLoading: isDataLoading,
          totalProfit,
        }}
      />
      <TotalSalesCard
        {...{
          isLoading: isDataLoading,
          totalSales,
        }}
      />
      <ProductCostsCard
        {...{
          isLoading: isDataLoading,
          totalItemsCost,
          totalBuyPrice,
          totalBuyTax,
        }}
      />
      <AutodsFeesCard
        {...{
          isLoading: isDataLoading,
          totalAutoDsCreditFees,
          totalAutoDsWalletFees,
        }}
      />
      <EbayFeesCard
        {...{
          isLoading: isDataLoading,
          totalFinalValueFees,
          totalAdFees,
        }}
      />
      <OrdersCard
        {...{
          isLoading: isDataLoading,
          totalOrderCount,
          successfulOrderCount,
        }}
      />
    </div>
  );
};

export default Overview;
