import {
  CircularProgress,
  TableBody as MuiTableBody,
  Typography,
} from "@mui/material";
import {
  MUIDataTableBody,
  TableBodyCell,
  TableBodyRow,
  TableBody,
} from "mui-datatables";
import React from "react";

interface Props extends MUIDataTableBody {
  isLoading: boolean;
}

const LoadingTableBody = ({
  isLoading,
  options,
  columns,
  ...others
}: Props) => {
  return isLoading ? (
    <MuiTableBody>
      <TableBodyRow options={options} />
      <TableBodyCell options={options} colIndex={0} rowIndex={0}>
        <Typography
          variant="body1"
          component={"div"}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
          }}
        >
          <CircularProgress />
        </Typography>
      </TableBodyCell>
    </MuiTableBody>
  ) : (
    <TableBody options={options} columns={columns} {...others} />
  );
};

export default LoadingTableBody;
