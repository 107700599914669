import React, { useEffect, useContext } from "react";
import SetupContent from "./Content";
import CloudFunctionService from "../../services/CloudFunctions";
import UserContext from "../../context/UserContext";
import addInfoNotif from "../../utils/notifications/addInfoNotif";
import addSuccessNotif from "../../utils/notifications/addSuccessNotif";
import { useSearchParams } from "react-router-dom";
export type SetupProps = { handleSyncClick: () => void };

const Setup = ({ handleSyncClick }: SetupProps) => {
  const userObject = useContext(UserContext);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get("code") && userObject?.uid.length) {
      const postEbayAccessToken = async () => {
        addInfoNotif("Syncing eBay Orders, Please Wait...", undefined, Infinity);
        await CloudFunctionService.EbayService().loadUserEbayRefreshToken(
          userObject.uid,
          searchParams.get("code") ?? ""
        );
        await CloudFunctionService.EbayService().updateEbayOrders(
          userObject?.uid
        );
        addSuccessNotif("Ebay Orders Synced Successfully!");
        window.location.href = "/setup";
      };

      postEbayAccessToken();
    }
  }, [userObject?.uid]);

  return <SetupContent {...{ handleSyncClick, userObject }} />;
};

export default Setup;
