import React, { useContext } from "react";

import {
  Card,
  CardHeader,
  CardContent,
  Paper,
  CircularProgress,
  useTheme,
} from "@mui/material";
import LineChart from "./LineChart";
import BarChart from "./BarChart";
import WindowContext from "../context/WindowContext";
import {
  DASHBOARD_GAP,
  MEDIUM_SCREEN_WIDTH,
  SMALL_SCREEN_WIDTH,
} from "../constants";

export const ChartTypes = { BAR: "bar", LINE: "line" };

const DashboardCardChartHybridSmall = ({
  children,
  title,
  chartProps = { chartData: [], xAxisKeys: [] },
  chartType = ChartTypes.LINE,
  isLoading = false,
}) => {
  const { width: screenWidth } = useContext(WindowContext);
  const theme = useTheme();

  return (
    <Paper
      style={{
        borderRadius: "20px",
        height: "320px",
        width:
          screenWidth > SMALL_SCREEN_WIDTH
            ? `calc((100% - ${DASHBOARD_GAP}px)/2)`
            : "100%",
        minWidth: screenWidth > SMALL_SCREEN_WIDTH ? "595px" : "0px",
        ...(screenWidth < MEDIUM_SCREEN_WIDTH ? { flexGrow: 1 } : {}),
        boxShadow: "6px 6px 10px rgba(0, 0, 0, 0.06)",
      }}
      elevation={0}
    >
      <Card
        style={{
          minWidth: "inherit",
          height: "inherit",
          borderRadius: "20px",
          padding: "15px",
          display: "flex",
          ...(isLoading
            ? {
                alignItems: "center",
                justifyContent: "center",
              }
            : {}),
          boxShadow: "none",
        }}
      >
        {isLoading ? (
          <CircularProgress />
        ) : (
          <>
            <div
              style={{
                width: screenWidth > SMALL_SCREEN_WIDTH ? "50%" : "100%",
              }}
            >
              <CardHeader
                {...{
                  title: (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {title}
                    </div>
                  ),
                  titleTypographyProps: {
                    variant: "h6",
                    fontWeight: 500,
                    fontSize: "16px",
                    color: theme.palette.text.secondary,
                  },
                  style: { padding: "0px 15px" },
                }}
              />
              <CardContent> {children} </CardContent>
            </div>
            {screenWidth > SMALL_SCREEN_WIDTH && (
              <div style={{ width: "50%" }}>
                {chartType === ChartTypes.LINE && <LineChart {...chartProps} />}
                {chartType === ChartTypes.BAR && <BarChart {...chartProps} />}
              </div>
            )}
          </>
        )}
      </Card>
    </Paper>
  );
};

export default DashboardCardChartHybridSmall;
