import {
  Backdrop,
  CircularProgress,
  Container,
  Paper,
  Typography,
} from "@mui/material";
import React, { useContext, useState } from "react";
import WindowContext from "../../context/WindowContext";
import { SMALL_SCREEN_WIDTH } from "../../constants";
import { useInterval } from "usehooks-ts";

type LoginContentProps = {
  isSigningIn: boolean;
};

const LoginContent = ({ isSigningIn }: LoginContentProps) => {
  const { width: screenWidth } = useContext(WindowContext);

  const [isGoogleLoading, setIsGoogleLoading] = useState<boolean>(true);
  const [google, setGoogle] = useState<any>();
  const [isGoogleButtonRendered, setIsGoogleButtonRendered] =
    useState<boolean>(false);

  //wait until windw.google loads to then call initalize and renderButton
  //https://www.dolthub.com/blog/2022-05-04-google-signin-migration/#3-useeffect-doesnt-listen-to-when-the-window-object-changes
  useInterval(
    () => {
      if (typeof window !== "undefined" && window.google) {
        setGoogle(window.google);
        setIsGoogleLoading(false);
      }
    },
    isGoogleLoading ? 100 : null
  );

  //wait until the loginButton div has appeared in the doc then call google.accounts.id.renderButton()
  //if renderButton() gets called before the div has appeared, google button wont render
  //custom solution, couldn't find anything better online
  useInterval(
    () => {
      if (
        google &&
        !isGoogleLoading &&
        document.getElementById("loginButton")
      ) {
        google.accounts.id.renderButton(
          document.getElementById("loginButton"),
          {
            shape: "circle",
            longtitle: true,
            // theme: "filled_black",
          }
        );
        setIsGoogleButtonRendered(true);
      }
    },
    !isGoogleButtonRendered ? 100 : null
  );

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isSigningIn}
      >
        <CircularProgress style={{ height: "70px", width: "70px" }} />
        <img
          src="logo512.png"
          width={50}
          style={{
            marginBottom: "20px",
            position: "absolute",
            top: "calc(50% - 25px)",
          }}
        />
      </Backdrop>
      {!isSigningIn && (
        <Container
          style={{
            height: "100vh",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            src="/DropstatsLogoWhite_cloud.png"
            width={200}
            style={{ marginBottom: "20px" }}
          />
          <Paper
            style={{
              marginBottom: "50px",
              minWidth: screenWidth > SMALL_SCREEN_WIDTH ? "400px" : "0px",
              borderRadius: "20px",
              padding: "40px",
            }}
            elevation={10}
          >
            <Typography variant="h4" fontWeight={600}>
              Login
            </Typography>
            <div
              style={{ height: "40px", marginTop: "50px" }}
              id="loginButton"
            />
          </Paper>
        </Container>
      )}
    </>
  );
};

export default LoginContent;
