import {
  collection,
  doc,
  DocumentData,
  Firestore,
  getCountFromServer,
  getDoc,
  getDocs,
  onSnapshot,
  query,
  QueryConstraint,
  QuerySnapshot,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { AutodsOrder } from "../../types";
import addErrorNotif from "../../utils/notifications/addErrorNotif";

const EbayService = (db: Firestore) => ({
  getEbayListings: (
    uid: string,
    queryOptions: QueryConstraint[],
    onNext: (snapshot: QuerySnapshot<DocumentData>) => void,
    onError: (e: any) => void = (e) => {
      addErrorNotif(e);
      throw new Error(e);
    }
  ) =>
    onSnapshot(
      query(collection(db, "users", `${uid}`, "ebayListings"), ...queryOptions),
      onNext,
      onError
    ),
  getEbayListingsRowCount: async (
    uid: string,
    queryOptions: QueryConstraint[] = []
  ) => {
    try {
      const rowCount = await getCountFromServer(
        query(
          collection(db, "users", `${uid}`, "ebayListings"),
          ...queryOptions
        )
      );
      return rowCount.data().count;
    } catch (e: any) {
      addErrorNotif(e);
      throw new Error(e);
    }
  },
  getEbayOrdersSnapshot: (
    uid: string,
    queryOptions: QueryConstraint[],
    onNext: (snapshot: QuerySnapshot<DocumentData>) => void,
    onError: (e: any) => void = (e) => {
      addErrorNotif(e);
      throw new Error(e);
    }
  ) => {
    return onSnapshot(
      query(collection(db, "users", `${uid}`, "ebayOrders"), ...queryOptions),
      onNext,
      onError
    );
  },
  getEbayOrders: async (uid: string, queryOptions: QueryConstraint[]) => {
    try {
      const ebayOrders = await getDocs(
        query(collection(db, "users", `${uid}`, "ebayOrders"), ...queryOptions)
      );

      return ebayOrders.docs;
    } catch (e: any) {
      addErrorNotif(e);
      throw new Error(e);
    }
  },
  updateEbayOrder: async (uid: string, orderId: string, updatedVales: any) => {
    try {
      await updateDoc(
        doc(db, "users", `${uid}`, "ebayOrders", orderId),
        updatedVales
      );
    } catch (e: any) {
      addErrorNotif(e);
      throw new Error(e);
    }
  },
  syncAutodsOrderData: async (uid: string, order: AutodsOrder) => {
    const payload = {
      [`autodsInfo.auto_order`]: order.auto_order,
      [`autodsInfo.buy_item_real_id`]: order.buy_item_real_id || "",
      [`autodsInfo.buy_item_url`]: order.buy_item_url || "",
      [`autodsInfo.buy_price`]: order.buy_price || null,
      [`autodsInfo.buy_shipping_price`]: order.buy_shipping_price || 0,
      [`autodsInfo.buy_site_order_id`]: order.buy_site_order_id || "",
      [`autodsInfo.buy_tax`]: order.buy_tax || 0,
      [`autodsInfo.estimated_arrival`]: order.estimated_arrival || "",
      [`autodsInfo.tracking_updated_on_sale_channel`]:
        order.extra_info.tracking_updated_on_sale_channel || null,
      [`autodsInfo.tracking_updated_time`]:
        order.extra_info.tracking_updated_time || null,
      [`autodsInfo.tracking_url`]: order.tracking_url || null,
      [`autodsInfo.managed_order`]: order.managed_order,
      [`autodsInfo.status`]: order.status || "",
      [`autodsInfo.tracking_number`]: order.tracking_number || "",
      [`autodsInfo.product_picture_url`]: order.product_picture_url || "",
    };  

    try {
      await updateDoc(
        doc(db, `users/${uid}/ebayOrders/${order.sell_site_order_id}`),
        payload
      );
    } catch (e: any) {
      try {
        await setDoc(
          doc(db, `users/${uid}/ebayOrders/${order.sell_site_order_id}`),
          {
            isAutodsOrder: true,
            creationDate: order.created_date,
            autodsInfo: order,
          }
        );
      } catch (e: any) {
        addErrorNotif(e);
        throw new Error(e);
      }
    }
  },
  getEbayOrdersRowCount: async (
    uid: string,
    queryOptions: QueryConstraint[] = []
  ) => {
    try {
      const rowCount = await getCountFromServer(
        query(collection(db, "users", `${uid}`, "ebayOrders"), ...queryOptions)
      );
      return rowCount.data().count;
    } catch (e: any) {
      addErrorNotif(e);
      throw new Error(e);
    }
  },
  getEbayListing: async (uid: string, itemId: string) => {
    try {
      const ebayListingSnapshot = await getDoc(
        doc(db, `users/${uid}/ebayListings/${itemId}`)
      );
      return ebayListingSnapshot.data();
    } catch (e: any) {
      addErrorNotif(e);
      throw new Error(e);
    }
  },
  updateEbayListing: async (
    uid: string,
    itemId: string,
    updatedValues: any
  ) => {
    try {
      await updateDoc(
        doc(db, "users", `${uid}`, "ebayListings", `${itemId}`),
        updatedValues
      );
    } catch (e: any) {
      addErrorNotif(e);
      throw new Error(e);
    }
  },
});

export default EbayService;
