import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React, { ComponentType } from "react";
import { NavLink } from "react-router-dom";

export type NavItemProps = {
  NavIcon: ComponentType;
  title: string;
  route: string;
  disabled?: boolean;
};

const NavItem = ({ NavIcon, title, route, disabled = false }: NavItemProps) => {
  const theme = useTheme();

  return (
    <ListItem
      key={route}
      disablePadding
      sx={{ display: "block" }}
      component={NavLink}
      to={`/${route}`}
      style={{
        color: theme.palette.text.primary,
        pointerEvents: disabled ? "none" : "auto",
      }}
    >
      <ListItemButton
        sx={{
          minHeight: 48,
          borderRadius: "10px",
          margin: "5px 0px",
        }}
        selected={window.location.pathname === `/${route}`}
        dense={true}
        disabled={disabled}
      >
        <ListItemIcon
          sx={{
            minWidth: 0,
            marginRight: "10px",
            color:
              window.location.pathname === `/${route}`
                ? theme.palette.primary.main
                : theme.palette.text.secondary,
          }}
        >
          <NavIcon />
        </ListItemIcon>
        <ListItemText
          primary={title}
          primaryTypographyProps={{
            fontSize: "14px",
            ...(window.location.pathname === `/${route}`
              ? { fontWeight: "700" }
              : { color: theme.palette.text.secondary }),
          }}
        />
      </ListItemButton>
    </ListItem>
  );
};

export default NavItem;
