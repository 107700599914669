import {
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  IconButton,
  Paper,
  useTheme,
} from "@mui/material";
import React from "react";
import Modal from "../components/Modal";

import BarChartIcon from "@mui/icons-material/BarChart";
import { useContext, useState } from "react";
import { MEDIUM_SCREEN_WIDTH, SMALL_SCREEN_WIDTH } from "../constants";
import WindowContext from "../context/WindowContext";

export const ChartTypes = { BAR: "bar", LINE: "line" };

const DashboardCardSmall = ({
  children,
  title,
  isLoading = false,
  isExpandable = false,
  expandableContent = null,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { width: screenWidth } = useContext(WindowContext);
  const theme = useTheme();

  return (
    <>
      <Paper
        style={{
          borderRadius: "20px",
          height: "210px",
          width: "calc((100% - 50px)/3)",
          minWidth: screenWidth < SMALL_SCREEN_WIDTH ? "100%" : "391px",
          ...(screenWidth < MEDIUM_SCREEN_WIDTH ? { flexGrow: 2 } : {}),
          boxShadow: "6px 6px 10px rgba(0, 0, 0, 0.06)",
        }}
        elevation={0}
      >
        <Card
          style={{
            minWidth: "inherit",
            maxWidth: "inherit",
            height: "inherit",
            borderRadius: "20px",
            padding: "15px",
            display: "flex",
            ...(isLoading
              ? {
                  alignItems: "center",
                  justifyContent: "center",
                }
              : {}),
            boxShadow: "none",
          }}
        >
          {isLoading ? (
            <CircularProgress />
          ) : (
            <div
              style={{
                width: "100%",
                overflow: "hidden",
              }}
            >
              <CardHeader
                {...{
                  title: (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {title}
                    </div>
                  ),
                  titleTypographyProps: {
                    variant: "h6",
                    fontWeight: 500,
                    fontSize: "16px",
                    color: theme.palette.text.secondary,
                  },
                  style: { padding: "0px 15px" },
                  ...(isExpandable && screenWidth > SMALL_SCREEN_WIDTH
                    ? {
                        action: (
                          <IconButton
                            style={{
                              marginTop: "5px",
                            }}
                            onClick={() => setIsModalOpen(true)}
                          >
                            <BarChartIcon
                              style={{ color: theme.palette.primary.main }}
                            />
                          </IconButton>
                        ),
                      }
                    : {}),
                }}
              />
              <CardContent> {children} </CardContent>
            </div>
          )}
        </Card>
      </Paper>
      {isExpandable && (
        <Modal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          style={{ overflow: "hidden" }}
        >
          {expandableContent}
        </Modal>
      )}
    </>
  );
};

export default DashboardCardSmall;
