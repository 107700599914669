import React, { useContext } from "react";
import {
  Card,
  CardHeader,
  CardContent,
  Paper,
  CircularProgress,
  IconButton,
  useTheme,
} from "@mui/material";
import Modal from "../components/Modal";

import BarChartIcon from "@mui/icons-material/BarChart";
import { useState } from "react";
import { DASHBOARD_GAP, SMALL_SCREEN_WIDTH } from "../constants";
import WindowContext from "../context/WindowContext";

export const ChartTypes = { BAR: "bar", LINE: "line" };

const DashboardCardExtraSmall = ({
  children,
  title,
  isLoading = false,
  isExpandable = false,
  expandableContent,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { width: screenWidth } = useContext(WindowContext);
  const theme = useTheme();

  return (
    <>
      <Paper
        style={{
          borderRadius: "20px",
          height: "210px",
          width:
            screenWidth > SMALL_SCREEN_WIDTH
              ? `calc((100% - ${DASHBOARD_GAP}px)/2)`
              : "100%",
          minWidth: "288px",
          boxShadow: "6px 6px 10px rgba(0, 0, 0, 0.06)",
        }}
        elevation={0}
      >
        <Card
          style={{
            minWidth: "inherit",
            height: "inherit",
            minHeight: "inherit",
            borderRadius: "20px",
            padding: "15px",
            display: "flex",
            ...(isLoading
              ? {
                  alignItems: "center",
                  justifyContent: "center",
                }
              : {}),
            boxShadow: "none",
          }}
        >
          {isLoading ? (
            <CircularProgress />
          ) : (
            <div style={{ width: "100%" }}>
              <CardHeader
                {...{
                  title: (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {title}
                    </div>
                  ),
                  titleTypographyProps: {
                    variant: "h6",
                    fontWeight: 500,
                    fontSize: "16px",
                    color: theme.palette.text.secondary,
                  },
                  style: { padding: "0px 15px" },
                  ...(isExpandable && screenWidth > SMALL_SCREEN_WIDTH
                    ? {
                        action: (
                          <IconButton
                            style={{
                              marginTop: "5px",
                            }}
                            onClick={() => setIsModalOpen(true)}
                          >
                            <BarChartIcon
                              style={{ color: theme.palette.primary.main }}
                            />
                          </IconButton>
                        ),
                      }
                    : {}),
                }}
              />
              <CardContent> {children} </CardContent>
            </div>
          )}
        </Card>
      </Paper>
      {isExpandable && (
        <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
          {expandableContent}
        </Modal>
      )}
    </>
  );
};

export default DashboardCardExtraSmall;
