import { sortBy } from "lodash";
import moment from "moment";
import { getFormattedDecimal, isSaleValid } from ".";
import {
  AutodsOrder,
  EbayOrder,
  OrderStatuses,
  UserObject,
  ValueOf,
} from "../types";

// add the users dropstatsInfo to this orders builder. takes precedence over autodsInfo?
const buildOrdersArr = (
  ebayOrders: Record<string, EbayOrder>,
  userObject: UserObject
) => {
  const orders = [];
  for (const ebayOrder of Object.values(ebayOrders)) {
    const isAutodsOrder = ebayOrder.isAutodsOrder ?? false;
    let autodsInfo: AutodsOrder | null = ebayOrder.autodsInfo ?? null;
    let buyPrice = autodsInfo?.buy_price ?? 0;
    let buyTax = autodsInfo?.buy_tax ?? 0;
    let buyItemVariant = "";
    let buyItemId = "";
    let buyerUsername = "";
    let fullName = "";
    let sellItemId = "";
    let addressLine1 = "";
    let addressLine2 = "";
    let city = "";
    let countryCode = "";
    let postalCode = "";
    let stateOrProvince = "";
    let phoneNumber = "";
    let creationDate = "";
    let fullfillmentStatus = "";
    let paymentStatus = "";
    let finalValueFee = 0;
    let sellPrice = 0;
    let shippingPrice = 0;
    let productTitle = "";
    let status: ValueOf<typeof OrderStatuses> = autodsInfo?.status ?? "PENDING";
    let buyItemUrl = "";
    let profit = 0;
    let autodsCreditFee = 0;
    let autodsWalletFee = 0;
    let buySiteOrderId = autodsInfo?.buy_site_order_id ?? "";
    let buyerAccount = "";
    let trackingNumber = autodsInfo?.tracking_number ?? "";
    let itemLocation = { city: "", state: "", postalCode: "", countryCode: "" };
    let easySyncOrderId = "";
    let miscFees = 0;
    let notes = "";
    let ebayAdFee = 0;

    if (autodsInfo && isAutodsOrder) {
      buyerUsername = autodsInfo.buyer_username;
      buyPrice = autodsInfo.buy_price ?? buyPrice;
      buyTax = autodsInfo.buy_tax ?? buyTax;
      buySiteOrderId = autodsInfo.buy_site_order_id ?? buySiteOrderId;
      buyItemVariant = autodsInfo.buy_item_variant;
      buyItemId = autodsInfo.buy_item_real_id;
      fullName = `${autodsInfo.first_name} ${autodsInfo.last_name}`;
      sellItemId = autodsInfo.sell_item_id;
      addressLine1 = autodsInfo.address_1;
      addressLine2 = autodsInfo.address_2;
      city = autodsInfo.city;
      countryCode = autodsInfo.country;
      postalCode = autodsInfo.zip_code;
      stateOrProvince = autodsInfo.state;
      phoneNumber = autodsInfo.phone_number;
      creationDate = autodsInfo.created_date;
      fullfillmentStatus = autodsInfo.status;
      paymentStatus = autodsInfo.status;
      finalValueFee =
        (autodsInfo.sell_price +
          (autodsInfo.sell_price *
            ((autodsInfo.buy_tax ?? 0) / autodsInfo.buy_price ?? 0) || 0)) *
        0.129 +
        0.3;
      sellPrice = autodsInfo.sell_price;
      productTitle = autodsInfo.product_title;
      status = (autodsInfo.status ?? status) as ValueOf<typeof OrderStatuses>;
      buyItemUrl = autodsInfo.buy_item_url;
      trackingNumber = autodsInfo.tracking_number ?? trackingNumber;
    } else {
      autodsInfo = ebayOrder?.autodsInfo ?? null;
      buyerUsername = ebayOrder?.buyer.username;
      fullName =
        ebayOrder?.fulfillmentStartInstructions[0].shippingStep.shipTo.fullName;
      sellItemId = ebayOrder?.lineItems[0].legacyItemId;
      addressLine1 =
        ebayOrder?.fulfillmentStartInstructions[0].shippingStep.shipTo
          .contactAddress?.addressLine1;
      addressLine2 =
        ebayOrder?.fulfillmentStartInstructions[0].shippingStep.shipTo
          .contactAddress?.addressLine2;
      city =
        ebayOrder?.fulfillmentStartInstructions[0].shippingStep.shipTo
          .contactAddress.city;
      countryCode =
        ebayOrder?.fulfillmentStartInstructions[0].shippingStep.shipTo
          .contactAddress.countryCode;
      postalCode =
        ebayOrder?.fulfillmentStartInstructions[0].shippingStep.shipTo
          .contactAddress.postalCode;
      stateOrProvince =
        ebayOrder?.fulfillmentStartInstructions[0].shippingStep.shipTo
          .contactAddress.stateOrProvince;
      phoneNumber =
        ebayOrder?.fulfillmentStartInstructions[0].shippingStep.shipTo
          .primaryPhone.phoneNumber;
      creationDate = ebayOrder?.creationDate;
      fullfillmentStatus = ebayOrder?.orderFulfillmentStatus;
      paymentStatus = ebayOrder?.orderPaymentStatus;
      finalValueFee = parseFloat(ebayOrder?.totalMarketplaceFee.value);
      sellPrice = parseFloat(ebayOrder?.pricingSummary.total.value);
      shippingPrice = parseFloat(ebayOrder?.pricingSummary.deliveryCost.value)
      productTitle = ebayOrder?.lineItems[0].title;
      fullName.substring(0, fullName.indexOf(" "));
      itemLocation = {
        postalCode: ebayOrder?.lineItems[0].itemLocation.postalCode ?? "",
        city: ebayOrder?.lineItems[0].itemLocation.location.substring(
          0,
          ebayOrder?.lineItems[0].itemLocation.location.indexOf(",")
        ),
        state: ebayOrder?.lineItems[0].itemLocation.location
          .substring(
            ebayOrder?.lineItems[0].itemLocation.location.indexOf(",") + 1
          )
          .trim(),
        countryCode: ebayOrder?.lineItems[0].itemLocation.countryCode,
      };

      // if fullfillmentStatus coming from ebay says its shipped, set as shipped
      if (fullfillmentStatus !== "NOT_STARTED") {
        status = "SHIPPED"
      }
      if (paymentStatus.includes("REFUNDED")) {
        status = "REFUNDED";
      }
      if (status === "PENDING" && paymentStatus === "PAID") {
        status = "READY";
      }
      if (ebayOrder?.cancelStatus?.cancelState !== "NONE_REQUESTED") {
        status = "CANCELED";
      }
    }

    // if dropstatsInfo exists it will overwrite autodsInfo
    buySiteOrderId = ebayOrder?.dropstatsInfo?.buySiteOrderId ?? buySiteOrderId;
    buyerAccount = ebayOrder?.dropstatsInfo?.buyerAccount ?? buyerAccount;
    buyPrice = ebayOrder.dropstatsInfo?.buyPrice ?? buyPrice;
    buyTax = ebayOrder.dropstatsInfo?.buyTax ?? buyTax;
    status = ebayOrder?.dropstatsInfo?.status ?? status;
    easySyncOrderId =
      ebayOrder.dropstatsInfo?.easySyncOrderId ?? easySyncOrderId;
    miscFees = ebayOrder?.dropstatsInfo?.miscFees ?? miscFees;
    notes = ebayOrder?.dropstatsInfo?.notes ?? notes;
    ebayAdFee = ebayOrder?.dropstatsInfo?.ebayAdFee ?? ebayAdFee;
    trackingNumber = ebayOrder?.dropstatsInfo?.trackingNumber ?? trackingNumber;
    shippingPrice = ebayOrder?.dropstatsInfo?.shippingPrice ?? shippingPrice

    if (autodsInfo && Object.keys(autodsInfo).length) {
      if (autodsInfo.tracking_number && autodsInfo.tracking_url) {
        autodsCreditFee +=
          0.5 * (userObject?.feeConfigs?.orderCreditPrice ?? 0.32);
      }
      if (autodsInfo.managed_order) {
        autodsCreditFee += userObject?.feeConfigs?.orderCreditPrice ?? 0.32;
        autodsWalletFee +=
          ((userObject?.feeConfigs?.walletLoadingPercentage ?? 5) / 100) *
          Number(buyPrice + buyTax || 0) || 0;
      }
    }

    const isValidSale = isSaleValid(status);

    if (status === OrderStatuses.READY) {
      profit = 0;
    } else if (isValidSale) {
      profit =
        sellPrice -
        buyPrice -
        buyTax -
        shippingPrice -
        finalValueFee -
        ebayAdFee -
        (autodsCreditFee + autodsWalletFee) -
        miscFees;
    }

    orders.push({
      fullName,
      buyerUsername,
      countryCode,
      addressLine1,
      addressLine2,
      city,
      stateOrProvince,
      postalCode,
      phoneNumber,
      fullfillmentStatus,
      buyItemUrl,
      buyItemVariant,
      buyItemId,
      buySiteOrderId,
      buyerAccount,
      productPictureUrl:
        autodsInfo?.product_picture_url ?? "/logo192.png",
      productTitle,
      profit: profit || 0,
      creationDate: moment(creationDate),
      sellItemId,
      easySyncOrderId,
      itemCost: buyPrice + buyTax,
      itemLocation,
      buyPrice,
      buyTax,
      autodsCreditFee,
      autodsWalletFee,
      autodsFee: autodsCreditFee + autodsWalletFee,
      finalValueFee,
      sellPrice,
      shippingPrice,
      profitMargin: getFormattedDecimal((profit / sellPrice) * 100),
      status,
      trackingNumber,
      isAutoDSAutoOrder: !!autodsInfo?.managed_order,
      sellOrderId: isAutodsOrder
        ? autodsInfo?.sell_site_order_id ?? ""
        : ebayOrder.orderId,
      legacySellOrderId: isAutodsOrder
        ? autodsInfo?.sell_site_order_id ?? ""
        : ebayOrder.legacyOrderId,
      miscFees,
      notes,
      ebayAdFee,
    });
  }

  return sortBy(orders, (order) => order?.creationDate);
};

export default buildOrdersArr;
