import moment from "moment";

export type ValueOf<T> = T[keyof T];

export type Theme = {
  typography: {
    fontFamily: string;
  };
  palette: {
    mode: string;
    primary: {
      main: string;
    };
    secondary: {
      main: string;
    };
  };
};

export type UserObject = {
  displayName: string;
  email: string;
  emailVerified: boolean;
  phoneNumber: string;
  photoURL: string;
  providerId: string;
  uid: string;
  walletLoadingPercentage?: number;
  ebayToken?: { username?: string } & Record<string, string>;
  lastEbayOrdersSync?: string;
  lastActiveEbayListingsSync?: string;
  feeConfigs?: {
    orderCreditPrice?: number;
    walletLoadingPercentage?: number;
  };
};

export type BuyerAccount = {
  authAppKey: string;
  otp?: string;
  supplier: string;
  email: string;
  password: string;
  enableTrackingUpdates: boolean;
};

export type Order = {
  fullName: string;
  profit: number;
  profitMargin: number;
  itemCost: number;
  buyPrice: number;
  buyTax: number;
  buyItemVariant: string;
  buyItemId: string;
  finalValueFee: number;
  sellPrice: number;
  autodsFee: number;
  autodsCreditFee: number;
  autodsWalletFee: number;
  creationDate: moment.Moment;
  status: ValueOf<typeof OrderStatuses>;
  sellItemId: string;
  productTitle: string;
  productPictureUrl: string;
  buyItemUrl: string;
  isAutoDSAutoOrder: boolean;
  sellOrderId: string;
  legacySellOrderId: string;
  ebayAdFee: number;
};

export type AutodsOrder = {
  fixed_fee: boolean;
  created_date: string;
  first_name: string;
  sell_variant_id: string;
  breakeven: number;
  buy_shipping_price?: number;
  buy_site_order_id?: string;
  city: string;
  sell_site: number;
  auto_order: boolean;
  last_name: string;
  sold_date: string;
  buy_item_real_id: string;
  prime_only: boolean;
  buy_item_url: string;
  country: string;
  store_id: number;
  bce_conversion: number;
  estimated_arrival: string;
  phone_number: string;
  sell_item_id: string;
  sell_site_order_url: string;
  zip_code: string;
  maximum_ship_days: number;
  days_awaiting_shipment: number;
  listing_fee: number;
  region: number;
  order_sent_date: string;
  suggested_buy_price: number;
  sell_site_product_url: string;
  sell_price: number;
  buyer_email: string;
  managed_order: boolean;
  product_picture_url: string;
  store_name: string;
  hipshipper: boolean;
  profit: number;
  is_canceled_on_sale_channel: true;
  gift_message_subject: string;
  order_from_webhook: boolean;
  status: ValueOf<typeof OrderStatuses>;
  sell_site_order_id: string;
  quantity_to_buy: number;
  product_title: string;
  currency: number;
  sell_site_order_name: string;
  sale_record_id: string;
  state: string;
  tracking_zipcode: string;
  tracking_number?: string;
  tracking_url?: string;
  buy_price: number;
  buy_tax?: number;
  first_prime: boolean;
  first_cheapest: boolean;
  extra_info: {
    tracking_updated_on_sale_channel: string | null;
    estimated_arrival_message: string | null;
    tracking_progress_screen: string | null;
    tracking_updated_time: string | null;
  };
  id: number;
  tags: [string, string, string, string, string];
  autods_product_id: string;
  order_fulfilled_by: string;
  supplier: number;
  buy_item_variant: string;
  gift_message: string;
  is_bundle: boolean;
  buyer_username: string;
  force_order: boolean;
  address_1: string;
  address_2: string;
};

export type OrdersTableRow = {
  id: number;
  fullName: string;
  countryCode: string;
  buyerUsername: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  stateOrProvince: string;
  postalCode: string;
  phoneNumber: string;
  fullfillmentStatus: string;
  profit: number;
  creationDate: moment.Moment;
  sellItemId: string;
  buyPrice: number;
  buySiteOrderId: string;
  buyerAccount: string;
  buyItemId: string;
  sellPrice: number;
  shippingPrice: number;
  profitMargin: number;
  status: ValueOf<typeof OrderStatuses>;
  trackingNumber: string;
  buyItemUrl: string;
  productPictureUrl: string;
  productTitle: string;
  totalItemCost: number;
  buyTax: number;
  itemLocation: {
    city: string;
    state: string;
    countryCode: string;
    postalCode: string;
  };
  autodsCreditFee: number;
  autodsWalletFee: number;
  finalValueFee: number;
  totalFees: number;
  isAutoDSAutoOrder: boolean;
  sellOrderId: string;
  easySyncOrderId: string;
  legacySellOrderId: string;
  miscFees: number;
  notes: string;
  ebayAdFee: number;
};

export const OrderStatuses = Object.freeze({
  CANCEL: "CANCELED",
  CANCELED: "CANCELED",
  RETURN_COMPLETED: "RETURN_COMPLETED",
  RETURN_PENDING: "RETURN_PENDING",
  RETURN_INPROGRESS: "RETURN_INPROGRESS",
  REFUNDED: "REFUNDED",
  FAILED: "FAILED",
  AWAITING_PAYMENT: "AWAITING_PAYMENT",
  SHIPPED: "SHIPPED",
  DELIVERED: "DELIVERED",
  ORDERED: "ORDERED",
  STAND_BY: "PENDING",
  READY: "READY",
  PENDING: "PENDING",
});

export type ProductInfo = {
  profit?: number;
  sellPrice?: number;
  successfulOrderCount?: number;
  cancelledOrderCount?: number;
  returnedOrderCount?: number;
  product_title: string;
  product_picture_url: string;
  buy_item_url: string;
  buy_item_real_id: string;
};

export type EbayOrder = {
  dropstatsInfo?: {
    buyerAccount: string;
    buySiteOrderId: string;
    buyPrice: number;
    buyTax: number;
    status: ValueOf<typeof OrderStatuses>;
    trackingNumber?: string;
    easySyncOrderId?: string;
    miscFees: number;
    notes: string;
    ebayAdFee: number;
    shippingPrice?: number;
  };
  listingInfo?: EbayListing;
  isAutodsOrder?: boolean;
  autodsInfo?: AutodsOrder;
  buyer: {
    taxAddress: {
      city: string;
      countryCode: string;
      postalCode: string;
      stateOrProvince: string;
    };
    username: string;
  };
  cancelStatus: {
    cancelState: string;
  };
  creationDate: string;
  ebayCollectAndRemitTax: boolean;
  fulfillmentHrefs: string[];
  fulfillmentStartInstructions: {
    ebaySupportedFulfillment: boolean;
    fulfillmentInstructionsType: string;
    maxEstimatedDeliveryDate: string;
    minEstimatedDeliveryDate: string;
    shippingStep: {
      shipTo: {
        contactAddress: {
          addressLine1: string;
          addressLine2: string;
          city: string;
          countryCode: string;
          postalCode: string;
          stateOrProvince: string;
        };
        fullName: string;
        primaryPhone: {
          phoneNumber: string;
        };
      };
      shippingServiceCode: string;
    };
  }[];
  lastModifiedDate: string;
  legacyOrderId: string;
  lineItems: {
    deliveryCost: {
      shippingCost: {
        currency: string;
        value: string;
      };
    };
    ebayCollectAndRemitTaxes: {
      amount: {
        currency: string;
        value: string;
      };
      collectionMethod: string;
      taxType: string;
    }[];
    itemLocation: {
      countryCode: string;
      location: string;
      postalCode: string;
    };
    legacyItemId: string;
    legacyVariationId: string;
    lineItemCost: {
      currency: string;
      value: string;
    };
    lineItemFulfillmentInstructions: {
      guaranteedDelivery: boolean;
      maxEstimatedDeliveryDate: string;
      minEstimatedDeliveryDate: string;
      shipByDate: string;
    };
    lineItemFulfillmentStatus: string;
    lineItemId: string;
    listingMarketplaceId: string;
    properties: {
      buyerProtection: boolean;
    };
    purchaseMarketplaceId: string;
    quantity: number;
    sku: string;
    soldFormat: string;
    title: string;
    total: {
      currency: string;
      value: string;
    };
  }[];
  pricingSummary: {
    deliveryCost: {
      currency: string;
      value: string;
    };
    priceSubtotal: {
      currency: string;
      value: string;
    };
    total: {
      currency: string;
      value: string;
    };
  };
  totalMarketplaceFee: {
    currency: string;
    value: string;
  };
  totalFeeBasisAmount: {
    currency: string;
    value: string;
  };
  sellerId: string;
  salesRecordReference: string;

  orderFulfillmentStatus: string;
  orderId: string;
  orderPaymentStatus: string;
  paymentSummary: {
    payments: [
      {
        amount: {
          currency: string;
          value: string;
        };
        paymentDate: string;
        paymentMethod: string;
        paymentReferenceId: string;
        paymentStatus: string;
      }
    ];
    totalDueSeller: {
      currency: string;
      value: string;
    };
  };
};

export type ListingDropstatsInfo = {
  config: {
    profitMargin: number;
    additionalProfit: number;
    feesPercentage: number;
    additionalFees: number;
    salesTaxRate: number;
    roundCents: boolean;
    roundCentsTo: number;
    defaultQuantity: number;
  };
  sourceInfo: {
    buyItemId: string;
    url: string;
  };
};

export type EbayListing = {
  galleryUrl: string;
  buyItNowPrice: number;
  dropstatsInfo: ListingDropstatsInfo;
  itemId: number;
  listingTitle: string;
  listingType: string;
  listingUrl: string;
  quantityAvailable: number;
  quantitySold: number;
  returnProfile: {
    returnProfileName: string;
    shippingProfileId: number;
  };
  startTime: string;
  shippingDetails: {
    ShippingServiceOptions: {
      ShippingServiceCost: {
        _attributes: {
          currencyID: string;
        };
        _text: string;
      };
    };
    ShippingType: {
      _text: string;
    };
  };
  shippingProfile: {
    shippingProfileId: number;
    shippingProfileName: string;
  };
  timeLeft: string;
  variations: {
    Variation: {
      SKU: string;
      StartPrice: {
        Value: number;
        CurrencyID: string;
      };
      Quantity: number;
      VariationSpecifics: {
        NameValueList: [
          {
            Name: string;
            Value: string[];
          },
          {
            Name: string;
            Value: string[];
          }
        ];
      };
      SellingStatus: {
        QuantitySold: number;
        QuantitySoldByPickupInStore: number;
      };
    }[];
    Pictures: {
      VariationSpecificName: string;
      VariationSpecificPictureSet: {
        VariationSpecificValue: string;
        PictureURL: string[];
      }[];
    }[];
    VariationSpecificsSet: {
      NameValueList: {
        Name: string;
        Value: string[];
      };
    };
  };
};

export type EbayListingSourceData = {
  sourceInfo?: { url: string; amazonItemId?: string };
  config?: {
    profitMargin: number;
    additionalProfit: number;
    feesPercentage: number;
    additionalFees: number;
    roundCents?: boolean;
    roundCentsTo?: number;
    salesTaxRate?: number;
    defaultQuantity?: number;
  };
};

export type AmazonSourceData = {
  lastSync?: { timestamp: string };
  currentPrice?: number | null;
  isOutOfStock?: boolean;
  isError?: boolean;
};

export type AmazonSourceDataDB = Record<string, AmazonSourceData>;
