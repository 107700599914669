import { Typography } from "@mui/material";
import { Container } from "@mui/system";
import React from "react";
import FullPageLoader from "./FullPageLoader";

export type MainPageLayoutProps = {
  children: JSX.Element;
  isLoading?: boolean;
  title?: string | JSX.Element;
};

const MainPageLayout = ({
  title,
  children,
  isLoading = false,
}: MainPageLayoutProps) => {
  return (
    <Container maxWidth={false} disableGutters>
      {title && (
        <Typography variant="h5" fontWeight={600} marginBottom="10px">
          {title}
        </Typography>
      )}
      {isLoading ? <FullPageLoader /> : children}
    </Container>
  );
};

export default MainPageLayout;
