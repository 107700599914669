import ArrowDropDownCircleIcon from "@mui/icons-material/ArrowDropDownCircle";
import GridViewRoundedIcon from "@mui/icons-material/GridViewRounded";
import ListIcon from "@mui/icons-material/List";
import LogoutIcon from "@mui/icons-material/Logout";
import MenuIcon from "@mui/icons-material/Menu";
import SellIcon from "@mui/icons-material/Sell";
import SettingsIcon from "@mui/icons-material/Settings";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import SyncIcon from "@mui/icons-material/Sync";
import {
  Avatar,
  Button,
  InputAdornment,
  Menu,
  MenuItem,
  Skeleton,
  TextField,
  Tooltip,
  useTheme,
} from "@mui/material";
import MuiAppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { noop } from "lodash";
import moment from "moment";
import * as React from "react";
import Moment from "react-moment";
import { useNavigate } from "react-router-dom";
import Modal from "../Modal";

import {
  DRAWER_WIDTH,
  MEDIUM_SCREEN_WIDTH,
  SMALL_SCREEN_WIDTH,
} from "../../constants";
import UserContext from "../../context/UserContext";
import WindowContext from "../../context/WindowContext";
import NavItem from "./NavItem";
import { CalculateRounded } from "@mui/icons-material";

const drawerWidth = DRAWER_WIDTH;

const StyledDrawer = styled(MuiDrawer)(() => ({
  width: drawerWidth,
}));

export default function MiniDrawer({ children, handleSyncClick }) {
  const navigate = useNavigate();
  const [isNavDrawerOpen, setIsNavDrawerOpen] = React.useState(false);
  const [isSignOutModalOpen, setIsSignOutModalOpen] = React.useState(false);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const { width: screenWidth } = React.useContext(WindowContext);
  const userObject = React.useContext(UserContext);
  const theme = useTheme();

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleSignOut = () => {
    localStorage.removeItem("dropstatsUserId");
    window.location.pathname = "/";
  };

  const drawerChildren = (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "100%",
      }}
    >
      <div>
        <div
          style={{
            padding: "20px 10px",
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
          }}
        >
          <img
            alt="DropstatsLogo"
            src={
              theme.palette.mode === "dark"
                ? "./DropstatsLogoWhite_cloud.png"
                : "./DropstatsLogoBlack_cloud.png"
            }
            height={screenWidth > SMALL_SCREEN_WIDTH ? "35px" : "25px"}
            draggable={false}
          />
        </div>
        <NavItem
          {...{
            title: "Dashboard",
            route: "dashboard",
            NavIcon: GridViewRoundedIcon,
            disabled: !userObject?.ebayToken,
          }}
        />
        <NavItem
          {...{
            title: "Orders",
            route: "orders",
            NavIcon: ShoppingCartIcon,
            disabled: !userObject?.ebayToken,
          }}
        />
        {/* <NavItem
          {...{
            title: "Listings",
            route: "listings",
            NavIcon: ListIcon,
          }}
        /> */}
        <Tooltip title="Coming Soon" placement="right" arrow>
          <div>
            <NavItem
              {...{
                title: "Products",
                route: "products",
                NavIcon: SellIcon,
                disabled: true,
              }}
            />
          </div>
        </Tooltip>
        <Tooltip title="Coming Soon" placement="right" arrow>
          <div>
            <NavItem
              {...{
                title: "Pricing",
                route: "pricing",
                NavIcon: CalculateRounded,
                disabled: true,
              }}
            />
          </div>
        </Tooltip>
      </div>
      <div>
        <NavItem
          {...{
            title: "Settings",
            route: "settings",
            NavIcon: SettingsIcon,
            isMobile: screenWidth < MEDIUM_SCREEN_WIDTH,
          }}
        />
      </div>
    </div>
  );

  return (
    <>
      <Box sx={{ display: "flex", height: "100%" }}>
        <MuiAppBar
          position="fixed"
          color="transparent"
          style={{
            boxShadow: "none",
            backdropFilter: "blur(6px)",
          }}
        >
          <Toolbar>
            {screenWidth < MEDIUM_SCREEN_WIDTH && (
              <div>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={() => setIsNavDrawerOpen(!isNavDrawerOpen)}
                  edge="start"
                >
                  <MenuIcon />
                </IconButton>
              </div>
            )}
            <div
              style={{
                display: "flex",
                marginLeft: "auto",
              }}
            >
              {userObject && (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Typography
                    style={{
                      alignSelf: "center",
                      fontSize: "12px",
                      color: theme.palette.text.secondary,
                      marginLeft: "10px",
                    }}
                  >
                    {userObject?.lastAutodsSync ? (
                      <>
                        Last synced{" "}
                        <Moment interval={10000} fromNow>
                          {userObject?.lastAutodsSync}
                        </Moment>
                      </>
                    ) : (
                      <span>Sync AutoDS Data</span>
                    )}
                  </Typography>
                  {screenWidth > SMALL_SCREEN_WIDTH && (
                    <Tooltip
                      title={
                        userObject?.lastAutodsSync &&
                        moment(userObject?.lastAutodsSync ?? null).isAfter(
                          moment().subtract(1, "hour")
                        )
                          ? "You may only sync once per hour"
                          : "Sync AutoDS Data"
                      }
                      placement="bottom"
                    >
                      <IconButton
                        onClick={handleSyncClick}
                        disabled={
                          userObject?.lastAutodsSync &&
                          moment(userObject.lastAutodsSync).isAfter(
                            moment().subtract(1, "hour")
                          )
                        }
                        style={{ pointerEvents: "auto" }}
                        size="small"
                      >
                        <SyncIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                </div>
              )}
            </div>
            <Box id="accountDropdown" sx={{ marginLeft: "15px" }}>
              <IconButton
                onClick={userObject ? handleOpenUserMenu : noop}
                sx={{ p: 0, width: "40px" }}
              >
                {userObject ? (
                  <Avatar
                    alt={userObject?.displayName}
                    src={userObject?.photoURL}
                    imgProps={{ referrerPolicy: "no-referrer" }}
                  />
                ) : (
                  <Tooltip title="Sign in" placement="bottom">
                    <div style={{ height: "40px" }} id="signInToolbar">
                      <Skeleton variant="circular" width={40}>
                        <Avatar />
                      </Skeleton>
                    </div>
                  </Tooltip>
                )}
              </IconButton>
              <Menu
                sx={{ mt: "45px", p: "10px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                <MenuItem
                  onClick={() => {
                    navigate("/settings");
                    handleCloseUserMenu();
                  }}
                >
                  <SettingsIcon style={{ marginRight: "10px" }} />
                  Settings
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setIsSignOutModalOpen(true);
                    handleCloseUserMenu();
                  }}
                >
                  <LogoutIcon style={{ marginRight: "10px" }} />
                  Sign Out
                </MenuItem>
              </Menu>
            </Box>
          </Toolbar>
        </MuiAppBar>
        {screenWidth > MEDIUM_SCREEN_WIDTH ? (
          <StyledDrawer
            variant="permanent"
            open={true}
            PaperProps={{
              style: {
                padding: "0px 20px 0px 20px",
                border: "none",
              },
            }}
            ModalProps={{ keepMounted: true }}
          >
            {drawerChildren}
          </StyledDrawer>
        ) : (
          <StyledDrawer
            variant="temporary"
            open={isNavDrawerOpen}
            PaperProps={{ style: { padding: "10px 20px" } }}
            ModalProps={{ keepMounted: true }}
            onClose={() => setIsNavDrawerOpen(false)}
          >
            {drawerChildren}
          </StyledDrawer>
        )}
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 3,
            ...(screenWidth < SMALL_SCREEN_WIDTH ? { paddingTop: "10px" } : {}),
            width: isNavDrawerOpen ? `calc(100vw - ${drawerWidth}px)` : "100vw",
            marginTop: "64px",
            overflow: "auto",
          }}
        >
          {children}
        </Box>
      </Box>
      <Modal
        isOpen={isSignOutModalOpen}
        onClose={() => setIsSignOutModalOpen(false)}
      >
        <Typography id="transition-modal-title" variant="h6" component="h2">
          Are you sure you want to sign out?
        </Typography>
        <div
          style={{
            display: "flex",
            justifyContent: "end",
            marginTop: "25px",
            columnGap: "10px",
          }}
        >
          <Button
            onClick={() => {
              handleSignOut();
              setIsSignOutModalOpen(false);
            }}
            variant="contained"
          >
            Yes
          </Button>
          <Button
            onClick={() => {
              setIsSignOutModalOpen(false);
            }}
            variant="outlined"
          >
            Cancel
          </Button>
        </div>
      </Modal>
    </>
  );
}
