import { where } from "firebase/firestore";
import { sortBy } from "lodash";
import moment from "moment-timezone";
import React, { useContext, useEffect, useState } from "react";
import UserContext from "../../context/UserContext";
import FirestoreService from "../../services/Firestore/index";
import { EbayOrder, Order } from "../../types";
import buildOrdersArr from "../../utils/buildOrdersArr";
import Content from "./Content";
import { DashboardProps } from "./types";

const Dashboard = (props: DashboardProps) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [isDataLoading, setIsDataLoading] = useState<boolean>(true);
  const [lastThirtyDaysOrders, setLastThirtyDaysOrders] = useState<Order[]>([]);
  const [orders, setOrders] = useState<Order[]>([]);
  const [startDate, setStartDate] = useState<moment.Moment>(moment());
  const [endDate, setEndDate] = useState<moment.Moment>(moment());
  const userObject = useContext(UserContext);

  useEffect(() => {
    if (userObject) {
      setIsDataLoading(true);

      const thirtyDaysAgo = moment()
        .startOf("day")
        .tz("Etc/GMT")
        .subtract("29", "days");

      const dateRangeQueryOptions = [
        where("creationDate", "<=", moment().tz("Etc/GMT").format()),
        where("creationDate", ">=", thirtyDaysAgo.format()),
      ];

      return FirestoreService.EbayService().getEbayOrdersSnapshot(
        userObject.uid,
        dateRangeQueryOptions,
        async (ebayOrdersSnapshot) => {
          const newEbayOrders: Record<string, EbayOrder> = {};

          for (const ebayOrder of ebayOrdersSnapshot.docs) {
            newEbayOrders[ebayOrder.id] = ebayOrder.data() as EbayOrder;
          }

          const lastThirtyDaysOrders = buildOrdersArr(
            newEbayOrders,
            userObject
          );

          setLastThirtyDaysOrders(
            sortBy(lastThirtyDaysOrders, (order) => order?.creationDate)
          );
          setIsDataLoading(false);
        }
      );
    }
  }, [userObject]);

  useEffect(() => {
    if (userObject) {
      setIsDataLoading(true);

      const dateRangeQueryOptions = [
        where("creationDate", "<=", moment(endDate).tz("Etc/GMT").format()),
        where("creationDate", ">=", moment(startDate).tz("Etc/GMT").format()),
      ];

      return FirestoreService.EbayService().getEbayOrdersSnapshot(
        userObject.uid,
        dateRangeQueryOptions,
        (ebayOrdersSnapshot) => {
          const newEbayOrders: Record<string, EbayOrder> = {};

          for (const ebayOrder of ebayOrdersSnapshot.docs) {
            newEbayOrders[ebayOrder.id] = ebayOrder.data() as EbayOrder;
          }

          const orders = buildOrdersArr(newEbayOrders, userObject);

          setOrders(orders);
          setIsDataLoading(false);
        }
      );
    }
  }, [startDate, endDate, userObject]);

  return (
    <Content
      {...{
        ...props,
        selectedTab,
        setSelectedTab,
        lastThirtyDaysOrders,
        isDataLoading,
        filteredOrders: orders,
        startDate,
        endDate,
        setStartDate,
        setEndDate,
      }}
    />
  );
};

export default Dashboard;
