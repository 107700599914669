import { Typography, useTheme } from "@mui/material";
import React from "react";
import DashboardCardSmall from "../../../../components/DashboardCardSmall";
import { getFormattedDecimal } from "../../../../utils";
import DashboardCardExpandableContent from "./DashboardCardExpandableContent";

const EbayFeesCard = ({ isLoading, totalFinalValueFees, totalAdFees }) => {
  const theme = useTheme();

  return (
    <DashboardCardSmall
      title="Ebay Fees"
      isLoading={isLoading}
      isExpandable={true}
      expandableContent={
        <DashboardCardExpandableContent
          {...{
            title: "Monthly Ebay Fees",
            yAxisNames: ["Final Value Fees", "Ebay Ad Fees"],
            yAxisKeys: ["finalValueFee", "ebayAdFee"],
          }}
        />
      }
    >
      <Typography variant="h4" style={{ marginBottom: "20px" }}>
        $
        {getFormattedDecimal(
          totalFinalValueFees + totalAdFees
        ).toLocaleString()}
      </Typography>
      <Typography>
        ${getFormattedDecimal(totalFinalValueFees).toLocaleString()}
        <span style={{ color: theme.palette.text.secondary }}>
          {" "}
          Final Value Fees
        </span>
      </Typography>
      <Typography>
        ${getFormattedDecimal(totalAdFees).toLocaleString()}
        <span style={{ color: theme.palette.text.secondary }}> Ad Fees</span>
      </Typography>
    </DashboardCardSmall>
  );
};

export default EbayFeesCard;
