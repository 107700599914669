import React from "react";
import { IconButton } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { NotificationVariants } from "../../constants";
import { OpenInNew } from "@mui/icons-material";

const addSuccessNotif = (message: string, link?: string) => {
  enqueueSnackbar(message, {
    variant: NotificationVariants.SUCCESS,
    ...(link
      ? {
          action: () => (
            <IconButton size="small" style={{ padding: "0px" }}>
              <a href={link} target="_blank" rel="noopener noreferrer">
                <OpenInNew fontSize="small" />
              </a>
            </IconButton>
          ),
        }
      : {}),
  });
};

export default addSuccessNotif;
