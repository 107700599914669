import InsertLinkIcon from "@mui/icons-material/InsertLink";
import {
  Button,
  InputAdornment,
  InputLabel,
  MenuItem,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import React, { FormEvent, useContext, useEffect, useState } from "react";
import CustomModal from "../../components/Modal";
import TextBlock from "../../components/TextBlock";
import {
  SECONDARY_TEXT_COLOR,
  SMALL_SCREEN_WIDTH,
} from "../../constants/index";
import UserContext from "../../context/UserContext";
import WindowContext from "../../context/WindowContext";
import EasySyncService from "../../services/CloudFunctions/EasySyncService";
import FirestoreService from "../../services/Firestore/index";
import { OrdersTableRow, OrderStatuses } from "../../types";
import { getFormattedDecimal } from "../../utils";
import addSuccessNotif from "../../utils/notifications/addSuccessNotif";

type OrderModalProps = {
  isOpen: boolean;
  onClose: () => void;
  defaultModalState: OrdersTableRow;
};

const OrderModal = ({
  isOpen,
  onClose,
  defaultModalState,
}: OrderModalProps) => {
  const userObject = useContext(UserContext);
  const { width: screenWidth } = useContext(WindowContext);
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const theme = useTheme();
  const [formState, setFormState] = useState(defaultModalState);

  useEffect(() => {
    setFormState(defaultModalState);
  }, [defaultModalState]);

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    await FirestoreService.EbayService().updateEbayOrder(
      userObject.uid,
      formState.legacySellOrderId,
      {
        ["dropstatsInfo.buySiteOrderId"]: formState.buySiteOrderId,
        ["dropstatsInfo.buyPrice"]: formState.buyPrice,
        ["dropstatsInfo.buyTax"]: formState.buyTax,
        ["dropstatsInfo.miscFees"]: formState.miscFees,
        ["dropstatsInfo.notes"]: formState.notes,
        ["dropstatsInfo.shippingPrice"]: formState.shippingPrice,
        ...(formState.buySiteOrderId?.length &&
          formState.status === OrderStatuses.READY
          ? { ["dropstatsInfo.status"]: OrderStatuses.ORDERED }
          : {}),
      }
    );

    const {
      trackingNumber,
      buySiteOrderId,
      buyerAccount,
      addressLine1,
      addressLine2,
      city,
      countryCode,
      fullName,
      phoneNumber,
      stateOrProvince,
      postalCode,
      itemLocation,
      legacySellOrderId,
    } = formState;

    if (
      !trackingNumber?.length &&
      buySiteOrderId.length &&
      buyerAccount.length
    ) {
      EasySyncService().createAquilineTrackingNumber({
        uid: userObject.uid,
        shippingAddress: {
          addressLine1,
          addressLine2,
          city,
          countryCode,
          fullName,
          phoneNumber,
          stateOrProvince,
          postalCode,
        },
        amazonOrderId: buySiteOrderId,
        buyerAccountEmail: buyerAccount,
        itemLocation,
        ebayLegacyOrderId: legacySellOrderId,
      });
    }

    addSuccessNotif("Order Updated");
    onClose();
  };

  return (
    <CustomModal {...{ isOpen, onClose }}>
      <form onSubmit={onSubmit} style={{ height: "100%" }}>
        <div
          style={{
            width: screenWidth > SMALL_SCREEN_WIDTH ? "700px" : "100%",
            height: screenWidth > SMALL_SCREEN_WIDTH ? "500px" : "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection:
                screenWidth > SMALL_SCREEN_WIDTH ? "row" : "column",
              alignItems: "center",
              columnGap: "10px",
              rowGap: "10px",
              paddingBottom: "15px",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                columnGap: "10px",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  padding: "0px",
                  position: "relative",
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "10px",
                }}
              >
                <img
                  src={formState.productPictureUrl}
                  width="50px"
                  height="50px"
                  style={{
                    minWidth: "50px",
                    minHeight: "50px",
                    borderRadius: "25px",
                    objectFit: "cover",
                    display: "flex",
                  }}
                />
              </div>
              <div>
                <Tooltip title={`https://www.ebay.com/sh/ord/details?orderid=${formState.sellOrderId}`} placement="top">
                  <a
                    style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                      columnGap: "10px",
                      fontWeight: 600,
                      maxWidth: "500px",
                    }}
                    href={`https://www.ebay.com/sh/ord/details?orderid=${formState.sellOrderId}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span>{formState.productTitle}</span>
                  </a>
                </Tooltip>
                <div
                  style={{
                    display: "flex",
                    flexDirection:
                      screenWidth > SMALL_SCREEN_WIDTH ? "row" : "column",
                    columnGap: "10px",
                  }}
                >
                  {/* <div
                    style={{
                      display: "flex",
                      columnGap: "5px",
                      alignItems: "center",
                      textOverflow: "ellipsis",
                      maxWidth: "250px",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                    }}
                  >
                    <TextBlock label={"BUY"} />
                    <a
                      href={formState.buyItemUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-transition"
                      style={{
                        display: "flex",
                        columnGap: "5px",
                        alignItems: "center",
                        pointerEvents: formState.buyItemUrl.length
                          ? "all"
                          : "none",
                      }}
                    >
                      {formState.buyItemId ? (
                        <>
                          <img
                            src="amazon.svg"
                            style={{ width: "25px" }}
                            draggable={false}
                          />
                          <span>{formState.buyItemId}</span>
                        </>
                      ) : (
                        <>
                          <InsertLinkIcon />
                          {formState.buyItemUrl}
                        </>
                      )}
                    </a>
                  </div> */}
                  {/* {screenWidth > SMALL_SCREEN_WIDTH && "|"} */}
                  <div
                    style={{
                      display: "flex",
                      columnGap: "5px",
                      alignItems: "center",
                    }}
                  >
                    <TextBlock label={"SELL"} />

                    <a
                      href={`https://ebay.com/itm/${formState.sellItemId}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-transition"
                      style={{
                        display: "flex",
                        columnGap: "5px",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src="ebay.svg"
                        style={{ width: "25px" }}
                        draggable={false}
                      />
                      <span>{formState.sellItemId}</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                padding: "10px",
                border: `2px solid ${theme.palette.divider}`,
                borderRadius: "10px",
                minWidth: "92px",
                width: screenWidth < SMALL_SCREEN_WIDTH ? "100%" : "auto",
              }}
            >
              <Typography
                variant="subtitle2"
                fontWeight={600}
                textAlign="center"
              >{`Profit: ${typeof formState.profit === "number"
                ? `$${getFormattedDecimal(formState.profit).toLocaleString()}`
                : "N/A"
                }`}</Typography>
            </div>
          </div>
          <Tabs
            value={selectedTab}
            onChange={(e, index) => setSelectedTab(index)}
            style={{
              marginBottom: "25px",
              width: "100%",
              borderBottom: `1px solid ${theme.palette.divider}`,
            }}
          >
            <Tab label={"Order"} />
            <Tab label={"Buyer"} />
          </Tabs>

          {selectedTab === 0 && (
            <>
              <div
                style={{
                  display: "flex",
                  columnGap: "20px",
                  justifyContent: "space-between",
                  marginBottom: "25px",
                }}
              >
                <TextField
                  label="Buy Order ID"
                  value={formState.buySiteOrderId}
                  style={{ width: "50%" }}
                  onChange={(e) =>
                    setFormState((prevState) => ({
                      ...prevState,
                      buySiteOrderId: e.target.value,
                    }))
                  }
                />
                <TextField
                  disabled
                  label="Tracking Number"
                  value={formState.trackingNumber}
                  style={{ width: "50%" }}
                  onChange={(e) =>
                    setFormState((prevState) => ({
                      ...prevState,
                      trackingNumber: e.target.value,
                    }))
                  }
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection:
                    screenWidth > SMALL_SCREEN_WIDTH ? "row" : "column",
                  columnGap: "20px",
                  rowGap: "20px",
                  justifyContent: "space-between",
                  marginBottom: "25px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    columnGap: "20px",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <TextField
                    value={formState.buyPrice}
                    type="number"
                    onChange={(e) => {
                      setFormState((prevState) => ({
                        ...prevState,
                        buyPrice: parseFloat(e.target.value),
                      }));
                    }}
                    label="Buy Price"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                    style={{ width: "25%" }}
                  />
                  <TextField
                    label="Buy Tax"
                    type="number"
                    value={formState.buyTax}
                    onChange={(e) =>
                      setFormState((prevState) => ({
                        ...prevState,
                        buyTax: parseFloat(e.target.value),
                      }))
                    }
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                    style={{ width: "25%" }}
                  />
                  <TextField
                    label="Shipping Cost"
                    type="number"
                    value={formState.shippingPrice}
                    onChange={(e) =>
                      setFormState((prevState) => ({
                        ...prevState,
                        shippingPrice: parseFloat(e.target.value),
                      }))
                    }
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                    style={{ width: "25%" }}
                  />
                  <TextField
                    label="Misc Fees"
                    type="number"
                    value={formState.miscFees}
                    onChange={(e) =>
                      setFormState((prevState) => ({
                        ...prevState,
                        miscFees: parseFloat(e.target.value),
                      }))
                    }
                    style={{
                      width: "25%",
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                  />
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection:
                    screenWidth > SMALL_SCREEN_WIDTH ? "row" : "column",
                  columnGap: "20px",
                  rowGap: "20px",
                }}
              >
                <TextField
                  label="Notes"
                  multiline
                  rows={4}
                  value={formState.notes}
                  onChange={(e) =>
                    setFormState((prevState) => ({
                      ...prevState,
                      notes: e.target.value,
                    }))
                  }
                  style={{
                    flexGrow: 1,
                    order: screenWidth < SMALL_SCREEN_WIDTH ? "1" : "0",
                  }}
                />
              </div>
            </>
          )}
          {selectedTab === 1 && (
            <div
              style={{
                display: "flex",
                justifyContent: "space-evenly",
                columnGap: "20px",
              }}
            >
              <div
                style={{
                  border: `2px solid ${theme.palette.divider}`,
                  padding: "15px",
                  borderRadius: "10px",
                  width: "48%",
                }}
              >
                <InputLabel style={{ marginBottom: "10px" }}>Info</InputLabel>
                <div style={{ marginBottom: "10px" }}>
                  <Typography variant="subtitle2" color={SECONDARY_TEXT_COLOR}>
                    Username:
                  </Typography>
                  <div>{formState.buyerUsername}</div>
                </div>
                <div style={{ marginBottom: "10px" }}>
                  <Typography variant="subtitle2" color={SECONDARY_TEXT_COLOR}>
                    Phone:
                  </Typography>
                  <div>{formState.phoneNumber}</div>
                </div>
              </div>
              <div
                style={{
                  border: `2px solid ${theme.palette.divider}`,
                  padding: "15px",
                  borderRadius: "10px",
                  width: "48%",
                }}
              >
                <InputLabel style={{ marginBottom: "10px" }}>
                  Address
                </InputLabel>
                <div>{formState.fullName}</div>
                <div>
                  {formState.addressLine1?.toUpperCase()}{" "}
                  {formState.addressLine2?.toUpperCase()}
                </div>
                <div>
                  {formState.city?.toUpperCase()},{" "}
                  {formState.stateOrProvince?.toUpperCase()}{" "}
                  {formState.postalCode}
                </div>
                <div>{formState.countryCode}</div>
              </div>
            </div>
          )}
          <div
            style={{
              display: "flex",
              columnGap: "10px",
              marginTop: "auto",
              justifyContent: "end",
            }}
          >
            <Button
              onClick={() => {
                onClose();
              }}
            >
              Cancel
            </Button>
            <Button variant={"contained"} type="submit">
              Save
            </Button>
          </div>
        </div>
      </form>
    </CustomModal>
  );
};

export default OrderModal;
