import { Typography } from "@mui/material";
import React from "react";
import DashboardCardSmall from "../../../../components/DashboardCardSmall";
import { getFormattedDecimal } from "../../../../utils";
import DashboardCardExpandableContent from "./DashboardCardExpandableContent";

const NetProfitCard = ({ isLoading, totalProfit }) => {
  return (
    <DashboardCardSmall
      title="Net Profit"
      isLoading={isLoading}
      isExpandable={true}
      expandableContent={
        <DashboardCardExpandableContent
          {...{
            title: "Monthly Net Profit",
            yAxisNames: ["Profit"],
            yAxisKeys: ["profit"],
          }}
        />
      }
    >
      <Typography variant="h4" style={{}}>
        ${getFormattedDecimal(totalProfit).toLocaleString()}
      </Typography>
    </DashboardCardSmall>
  );
};

export default NetProfitCard;
