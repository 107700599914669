import React from "react";
import {
  Card,
  CardHeader,
  CardContent,
  Paper,
  CircularProgress,
} from "@mui/material";
import LineChart from "./LineChart";
import BarChart from "./BarChart";

export const ChartTypes = { BAR: "bar", LINE: "line" };

const DashboardCardChartHybridLarge = ({
  children,
  title,
  chartProps = { chartData: [], xAxisKeys: [] },
  chartType = ChartTypes.LINE,
  isLoading = false,
  renderTooltip,
  showLegend = false,
}) => {
  return (
    <Paper
      style={{
        borderRadius: "20px",
        height: "445px",
        width: `100%`,
        boxShadow: "6px 6px 10px rgba(0, 0, 0, 0.06)",
      }}
      elevation={0}
    >
      <Card
        style={{
          minWidth: "inherit",
          maxWidth: "inherit",
          height: "inherit",
          borderRadius: "20px",
          padding: "20px",
          display: "flex",
          ...(isLoading
            ? {
                alignItems: "center",
                justifyContent: "center",
              }
            : {}),
          boxShadow: "none",
        }}
      >
        {isLoading ? (
          <CircularProgress />
        ) : (
          <>
            <div style={{ width: "30%" }}>
              <CardHeader
                title={title}
                titleTypographyProps={{ variant: "h6", fontWeight: 600 }}
                style={{ padding: "0px 15px" }}
              />
              <CardContent> {children} </CardContent>
            </div>
            <div
              style={{
                width: "70%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              {chartType === ChartTypes.LINE && (
                <LineChart
                  {...chartProps}
                  hideXAxis={false}
                  showLegend={showLegend}
                  renderTooltip={renderTooltip}
                  margin={{
                    top: 16,
                    right: 16,
                    bottom: 0,
                    left: 16,
                  }}
                />
              )}
              {chartType === ChartTypes.BAR && (
                <BarChart {...chartProps} renderTooltip={renderTooltip} />
              )}
            </div>
          </>
        )}
      </Card>
    </Paper>
  );
};

export default DashboardCardChartHybridLarge;
