const formatError = (e: any) => {
  let message;
  if (e instanceof Error) message = e.message;
  else message = String(e);
  if (e?.response?.data?.message) {
    message = `${message}: ${e?.response?.data?.message}`;
  }
  return message;
};

export default formatError;
