import { Typography } from "@mui/material";
import React from "react";

const TextBlock = ({ label }: { label: string }) => (
  <Typography
    style={{
      textAlign: "center",
      minWidth: "40px",
      fontSize: "10px",
      fontWeight: 600,
      color: "black",
      borderRadius: "5px",
      backgroundColor: "grey",
      padding: "1px 5px",
      margin: "3px 0px",
    }}
  >
    {label.toUpperCase()}
  </Typography>
);

export default TextBlock;
