import { enqueueSnackbar } from "notistack";
import { NotificationVariants } from "../../constants";
import formatError from "../formatError";

const addErrorNotif = (e: any) => {
  enqueueSnackbar(formatError(e), {
    variant: NotificationVariants.ERROR,
  });
};

export default addErrorNotif;
