import axios, { AxiosResponse } from "axios";
import { AmazonSourceData, AmazonSourceDataDB } from "../../types";
import addErrorNotif from "../../utils/notifications/addErrorNotif";

const AmazonService = () => ({
  updateAmazonPrice: async (asin: string): Promise<AmazonSourceData> => {
    try {
      const amazonPriceResponse: AxiosResponse<AmazonSourceDataDB> =
        await axios.post(
          "https://us-central1-ds-metrics-364506.cloudfunctions.net/updateAmazonPrice",
          { asin }
        );
      const updatedAmazonPrice = amazonPriceResponse.data[asin];
      return updatedAmazonPrice;
    } catch (e) {
      addErrorNotif(e);
      return Promise.reject(e);
    }
  },
});

export default AmazonService;
