import {
  collection,
  deleteDoc,
  doc,
  DocumentData,
  Firestore,
  getDoc,
  onSnapshot,
  QuerySnapshot,
  setDoc,
} from "firebase/firestore";
import { AmazonSourceData, EbayListingSourceData } from "../../types";
import addErrorNotif from "../../utils/notifications/addErrorNotif";

const SourceDataService = (db: Firestore) => ({
  listenAmazonSourceData: (
    asin: string,
    onNext: (doc: DocumentData) => void,
    onError: (e: any) => void = (e) => {
      addErrorNotif(e);
      throw new Error(e);
    }
  ) => onSnapshot(doc(db, `amazonSourceData/${asin}`), onNext, onError),
  getAmazonSourceData: async (
    asin: string
  ): Promise<AmazonSourceData | null> => {
    try {
      const amazonSourceData = await getDoc(doc(db, "amazonSourceData", asin));
      return amazonSourceData.data() || null;
    } catch (e: any) {
      addErrorNotif(e);
      throw new Error(e);
    }
  },
  updateAmazonSourceData: async (
    asin: string,
    updatedAmazonSourceData: AmazonSourceData
  ) => {
    try {
      await setDoc(doc(db, "amazonSourceData", asin), updatedAmazonSourceData);
    } catch (e: any) {
      addErrorNotif(e);
      throw new Error(e);
    }
  },
  checkIfAmazonSourceDataExists: async (asin: string) => {
    try {
      const amazonSourceData = await getDoc(doc(db, "amazonSourceData", asin));
      return amazonSourceData.data() ? true : false;
    } catch (e: any) {
      addErrorNotif(e);
      throw new Error(e);
    }
  },
  updateAmazonListingMap: async (uid: string, asin: string, itemId: string) => {
    try {
      await setDoc(
        doc(db, "amazonSourceData", `${asin}`, "ebayListingsMap", `${uid}`),
        { itemId }
      );
    } catch (e: any) {
      addErrorNotif(e);
      throw new Error(e);
    }
  },
  deleteUserAmazonListingMap: async (uid: string, asin: string) => {
    try {
      await deleteDoc(
        doc(db, "amazonSourceData", `${asin}`, "ebayListingsMap", `${uid}`)
      );
    } catch (e: any) {
      addErrorNotif(e);
      throw new Error(e);
    }
  },
});

export default SourceDataService;
