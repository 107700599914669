/* eslint-disable react/no-unescaped-entities */
import { InfoOutlined, SyncOutlined } from "@mui/icons-material";
import ExtensionIcon from "@mui/icons-material/Extension";
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Container,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Skeleton,
  Step,
  StepLabel,
  Stepper,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { noop } from "lodash";
import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import CustomModal from "../../components/Modal";
import StoreBlock from "../../components/StoreBlock";
import {
  EBAY_OAUTH_URL,
  EXTENSION_ID,
  SECONDARY_TEXT_COLOR,
} from "../../constants";
import { UserObject } from "../../types";
import addInfoNotif from "../../utils/notifications/addInfoNotif";
import LogoutIcon from "@mui/icons-material/Logout";

type SetupContentProps = {
  ebayStoreUsername?: string;
  handleSyncClick: () => void;
  userObject: UserObject;
};

const SetupContent = ({ handleSyncClick, userObject }: SetupContentProps) => {
  const [activeStep, setActiveStep] = React.useState(0);
  const theme = useTheme();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [isSignOutModalOpen, setIsSignOutModalOpen] = React.useState(false);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenUserMenu = (event: any) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleSignOut = () => {
    localStorage.removeItem("dropstatsUserId");
    window.location.pathname = "/";
  };

  useEffect(() => {
    if (userObject.ebayToken) {
      setActiveStep(activeStep + 1);
    }
  }, [userObject?.ebayToken]);

  return (
    <CustomModal
      {...{
        isOpen: true,
        onClose: noop,
        style: { width: "100%", height: "100%", borderRadius: "0px" },
      }}
    >
      <Container>
        <Box
          id="accountDropdown"
          sx={{ display: "flex", justifyContent: "end" }}
        >
          <IconButton
            onClick={userObject ? handleOpenUserMenu : noop}
            sx={{ p: 0, width: "40px" }}
          >
            {userObject ? (
              <Avatar
                alt={userObject?.displayName}
                src={userObject?.photoURL}
                imgProps={{ referrerPolicy: "no-referrer" }}
              />
            ) : (
              <Tooltip title="Sign in" placement="bottom">
                <div style={{ height: "40px" }} id="signInToolbar">
                  <Skeleton variant="circular" width={40}>
                    <Avatar />
                  </Skeleton>
                </div>
              </Tooltip>
            )}
          </IconButton>
          <Menu
            sx={{ mt: "45px", p: "10px" }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            <MenuItem
              onClick={() => {
                setIsSignOutModalOpen(true);
                handleCloseUserMenu();
              }}
            >
              <LogoutIcon style={{ marginRight: "10px" }} />
              Sign Out
            </MenuItem>
          </Menu>
        </Box>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "20px",
            rowGap: "25px",
            height: "100%",
          }}
        >
          <Stepper activeStep={activeStep} style={{ margin: "20px 0px" }}>
            <Step key={"Add eBay Store"}>
              <StepLabel>{"Add eBay Store"}</StepLabel>
            </Step>
            <Step key={"Sync with AutoDS"}>
              <StepLabel>{"Sync with AutoDS"}</StepLabel>
            </Step>
          </Stepper>
          {activeStep === 0 && (
            <>
              {!userObject?.ebayToken && (
                <div
                  style={{
                    backgroundColor: theme.palette.primary.main,
                    padding: "15px 20px",
                    borderRadius: "15px",
                    display: "flex",
                    flexDirection: "column",
                    margin: "20px 0px",
                  }}
                >
                  <span
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "10px",
                      fontWeight: 600,
                    }}
                  >
                    <InfoOutlined style={{ marginRight: "5px" }} />
                    {userObject?.displayName}, let's add an eBay Store!
                  </span>
                  <span>{"You're almost ready to start using DropStats."}</span>
                  <span>{"Add an eBay store to get started."}</span>
                </div>
              )}
              <div style={{ display: "flex", columnGap: "15px" }}>
                {searchParams.get("code") && (
                  <StoreBlock>
                    <CircularProgress />
                  </StoreBlock>
                )}
                <div
                  style={{
                    height: "200px",
                    width: "200px",
                    border: `3px dashed ${SECONDARY_TEXT_COLOR}`,
                    borderRadius: "10px",
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "center",
                    color: SECONDARY_TEXT_COLOR,
                    cursor: "pointer",
                  }}
                  onClick={() => (window.location.href = EBAY_OAUTH_URL)}
                >
                  + Add Store
                </div>
              </div>
              {userObject.ebayToken && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    marginTop: "auto",
                  }}
                >
                  <Button
                    variant="contained"
                    onClick={() => setActiveStep(activeStep + 1)}
                  >
                    Next
                  </Button>
                </div>
              )}
            </>
          )}
          {activeStep === 1 && (
            <>
              <div
                style={{
                  backgroundColor: theme.palette.primary.main,
                  padding: "15px 20px",
                  borderRadius: "15px",
                  display: "flex",
                  flexDirection: "column",
                  margin: "20px 0px",
                }}
              >
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "10px",
                    fontWeight: 600,
                  }}
                >
                  <InfoOutlined style={{ marginRight: "5px" }} />
                  Sync your AutoDS orders to start utilizing DropStats.
                </span>
                <span>
                  {
                    "Install the DropStats Chrome Extension and click sync to get started."
                  }
                </span>
              </div>
              <div
                style={{ display: "flex", gap: "20px", alignItems: "center" }}
              >
                <Typography variant="h6">Step 1</Typography>
                <Button variant="outlined" style={{ padding: "20px" }}>
                  <ExtensionIcon style={{ marginRight: "10px" }} />
                  <a
                    href={`https://chrome.google.com/webstore/detail/dropstats/${EXTENSION_ID}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Install Chrome Extension
                  </a>
                </Button>
              </div>
              <Divider style={{ margin: "20px 0px" }} />
              <div
                style={{ display: "flex", gap: "20px", alignItems: "center" }}
              >
                <Typography variant="h6">Step 2</Typography>
                <div>
                  <IconButton
                    color="primary"
                    onClick={() => {
                      handleSyncClick();
                      navigate("/orders");
                    }}
                  >
                    <SyncOutlined />
                  </IconButton>
                  <span>Sync AutoDS Orders</span>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                  marginTop: "auto",
                }}
              >
                <Button variant="contained" onClick={() => navigate("/orders")}>
                  Finish
                </Button>
              </div>
            </>
          )}
        </div>
        <CustomModal
          isOpen={isSignOutModalOpen}
          onClose={() => setIsSignOutModalOpen(false)}
        >
          <>
            <Typography id="transition-modal-title" variant="h6" component="h2">
              Are you sure you want to sign out?
            </Typography>
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                marginTop: "25px",
                columnGap: "10px",
              }}
            >
              <Button
                onClick={() => {
                  handleSignOut();
                  setIsSignOutModalOpen(false);
                }}
                variant="contained"
              >
                Yes
              </Button>
              <Button
                onClick={() => {
                  setIsSignOutModalOpen(false);
                }}
                variant="outlined"
              >
                Cancel
              </Button>
            </div>
          </>
        </CustomModal>
      </Container>
    </CustomModal>
  );
};

export default SetupContent;
