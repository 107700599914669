import {
  DocumentData,
  endBefore,
  limit,
  limitToLast,
  orderBy,
  QueryConstraint,
  QueryDocumentSnapshot,
  startAfter,
  where,
} from "firebase/firestore";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import UserContext from "../../context/UserContext";
import CloudFunctionsService from "../../services/CloudFunctions/index";
import FirestoreService from "../../services/Firestore/index";
import { EbayOrder, OrdersTableRow, UserObject } from "../../types";
import { getFormattedDecimal } from "../../utils";
import buildOrdersArr from "../../utils/buildOrdersArr";
import OrdersContent from "./Content";

export type OrdersProps = {
  profitStartDate: moment.Moment | null;
  profitEndDate: moment.Moment | null;
  setProfitStartDate: (date: moment.Moment | null) => void;
  setProfitEndDate: (date: moment.Moment | null) => void;
  ordersDataStartDate: moment.Moment;
  isAutodsDataSyncing: boolean;
};
const Orders = (props: OrdersProps) => {
  const userObj: UserObject = useContext(UserContext);

  const [startDate, setStartDate] = useState<moment.Moment>(
    props.ordersDataStartDate || moment()
  );
  const [endDate, setEndDate] = useState<moment.Moment>(moment());
  const [isDataLoading, setIsDataLoading] = useState<boolean>(true);

  const [ebayOrders, setEbayOrders] = useState<Record<string, EbayOrder>>();
  const [tableRows, setTableRows] = useState<OrdersTableRow[]>([]);
  const [areRowsLoading, setAreRowsLoading] = useState<boolean>(true);
  const [lastVisibleDocument, setLastVisible] =
    useState<QueryDocumentSnapshot<DocumentData> | null>(null);
  const [firstVisibleDocument, setFirstVisible] =
    useState<QueryDocumentSnapshot<DocumentData> | null>(null);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [sortDirection, setSortDirection] = useState<"desc" | "asc">("desc");
  const [sortBy, setSortBy] = useState<string>("creationDate");
  const [rowCount, setRowCount] = useState<number>(0);
  const [dateRangeQueryOptions, setDateRangeQueryOptions] = useState<
    QueryConstraint[]
  >([]);
  const [searchString, setSearchString] = useState<string | null>(null);
  const pageSize = 15;

  const handlePageChange = (
    newPage: number,
    firstVisible: QueryDocumentSnapshot<DocumentData> | null = firstVisibleDocument,
    lastVisible: QueryDocumentSnapshot<DocumentData> | null = lastVisibleDocument,
    sortDir: string = sortDirection,
    column: string = sortBy
  ) => {
    setAreRowsLoading(true);
    let pageIncrease = true;
    if (newPage < currentPage && firstVisible !== null && lastVisible !== null)
      pageIncrease = false;
    setCurrentPage(newPage);

    const queryOptions = [orderBy(column)];
    if (sortDir === "desc") {
      if (pageIncrease) {
        queryOptions.push(limitToLast(pageSize));
        if (firstVisible !== null) {
          queryOptions.push(endBefore(firstVisible));
        }
      } else {
        queryOptions.push(limit(pageSize));
        if (lastVisible !== null) {
          queryOptions.push(startAfter(lastVisible));
        }
      }
    } else if (sortDir === "asc") {
      if (pageIncrease) {
        queryOptions.push(limit(pageSize));
        if (lastVisible !== null) {
          queryOptions.push(startAfter(lastVisible));
        }
      } else {
        queryOptions.push(limitToLast(pageSize));
        if (firstVisible !== null) {
          queryOptions.push(endBefore(firstVisible));
        }
      }
    }

    const dateRangeQueryOptions = [
      where(
        "creationDate",
        "<=",
        moment(endDate).endOf("day").tz("Etc/GMT").format()
      ),
      where(
        "creationDate",
        ">=",
        moment(startDate).startOf("day").tz("Etc/GMT").format()
      ),
    ];

    queryOptions.push(...dateRangeQueryOptions);

    setDateRangeQueryOptions(dateRangeQueryOptions);

    return FirestoreService.EbayService().getEbayOrdersSnapshot(
      userObj.uid,
      queryOptions,
      (ebayOrdersSnapshot) => {
        const newEbayOrders: Record<string, EbayOrder> = {};

        for (const ebayOrder of ebayOrdersSnapshot.docs) {
          newEbayOrders[ebayOrder.id] = ebayOrder.data() as EbayOrder;
        }

        setEbayOrders(newEbayOrders);
        setFirstVisible(ebayOrdersSnapshot.docs[0]);
        setLastVisible(
          ebayOrdersSnapshot.docs[ebayOrdersSnapshot.docs.length - 1]
        );
      }
    );
  };

  // reset the table to page 0 when the column sorting changes
  const onColumnSortChange = (
    changedColumn: string,
    direction: "desc" | "asc"
  ) => {
    setSortDirection(direction);
    setSortBy(changedColumn);
    setFirstVisible(null);
    setLastVisible(null);
    handlePageChange(0, null, null, direction, changedColumn);
  };

  useEffect(() => {
    if (!searchString) {
      setFirstVisible(null);
      setLastVisible(null);
      return handlePageChange(0, null, null);
    } else {
      const queryOptions = [
        orderBy("orderId"),
        where("orderId", ">=", searchString),
        where("orderId", "<=", searchString + "~"),
      ];

      return FirestoreService.EbayService().getEbayOrdersSnapshot(
        userObj.uid,
        queryOptions,
        (ebayOrdersSnapshot) => {
          const newEbayOrders: Record<string, EbayOrder> = {};

          for (const ebayOrder of ebayOrdersSnapshot.docs) {
            newEbayOrders[ebayOrder.id] = ebayOrder.data() as EbayOrder;
          }

          setEbayOrders(newEbayOrders);
        }
      );
    }
  }, [searchString]);

  // reset the table to page 0 when the date ranges or search string change
  useEffect(() => {
    if (startDate && endDate) {
      setFirstVisible(null);
      setLastVisible(null);
      return handlePageChange(0, null, null);
    }
  }, [startDate, endDate]);

  // set default start and end dates when ordersDataStartDate loads
  useEffect(() => {
    if (props.ordersDataStartDate) {
      setStartDate(props.ordersDataStartDate);
      setIsDataLoading(false);
    }
  }, [props.ordersDataStartDate]);

  // refresh the total row count for the table when the date range query changes
  useEffect(() => {
    const loadRowCount = async () => {
      const newRowCount =
        await FirestoreService.EbayService().getEbayOrdersRowCount(
          userObj.uid,
          dateRangeQueryOptions
        );

      setRowCount(newRowCount ?? 0);
    };

    loadRowCount();
  }, [dateRangeQueryOptions]);

  useEffect(() => {
    if (ebayOrders) {
      const newTableRows: OrdersTableRow[] = [];
      const orders = buildOrdersArr(ebayOrders, userObj);

      orders.forEach((order) => {
        const {
          fullName,
          buyerUsername,
          countryCode,
          addressLine1,
          addressLine2,
          city,
          stateOrProvince,
          postalCode,
          phoneNumber,
          fullfillmentStatus,
          buySiteOrderId,
          buyerAccount,
          productPictureUrl,
          productTitle,
          profit,
          creationDate,
          sellItemId,
          itemCost,
          itemLocation,
          buyPrice,
          buyTax,
          sellPrice,
          shippingPrice,
          trackingNumber,
          easySyncOrderId,
          autodsCreditFee,
          autodsWalletFee,
          finalValueFee,
          profitMargin,
          status,
          isAutoDSAutoOrder,
          sellOrderId,
          legacySellOrderId,
          miscFees,
          notes,
          ebayAdFee,
        } = order;

        const buyItemId =
          ebayOrders[legacySellOrderId]?.listingInfo?.dropstatsInfo?.sourceInfo
            ?.buyItemId ?? order.buyItemId;

        const buyItemUrl =
          ebayOrders[legacySellOrderId]?.listingInfo?.dropstatsInfo?.sourceInfo
            ?.url ?? order.buyItemUrl;

        newTableRows.push({
          id: 1,
          fullName,
          buyerUsername,
          countryCode,
          addressLine1,
          addressLine2,
          city,
          stateOrProvince,
          postalCode,
          phoneNumber,
          fullfillmentStatus,
          buyItemUrl,
          buySiteOrderId,
          buyItemId,
          itemLocation,
          buyerAccount,
          easySyncOrderId,
          productPictureUrl: productPictureUrl.length
            ? productPictureUrl
            : ebayOrders[legacySellOrderId]?.listingInfo?.galleryUrl ?? "",
          productTitle,
          profit: profit ? getFormattedDecimal(profit) : 0,
          creationDate: moment(creationDate),
          sellItemId,
          totalItemCost: getFormattedDecimal(itemCost),
          buyPrice,
          buyTax,
          autodsCreditFee,
          autodsWalletFee: getFormattedDecimal(autodsWalletFee),
          finalValueFee: getFormattedDecimal(finalValueFee),
          totalFees: getFormattedDecimal(
            autodsCreditFee +
            autodsWalletFee +
            finalValueFee +
            ebayAdFee +
            miscFees
          ),
          sellPrice,
          shippingPrice,
          profitMargin,
          status,
          trackingNumber,
          isAutoDSAutoOrder,
          sellOrderId,
          legacySellOrderId,
          miscFees,
          notes,
          ebayAdFee,
        });
      });

      if (sortDirection === "desc") newTableRows.reverse();

      setTableRows(newTableRows);
      setAreRowsLoading(false);
    }
  }, [ebayOrders]);

  useEffect(() => {
    // update ebay orders
    if (
      userObj?.ebayToken &&
      (!userObj?.lastEbayOrdersSync ||
        moment()
          .tz("Etc/GMT")
          .subtract(5, "minutes")
          .isAfter(moment(userObj.lastEbayOrdersSync)))
    ) {
      CloudFunctionsService.EbayService().updateEbayOrders(userObj.uid);
    }
  }, []);

  return (
    <OrdersContent
      {...{
        ...props,
        startDate,
        endDate,
        setStartDate,
        setEndDate,
        areRowsLoading,
        rowCount,
        tableRows,
        currentPage,
        isDataLoading,
        onChangePage: handlePageChange,
        onColumnSortChange,
        sortBy,
        sortDirection,
        setSearchString,
      }}
    />
  );
};

export default Orders;
